/* eslint-disable react/no-array-index-key,camelcase */
import React from 'react';

class FiltersSearch extends React.PureComponent {
  setFilters = (filter) => {
    const { setFitlers } = this.props;
    setFitlers(filter);
  };

  render() {
    const { filters } = this.props;
    const available = filters
      .filter(({ searchable, menu_localizations }) => searchable && menu_localizations);

    return (
      <React.Fragment>
        <div className="search-filters_wrapper">
          <p className="filters-search_title">
            Область пошуку, без :
          </p>
          <ul className="search-filters_list">
            {filters.map(({ id, menu_localizations: item }, index) => (
              <li
                key={`search-fitler-item-${id}`}
                className="search-filters_item"
              >
                <label htmlFor={id}>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      id={id}
                      name={item[0].title}
                      onChange={() => this.setFilters(filters[index])}
                    />
                    <div className="checkbox-facade" />
                  </div>
                  <p className="filters-item_title">
                    {item[0].title}
                  </p>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default FiltersSearch;
