const linksContentUA = [
  {
    title: { text: 'Новини', url: '/news' },
  },
  {
    title: { text: 'ПРО НАС', url: '/about', point: 'about' },
    subItems: [
      { text: 'Місія + візія', url: '#mission' },
      { text: 'Завдання', url: '#task' },
      { text: 'Організаційна структура', url: '#nodes' },
      { text: 'Система менеджменту', url: '#leadership' },
      { text: 'Антикорупційна кампанія', url: '#anti-corruption' },
      { text: 'Історія', url: '#history' },
      { text: 'Партнери (Україна і світ)', url: '#partners' },
    ],
  },
  {
    title: { text: 'Наукова діяльність', url: '' },
    subItems: [
      { text: 'Наукові дослідження', url: '' },
      { text: 'Науково-технічна рада', url: '' },
      { text: 'Секція НТС', url: '' },
    ],
  },
  {
    title: { text: 'Експертна діяльність', url: '' },
    subItems: [
      { text: 'Огляд', url: '' },
      { text: 'Ліцензування', url: '' },
      { text: 'Нормування', url: '' },
      { text: 'Міжнародна діяльність', url: '' },
    ],
  },
  {
    title: { text: 'Видання', url: '/editions' },
    subItems: [
      { text: 'Художні книги', url: '' },
      { text: 'Наукові роботи', url: '' },
    ],
  },
  {
    title: {
      text: 'Послуги', url: '', img: '', point: 'proposition',
    },
    subItems: [
      { text: 'радіактивній монітор', url: '', img: '' },
      { text: 'Навчання і підвищення кваліфікації', url: '', img: '' },
      { text: 'R & D', url: '', img: '' },
      { text: 'Технічні переклади', url: '', img: '' },
    ],
  },
  {
    title: { text: 'Філії', url: '', point: 'affiliates' },
    subItems: [
      {
        text: 'Славутицький', url: '', name: 'Славутицкий филиал', address: 'вул. 77-ї Гвардійської дивізії, 7/4; а/с 56', data: 'good filial',
      },
      {
        text: 'Харківський', url: '', name: 'Харьковский филиал', address: 'вул. Чернишевська, 53, 61002, Харків', data: 'good filial',
      },
    ],
  },
  {
    title: { text: 'Контакти', url: '/contacts', point: 'contacts' },
    subItems: [
      { text: 'Канцелярія', url: '' },
      { text: 'Приймальна директора', url: '' },
      { text: 'Прес-центр', url: '' },
    ],
  },
];

export default linksContentUA;
