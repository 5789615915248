/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import '../assets/styles/propositions.scss';

import { toggleModal } from '../actions/main';
import SliderSecondary from './SliderSecondary';
import {connect} from "react-redux";

const secondarySettings = {
    dots          : true,
    infinite      : false,
    speed         : 500,
    slidesToShow  : 4,
    slidesToScroll: 4,
    responsive    : [
        {
            breakpoint: 768,
            settings  : {
                slidesToShow  : 2,
                slidesToScroll: 2,
            },
        },
    ],
};

const Gallery = ({media, title, toggle}) => {

    return (<div className="propositions-wrapper">
        <h2 style={{ textAlign: 'center', marginBottom: '5px' }}>{title}</h2>
        <div className="row no-gutter">
            <SliderSecondary settings={{...secondarySettings}}>
                {media.map((item, i) => (
                    <div key={`propositions-item-${i}`}
                         className={`propositions-item${!item ? ' no-pointer' : ''}`}
                         onClick={() => toggle(media, title, 'galleries')}>
                        <div className="propositions-image_wrapper">
                            <img
                                className="propositions-image"
                                src={item && (item.src)}
                                alt=""
                            />
                        </div>
                        <div className="propositions-text">
                            {item && (item.title || item.description)}
                        </div>
                    </div>
                ))}
            </SliderSecondary>
        </div>
    </div>)
}

Gallery.propTypes = {
    media: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    toggle: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    toggle: (data, title, type) => dispatch(toggleModal(data, title, type)),
});

export default connect(null, mapDispatchToProps)(Gallery);
