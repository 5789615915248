import React from 'react';
import Loader from '../../assets/svg/loader.svg';

const LoaderWrapper = () => (
  <div className="container">
    <div className="news-section">
      <div className="news-item-wrapper">
        <div className="d-flex align-items-center justify-content-center min-height-100">
          <img src={Loader} className="m-5" alt="" />
        </div>
      </div>
    </div>
  </div>
);

export default LoaderWrapper;
