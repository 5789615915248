import React, { Component } from 'react';

import '../../assets/styles/accordionFilters.scss';
import '../../assets/styles/booksFilterAccordion.scss';
import BookImg from '../../assets/svg/books.svg';
import Arrow from '../../assets/svg/arrow-grey.svg';

class BooksFilterAccordion extends Component {
  state = {
    open: false,
  };

  toggle = () => this.setState(state => ({ open: !state.open }));

  render() {
    const { open } = this.state;
    const {
      header, button, body, count, count_active
    } = this.props;
    return (
      <React.Fragment>
        {open && (
          <div
            className="overflow"
            onClick={() => {
              this.setState({ open: false });
            }}
            role="presentation"
          />
        )}
        <div className={`books-accordion-wrapper${open ? ' opened' : ''}`}>
          <div className="accordion-head">
            <div
              className="accordion-button-books"
              onClick={this.toggle}
              role="presentation"
            >
              {button}
              <img src={Arrow} alt="" />
            </div>
            <div className="books-count-image">
              <img className="book-image" src={BookImg} alt="" />
              {!!count_active &&
                <span>
                  {count_active} /
                </span>
              }
              <span>
                {count}
              </span>
            </div>
            {header}
          </div>
          {open && (
          <div className="accordion-body">
            {body}
          </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default BooksFilterAccordion;
