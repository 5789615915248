/* eslint-disable react/no-array-index-key, react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import NotFound from '../../views/NotFound';
import Loader from './Loader';

class LoaderWrapper extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { language, languageChange } = this.props;
    if (language.id !== prevProps.language.id) languageChange();
  }

  render() {
    const {
      initiated,
      title, children,
      error, loading, localization,
    } = this.props;

    if (!initiated) return null;

    if (localization) return (<NotFound localization={localization} />);

    if (error) return (<NotFound localization={localization} />);

    if (loading) return (<Loader />);

    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </React.Fragment>
    );
  }
}

LoaderWrapper.propTypes = {
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  initiated: PropTypes.bool.isRequired,
  language: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ general }) => ({
  language: general.localization.language,
  initiated: general.initiated,
});

export default connect(mapStateToProps)(LoaderWrapper);
