import React from 'react';
import { connect } from 'react-redux';

import '../../assets/styles/accordion.scss';
import ContactBlock from "../contacts/ContactBlock";
import {Link} from "react-router-dom";

class ContactAffiliatesAccordion extends React.Component {
  state = {
    active: 0,
  };

  selectFold = foldNum => {
    const current = this.state.active === foldNum ? false : foldNum;
    this.setState(() => ({ active: current }));
    this.props.hideMap();
  };

  render() {

    const { contacts, toggleFormMap, titleButton } = this.props;
    // const affiliates = i18n.links.find(item => item.title.point === 'affiliates');
    return (
      <div className="accordion">
        {contacts && contacts
            .sort((a, b) => a.priority - b.priority)
            .map((contact, i) => {
          return (
            contact.department.department_localizations[0] &&
            <Fold
              key={`contacts-${contact.id}`}
              contact={contact}
              handle={() => this.selectFold(i)}
              active={i === this.state.active }
              toggleFormMap={toggleFormMap}
              titleButton={titleButton}
            />
          );
        })}
      </div>
    );
  }
}

class Fold extends React.Component {
  render() {
    return (
      <div className="fold">
        <button
          className={`fold_trigger ${this.props.active ? "open" : ""}`}
          onClick={this.props.handle}
          style={{ backgroundColor: '#fff' }}
        >
          <div className="fold-title">
            {this.props.contact.department.department_localizations[0].title}
          </div>
          <div className="partner-counter">
            <span>
              {this.props.active ? <i className="fas fa-angle-up"></i>
                : <i className="fas fa-angle-down"></i>
              }
            </span>
          </div>


        </button>


        <div
          key="content"
          className={`fold_content ${this.props.active ? "open" : ""}`}
        >
          {this.props.active ?
            <div className="folder-item" key={`accordion-content-${this.props.contact.id}`}>
              <div className="accordion-row-content">
                {/*{this.props.contact.id}*/}
                <ContactBlock
                    department={this.props.contact.department}
                    children={this.props.contact.children}
                    toggleFormMap={this.props.toggleFormMap}
                    titleButton={this.props.titleButton}
                />
              </div>
            </div>
            : null}
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => ({
//   i18n: state.i18n,
// });
// export default connect(mapStateToProps)(ContactAffiliatesAccordion);


export default ContactAffiliatesAccordion;
