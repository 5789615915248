/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.scss';
import '../assets/styles/sliderMedia.scss';

import newsImage from '../assets/images/news-img1.jpg';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const slides = new Array(8).fill('');

export default class SliderFiles extends Component {
  constructor(props) {
    super(props);
    this.prevX = 0;
    this.prevY = 0;
    this.slider = React.createRef();
    this.state = {
      selected: new Map(),
    };
  }

  select = (event, item) => {
    const { type, currentTarget, nativeEvent } = event;
    const { x, y } = nativeEvent;
    const { clientWidth } = currentTarget;

    if (type === 'mouseup') {
      const a = this.prevX - x;
      const b = this.prevY - y;
      const distance = Math.sqrt((a * a + b * b));
      if (distance <= clientWidth) {
        const { selected } = this.state;
        const current = selected.get(item);
        this.setState({
          selected: current ? selected.set(item, !current) : selected.set(item, true),
        });
      }
    } else {
      this.prevX = x;
      this.prevY = y;
    }
  };

  render() {
    const { selected } = this.state;
    return (
      <div className="slider-media slider-media-files">
        <div className="title-wrapper">
          <h2>Slider Syncing</h2>
          <div className="cross" />
        </div>

        <Slider
          ref={this.slider}
          {...settings}
        >
          {slides.map((item, index) => (
            <ul
              key={`sl-item-bot-${index}`}
              className="slider-files-item"
            >
              {slides.map((el, i) => (
                <li
                  key={`sub-item-${i}`}
                  className={`slider-files-sub ${selected.get(`${index}-${i}`) ? 'selected' : ''}`}
                  onMouseDown={ev => this.select(ev, `${index}-${i}`)}
                  onMouseUp={ev => this.select(ev, `${index}-${i}`)}
                  role="presentation"
                >
                  <img src={newsImage} alt="" />
                  <h5>{index}</h5>
                </li>
              ))}
            </ul>
          ))}
        </Slider>

        <button className="download-button">
          {selected.size}
        </button>
      </div>
    );
  }
}
