/* eslint-disable camelcase,react/prop-types */
import React from 'react';
import Truncate from 'react-truncate';
import TextTruncate from 'react-text-truncate';

import LinkWrapper from '../util/LinkWrapper';

import '../../assets/styles/bigNewsBlock.scss';

import { formatDateString, clearPageDescription } from '../../services/main';

import BigNewsTags from './BigNewsTags';
import NewsBottom from './NewsBottom';
import ShareButton from '../util/ShareButton';
import {CORE_URL} from "../../constants/urls";


const BigNewsBlock = (props) => {
  const { parent, page } = props;
  const {
    is_social_networks_on, photo,
    news_localizations, published_at, created_at, alias,
  } = page;

  return (
    <div className="big-card">
      <LinkWrapper link={`${parent}/${alias}`}>
        <div className="big-news-image">
          <img src={photo && (photo.resize || photo.href)} alt="" />
        </div>
        <div className="big-news-title">
          <h3>{news_localizations[0] && news_localizations[0].title}</h3>
            {/*<Truncate*/}
            {/*  lines={2}*/}
            {/*  className="truncate-wrapper"*/}
            {/*  ellipsis={<span>...</span>}*/}
            {/*>*/}
            {/*  {news_localizations[0] && news_localizations[0].title}*/}
            {/*</Truncate>*/}
            {/*<TextTruncate*/}
            {/*  line={2}*/}
            {/*  truncateText="..."*/}
            {/*  text={news_localizations[0] && news_localizations[0].title}*/}
            {/*/>*/}
        </div>
        <div className="big-news-description">
          <p>{news_localizations[0] && clearPageDescription(news_localizations[0].description)}</p>
          {/*<TextTruncate*/}
          {/*  line={3}*/}
          {/*  truncateText="..."*/}
          {/*  text={news_localizations[0] && clearPageDescription(news_localizations[0].description)}*/}
          {/*/>*/}

          {/*<Truncate*/}
          {/*  lines={2}*/}
          {/*  ellipsis={<span>...</span>}*/}
          {/*>*/}
          {/*  {news_localizations[0] && clearPageDescription(news_localizations[0].description)}*/}
          {/*</Truncate>*/}
        </div>
        {/*<div className="big-news-tag-block">*/}
        {/*  {news_localizations[0] && (<BigNewsTags tags={news_localizations[0].tags} />)}*/}
        {/*</div>*/}
      </LinkWrapper>
      <NewsBottom
          tags={news_localizations[0].tags}
          date={formatDateString(published_at || created_at)}
          social
          title={news_localizations[0].title}
          url={`${CORE_URL}${parent}/${alias}`}
          image={photo && (photo.resize || photo.href)}
      />
    </div>
  );
};

export default BigNewsBlock;
