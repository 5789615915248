/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import docimage from "../assets/svg/file-icon.svg";

import "../assets/styles/propositions.scss";

import { toggleModal } from "../actions/main";
import SliderSecondary from "./SliderSecondary";
import { connect } from "react-redux";

const secondarySettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
};

const FilesGallery = ({ media, title, toggle }) => (
  <div className="propositions-wrapper">
    <div className="row no-gutter">
      <SliderSecondary settings={{ ...secondarySettings }}>
        {media.map((item, i) => (
          <a
            className="files-item-link"
            href={item.href}
            key={`propositions-item-${i}`}
          >
            <div
              className={`propositions-item${!item ? " no-pointer" : ""}`}
              // onClick={() => toggle(media, title, "galleries")}
            >
              <div className="propositions-image_wrapper">
                <img className="files-image" src={docimage} alt="" />
              </div>
              <div className="propositions-text">
                {item && item.description}
              </div>
            </div>
          </a>
        ))}
      </SliderSecondary>
    </div>
  </div>
);

FilesGallery.propTypes = {
  media: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  toggle: (data, title, type) => dispatch(toggleModal(data, title, type))
});

export default connect(
  null,
  mapDispatchToProps
)(FilesGallery);
