import React from 'react';
import PropTypes from 'prop-types';

class Voting extends React.PureComponent {
  render() {
    const { score } = this.props;
    const iWholeStars = Math.floor(score);
    const blnHalfStar = (iWholeStars < score);

    const indents = [];
    for (let iStar = 1; iStar <= iWholeStars; iStar++) {
      indents.push(<span className="star-icon full" key={`full-sub-item-${iStar}`} />);
    }

    if (blnHalfStar) {
      indents.push(<span className="star-icon half" key="half-sub-item" />);
    }

    if (indents.length < 5) {
      const dif = 5 - indents.length;
      for (let iStar = 1; iStar <= dif; iStar++) {
        indents.push(<span className="star-icon" key={`star-icon-sub-item-${iStar}`} />);
      }
    }

    if (indents.length) {
      return (
        <div className="rating">
          {indents}
        </div>
      );
    }

    return null;
  }
}

Voting.propTypes = {
  score: PropTypes.number,
};

Voting.defaultProps = {
  score: 0,
};

export default Voting;
