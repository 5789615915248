/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import NotFound from '../../views/NotFound';

class ErrorWrapper extends React.Component {
  state = {
    error: false,
  };

  static getDerivedStateFromError() {
    return {
      error: true,
    };
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) return (<NotFound />);

    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}

ErrorWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorWrapper;
