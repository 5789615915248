import React from "react";
import "../../assets/styles/social.scss";

const Social = ({ socials }) => {
  if (!socials) return "";
  return (
    <ul className="social-icons">
      {socials.facebook_link && (
        <li>
          <a href={socials.facebook_link} className="social-icon">
            <i className="fa fa-facebook" />
          </a>
        </li>
      )}
      {socials.twitter_link && (
        <li>
          <a href={socials.twitter_link} className="social-icon">
            <i className="fa fa-twitter" />
          </a>
        </li>
      )}
      {socials.instagram_link && (
        <li>
          <a href={socials.instagram_link} className="social-icon">
            <i className="fa fa-instagram" />
          </a>
        </li>
      )}
      {socials.youtube_link && (
        <li>
          <a href={socials.youtube_link} className="social-icon">
            <i className="fa fa-youtube" />
          </a>
        </li>
      )}
      {socials.linked_in_link && (
        <li>
          <a href={socials.linked_in_link} className="social-icon">
            <i className="fa fa-linkedin" />
          </a>
        </li>
      )}
      {socials.google_plus_link && (
        <li>
          <a href={socials.google_plus_link} className="social-icon">
            <i className="fa fa-google-plus" />
          </a>
        </li>
      )}
    </ul>
  );
};

export default Social;
