/* eslint-disable camelcase, eslint-disable react/no-array-index-key */
/* eslint-disable max-len */

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import '../assets/styles/about.scss';

import ReactHtmlParser from 'react-html-parser';
import Accordion from '../components/util/Accordion';
import Breadcrumbs from '../components/util/Breadcrumbs';
import ContentBlock from '../components/ContentBlock';
import BossStructureItem from '../components/util/BossStructureItem';
import { fetchStructure } from '../actions/org_structure';

import SliderSecondary from '../components/SliderSecondary';
import fetchOne from '../services/fetch';
import { GET_DEPARTMENTS } from '../constants/urls';
import { addLoadPlaceholders, clearPageDescription, getDescriptionWidgets, getTranslation } from '../services/main';
import Gallery from '../components/Gallery';
import FilesGallery from '../components/FilesGallery';
import IframeContainer from '../components/util/IframeContainer';
import OrgStructure from '../components/about/OrgStructure/OrgStructure';
import Iblocks from '../components/Iblocks'

const parseContent = (key, translations) => (
  getDescriptionWidgets(addLoadPlaceholders(getTranslation(key, translations))).map(
    (item, i) => {
      if (item.type === 'text') {
        return ReactHtmlParser(item.text);
      } else if (item.type === 'media') {
        return (
          <Gallery
            key={i}
            media={item.media}
            title={item.title}
          />
        );
      } else if (item.type === 'files') {
        return (
          <FilesGallery
            key={i}
            media={item.media}
            title={item.title}
          />
        );
      } else if (item.type === 'iblocks') {
        return (
                <Iblocks
                        key={i}
                        media={item.media}
                        title={item.title}
                />
        );
      } else if (item.type === 'iframe') {
        return (
          <IframeContainer
            key={`iframe-${i}`}
            iframe={item.media[0].iframe}
            itext={item.media[0].itext}
          />
        );
      }
    })
);

class About extends React.Component {
    // рефы на ссылки с якорями
    partners = React.createRef();

    history = React.createRef();

    nodes = React.createRef();

    leadership = React.createRef();

    mission = React.createRef();

    task = React.createRef();

    anticorruption = React.createRef();

    state = {
      cur_elem_id: 0,
      curDepartment: {},
      organization_column: [],
      curSubStructure: [],
      curSubStructureChildren: [],
    };

    componentDidMount() {
      window.scrollTo(0, 0);
      const { getStructure, orgStructure } = this.props;
      getStructure().then(() => {
        this.getDepartment(orgStructure);
        this.setState({
          curSubStructure: orgStructure.children || {},
        });
      });
    }

    componentDidUpdate(prevProps) {
      // fix auto scroll
      const { location: { hash }, history, lang, getStructure, orgStructure } = this.props;
      if (prevProps.lang !== lang) {
        if (hash) {
          history.push('/about-us');
        }
        getStructure().then(() => {
          this.getDepartment(orgStructure);
          this.setState({
            curSubStructure: orgStructure.children || {},
          });
        });
      } else {
        this.goAnchorLinks();
      }
    }

    goAnchorLinks = () => {
      const { location: { hash } } = this.props;
      if (hash) {
        switch (hash) {
          case '#mission':
            return this.mission.current.scrollIntoView({ behavior: 'smooth' });
          case '#leadership':
            return this.leadership.current.scrollIntoView({ behavior: 'smooth' });
          case '#nodes':
            return this.nodes.current.scrollIntoView({ behavior: 'smooth' });
          case '#history':
            return this.history.current.scrollIntoView({ behavior: 'smooth' });
          case '#partners':
            return this.partners.current.scrollIntoView({ behavior: 'smooth' });
          case '#task':
            return this.task.current.scrollIntoView({ behavior: 'smooth' });
          case '#anti-corruption':
            return this.anticorruption.current.scrollIntoView({ behavior: 'smooth' });
          default:
            return false;
        }
      }
    };

    getEmployeeName = (obj) => {
      if (!obj || !obj.employee) {
        return '';
      }

      const { surname, name, middle_name } = obj.employee.employee_localizations[0] || {};
      return `${name} ${middle_name} ${surname}`;
    };

  getEmployeeLink = (obj) => {
    if (!obj || !obj.employee) {
      return '';
    }
    const { link } = obj.employee.employee_localizations[0] || {};
    return link;
  };

  getItemImage = (obj) => {
    let image = '/images/no_photo.png';

    if (obj && obj.employee && obj.employee.photo) {
      image = obj.employee.photo.resize || obj.employee.photo.href;
    }
    return image;
  };

    parse = arr => [].concat(...arr.map(item => (item.children && item.children.length
      ? [].concat(...[item, [].concat(...this.parse(item.children))]) : item)));

    getDepartment = async (obj) => {
      if (obj.department) {
        const res = await fetchOne({ url: `${GET_DEPARTMENTS}/${obj.department.id}` });
        this.setState({
          curDepartment: res,
        });
      } else {
        this.setState({
          curDepartment: {},
        });
      }
    };

    render() {
      // let curObj = {};
      //
      // const {
      //   cur_elem_id, curDepartment, organization_column, curSubStructure, curSubStructureChildren,
      // } = this.state;

      const { orgStructure, translations, menu } = this.props;

      if (orgStructure && Object.keys(orgStructure).length) {
        // const treeStructure = this.parse([orgStructure]);

        // curObj = treeStructure.find(item => item.id === cur_elem_id);
        // curObj = (curObj && Object.keys(curObj).length) ? curObj : orgStructure;

        orgStructure.children = orgStructure.children && orgStructure.children.filter(item => item.employee);
      }

      const customBlocks = ['mission', 'task', 'mission', 'leadership', 'anti-corruption', 'history'];

      const sideMenu = menu
        .find(item => item.url === 'about-us')
        .children
        .sort((a, b) => a.priority - b.priority)
        .filter(el => !customBlocks.some(cb => cb === el.url.split('#')[1]) || !!getTranslation(el.url.split('#')[1], translations));

      const abouts = menu.find(item => item.url === 'about-us').menu_localizations[0].title;

      return (
        <React.Fragment>

          {/* Side Menu */}
          <div className="side-links">
            {sideMenu.map((item, index) => {
              return (
                <Link to={`${item.url}`}>
                  <div className="side-link-item" key={`side-link-${index}`}>
                    <div className="side-link-item-text">
                      {item.menu_localizations[0].title}
                    </div>
                  </div>
                </Link>
              )
              }
            )}
          </div>
          {/* Side Menu --- END*/}

          <div className="container">
            <Helmet>
              <title>
                {clearPageDescription(getTranslation('about-us-title', translations))}
              </title>
            </Helmet>

            <div className="row no-gutter">
              <div className="col-xl-12 breadcrumb-wrapper">
                <Breadcrumbs string={abouts} />
              </div>
            </div>
            <ContentBlock>
              <div className="news-item-wrapper">
                <div className="about-big-name">
                  {clearPageDescription(getTranslation('about-us-title', translations))}
                </div>
                <div className="row no-gutter">
                  <div className="col-xl-12 about-content">
                    { parseContent('about-us', translations) }
                  </div>


                  {/* Блок Миссия + Визия */}
                  { !!getTranslation('mission', translations) && (
                  <div className="about-history-block" id="mission" ref={this.mission}>
                    <div className="about-mediate-name">
                      {clearPageDescription(getTranslation('mission-and-vision-title', translations))}
                    </div>
                      <div className="col-xl-12 about-indent about-content">
                        { parseContent('mission', translations) }
                      </div>
                  </div>
                  )}

                  {/* Блок Задания */}
                  { !!getTranslation('task', translations) && (
                  <div className="about-history-block" id="task" ref={this.task}>
                    <div className="about-mediate-name">
                      {clearPageDescription(getTranslation('tasks-title', translations))}
                    </div>
                    <div className="col-xl-12 about-indent about-content">
                      { parseContent('task', translations) }
                    </div>
                  </div>
                  )}


                  {/* Блок организационная структура */}
                  <div  id="nodes" ref={this.nodes}></div>
                    <div className="about-mediate-name">
                      {clearPageDescription(getTranslation('organizational_chart', translations))}
                    </div>
                  {!!(orgStructure && Object.keys(orgStructure)).length && (

                    // Руководство --------
                    <React.Fragment>
                      <div className="boss-block-header">
                        {clearPageDescription(getTranslation('management', translations))}
                      </div>

                      <div className="boss-block">
                        <div className="boss-structure">
                          <div className="director-row">
                                <BossStructureItem
                                  name={this.getEmployeeName(orgStructure)}
                                  positionSchema={orgStructure.node_localizations && orgStructure.node_localizations[0].title}
                                  bossImg={this.getItemImage(orgStructure)}
                                  link={this.getEmployeeLink(orgStructure)}
                                />
                              </div>
                          <div className="sub-director-row">
                                <SliderSecondary
                                  style={{
                                        marginLeft: -5,
                                      }}
                                  settings={{
                                        dots: true,
                                        infinite: false,
                                        // infinite: orgStructure.children.length > 6,
                                        speed: 500,
                                        slidesToShow: 4,
                                        slidesToScroll: 4,
                                        responsive: [
                                          {
                                            breakpoint: 440,
                                            settings: {
                                              slidesToShow: 1,
                                              slidesToScroll: 1,
                                            },
                                          },
                                          {
                                            breakpoint: 768,
                                            settings: {
                                              slidesToShow: 2,
                                              slidesToScroll: 2,
                                            },
                                          },
                                          {
                                            breakpoint: 1024,
                                            settings: {
                                              slidesToShow: 3,
                                              slidesToScroll: 3,
                                            },
                                          },
                                        ],
                                      }}
                                >
                                  {orgStructure.children
                                  .filter(item => item.government)
                                  .sort((a, b) => a.priority - b.priority)
                                  .map((lead, i) => (
                                        <BossStructureItem
                                          key={`boss-sub-item-${i}`}
                                          name={this.getEmployeeName(lead)}
                                          // positionSchema={locale(lead.node_localizations) && locale(lead.node_localizations).title}
                                          position={lead.node_localizations && lead.node_localizations[0].position}
                                          // onClickFunction={() => this.linkTo(locale(lead.node_localizations) ? locale(lead.node_localizations).page : '')}
                                          bossImg={this.getItemImage(lead)}
                                          link={this.getEmployeeLink(lead)}
                                          department={!!lead.department && lead.department.department_localizations[0].title}
                                        />
                                      ))}
                                </SliderSecondary>
                              </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {/* Сама оргструктура - ноды */}
                  <div className="boss-block-header">
                    {clearPageDescription(getTranslation('organizational_chart', translations))}
                  </div>
                  <OrgStructure />
                </div>


                {/* Блок Система Менеджменту */}
                <div id="leadership" ref={this.leadership}></div>
                { !!getTranslation('leadership', translations) && (
                <div className="about-history-block">
                  <div className="about-mediate-name">
                    {clearPageDescription(getTranslation('management-system-title', translations))}
                  </div>
                  <div className="col-xl-12 about-indent about-content">
                    { parseContent('leadership', translations) }
                  </div>
                </div>
                )}


                {/* Блок Антікоррупційна кампанія */}
                <div  id="anti-corruption" ref={this.anticorruption}></div>
                { !!getTranslation('anti-corruption', translations) && (
                  <div className="about-history-block">
                    <div className="about-mediate-name">
                      {clearPageDescription(getTranslation('anti-corruption-campaign-title', translations))}
                    </div>
                    <div className="col-xl-12 about-indent about-content">
                      { parseContent('anti-corruption', translations) }
                    </div>
                  </div>
                )}

                {/* Блок история */}
                <div id="history" ref={this.history}></div>
                { !!getTranslation('history-title', translations) && (
                <div className="about-history-block">
                  <div className="about-mediate-name">
                    {clearPageDescription(getTranslation('history-title', translations))}
                  </div>
                  <div className="col-xl-12 about-indent about-content">
                    { parseContent('history', translations) }
                  </div>
                </div>
                )}


                {/* Блок партнери */}
                <div id="partners" ref={this.partners}></div>
                <div className="row no-gutter">
                  <div className="about-mediate-name about-indent" style={{ paddingTop: '20px' }}>
                    {clearPageDescription(getTranslation('partners-title', translations))}
                  </div>
                  <div className="col-xl-12 about-content partners-wrap">
                    <Accordion />
                  </div>
                </div>
              </div>
            </ContentBlock>
          </div>
        </React.Fragment>
      );
    }
}

const mapStateToProps = state => ({
  orgStructure: state.orgStructure,
  translations: state.translations,
  menu: state.general.content.menu,
  lang: state.general.localization.language.locale,
});

const mapDispatchToProps = dispatch => ({
  getStructure: () => dispatch(fetchStructure()),
});

export default connect(mapStateToProps, mapDispatchToProps)(About);
