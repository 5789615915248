const fetchOne = async ({
  url,
  method = 'GET',
  headers = {},
  body = {},
  ...rest
}) => {
  const language = localStorage.getItem('language');
  const params = {
    ...rest,
    method,
    ...(method !== 'GET' && { body }),
    headers: {
      ...headers,
      Localization: language,
    },
  };
  const response = await fetch(url, params);
  return method === 'GET' ? response.json() : response;
};

export default fetchOne;
