import React, { Component } from 'react';
import { connect } from 'react-redux';
import fetchOne from '../../../services/fetch';
import { GET_ORG_STRUCTURE } from '../../../constants/urls';
import getAllNodes from './getNodes';
import './org-structure.scss';
import OrgContacts from './OrgContacts'

class OrgStructure extends Component {
  state = {
    allNodes: [],
    activeNodes: [],
    currentNode: {},
  }

  componentDidMount() {
    this.getNodes();
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.getNodes();
    }
  }

  getNodes = async () => {
    try {
      const nodes = await fetchOne({ url: `${GET_ORG_STRUCTURE}` });
      const allNodes = getAllNodes(nodes);
      this.setState({
        allNodes,
        activeNodes: [allNodes[0]],
        currentNode: allNodes[0],
      });
    } catch (e) {
      console.log(e);
    }
  }

  selectChild = (node) => {
    const { allNodes, activeNodes } = this.state;
    if (node.isChildren) {
      const current = allNodes.find(el => el.id === node.id);
      this.setState({ activeNodes: [...activeNodes, { ...current }] });
    }
    this.setState({ currentNode: { ...node } });
  }

  selectParent = (index) => {
    const { activeNodes } = this.state;
    this.setState({
      activeNodes: activeNodes.slice(0, index + 1),
      currentNode: { ...activeNodes[index] },
    });
  }

  render() {
    const { activeNodes, currentNode } = this.state;

    return (
      <div className="org-structure">

        {/* --- Левая панель с иерархической структурой и контактным блоком  --- */}
        <div className="org-structure-panel parent-panel">
          <div className="parent-nodes">
            {activeNodes.map((node, index) => (
              <p
                key={node.id}
                className="parent"
                // className={`parent${index === activeNodes.length - 1 ? ' active' : ''}`}
                style={{
                  marginLeft: `${index * 20}px`,
                  backgroundColor: `${index === activeNodes.length - 1 ? '#f8ca7f' : (node.color || '#ffffff')}`,
                }}
                onClick={this.selectParent.bind(this, index)}
              >
                {node.node_localizations[0].title || node.node_localizations[0].position}
              </p>
            ))}
          </div>
          <OrgContacts node={currentNode} />
        </div>
        {/* --- Конец --- Левая панель с иерархической структурой и контактным блоком  --- */}

        {/* --- Правая панель с вложенными нодами --- */}
        <div className="org-structure-panel children-panel">
          {!!activeNodes.length
            && activeNodes[activeNodes.length - 1]
              .children
              .sort((a, b) => a.priority - b.priority)
              .map(node => !!node.node_localizations.length &&
                <div className="children"
                     key={node.id}
                     onClick={this.selectChild.bind(this, node)}
                     style={{ backgroundColor: node.id === currentNode.id ? '#f8ca7f' : (node.color || '#ffffff') }}
                >
                  <p>{node.node_localizations[0].title}</p>
                  {/*<p>{node.node_localizations[0].position || node.node_localizations[0].title}</p>*/}
                  { node.isChildren && <div className="arrow" /> }
                </div>)
          }
        </div>
        {/* --- Конец --- Правая панель с вложенными нодами --- */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.general.localization.language.locale,
});

export default connect(mapStateToProps)(OrgStructure);
