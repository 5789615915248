/* eslint-disable react/no-array-index-key */
import React from 'react';

const BigNewsTags = ({ tags, all }) => (all !== true ? (
  <React.Fragment>
    {tags.map((tag, i) => (i < 3) && (
    <div key={`tag-item-${tag.id}`} className="tag-item">
      {tag.title}
    </div>
    ))}
    {(tags.length > 3) && (
    <span>
      {' '}
+
      {tags.length - 3}
    </span>
    )}
  </React.Fragment>
) : (
  <React.Fragment>
    {tags.map(tag => (
      <div key={`tag-item-${tag.id}`} className="tag-item">
        {tag.title}
      </div>
    ))}
  </React.Fragment>
));

export default BigNewsTags;
