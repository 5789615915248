/* eslint-disable react/no-array-index-key,camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReactToPrint from 'react-to-print';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

import '../assets/styles/newsItem.scss';

import { CORE_URL, BASE_URL } from '../constants/urls';

import {
  getDescriptionWidgets,
  addLoadPlaceholders,
  formatDateString,
  addMedia,
  formatPublishDate,
} from '../services/main';
import fetchOne from '../services/fetch';

import Loader from '../modules/views/Loader';
import SliderSecondary from '../components/SliderSecondary';
import ContentBlock from '../components/ContentBlock';
import SmallNewsBlock from '../components/news/SmallNewsBlock';
import Breadcrumbs from '../components/util/Breadcrumbs';
import Page from '../modules/views/Page';
import ShareButton from '../components/util/ShareButton';
import Gallery from "../components/Gallery";
import BigNewsTags from "../components/news/BigNewsTags";
import IframeContainer from '../components/util/IframeContainer';

import '../assets/styles/table.scss';
import Iblocks from '../components/Iblocks'

class NewsItem extends React.Component {
  constructor(props) {
    super(props);

    this.description = React.createRef();
    this.prevX = 0;
    this.prevY = 0;

    this.state = {
      description: '',
      created_at: '',
      published_at: '',
      title: '',
      tags: [],

      related: {},

      loading: true,
      error: false,

      link: `${CORE_URL}/news/${this.props.match.params.alias}`,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getContent();
  }

  componentDidUpdate(oldProps) {
    const { match, lang } = this.props;
    if (oldProps.match.params.alias !== match.params.alias || oldProps.lang !== lang) {
      this.getContent();
      window.scrollTo(0, 0);
    }
    // if (this.state.title) {
    //   this.setMetaTags();
    // }
  }

  setMetaTags() {
    const title = document.createElement('meta');
    title.setAttribute('property', 'og:title');
    title.content = this.state.title;
    document.getElementsByTagName('head')[0].appendChild(title);

    const article = document.createElement('meta');
    article.setAttribute('property', 'og:type');
    article.content = 'article';
    document.getElementsByTagName('head')[0].appendChild(article);

    // const image = document.createElement('meta');
    // image.setAttribute('property', 'og:image');
    // image.content = this.props.image;
    // document.getElementsByTagName('head')[0].appendChild(image);
  }

  getContent = async () => {
    const { match } = this.props;
    try {
      const newsItem = await fetchOne({
        url: `${CORE_URL}/news/alias/${match.params.alias}`,
      });
      if (newsItem.error) {
        this.setState({
          loading: false,
          error: true,
        });
      } else {
        if (!newsItem.news_localizations[0]) {
          this.setState({
            loading: false,
            withoutLocalization: true,
          });
          return;
        }
        this.setState({
          error: false,
          withoutLocalization: false,
          link: `${BASE_URL}/news/${match.params.alias}`,
        });
        this.getContentLocalization(newsItem);
      }
    } catch (err) {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  getContentLocalization = async (newsItem) => {
    const {
      created_at, localization, title, description, tags,
    } = newsItem.news_localizations[0];

    const { published_at } = newsItem;

    const parsedDescription = getDescriptionWidgets(addLoadPlaceholders(addMedia(description)));

    const date = formatPublishDate(new Date());
    const query = `localization=${localization.id}&published_at=${date}`;

    const { collection, pagination } = await fetchOne({
      url: `${CORE_URL}/news/${newsItem.id}/related?${query}`,
    });

    const clearedRelated = collection
      .filter(({ is_active }) => is_active)
      .sort((a, b) => {
        const prev = a.news_localizations[0].tags.length;
        const next = b.news_localizations[0].tags.length;
        if (prev === next) {
          const prevCreate = a.published_at || a.created_at;
          const nextCreate = b.published_at || b.created_at;
          const prevDate = new Date(prevCreate);
          const nextDate = new Date(nextCreate);
          return -(prevDate - nextDate);
        }
        return -(prev - next);
      });

    this.setState({
      description: parsedDescription,
      published_at,
      created_at,
      tags,

      title,

      related: {
        pagination,
        collection: clearedRelated.slice(0, 4),
      },

      loading: false,
    });
  };

  render() {
    const {
      loading, error, withoutLocalization, tags, created_at,
      title, description, related, published_at,
    } = this.state;

    if (loading) return (<Loader />);

    // or date created
    const date = formatDateString(published_at || created_at);

    return (
      <Page
        error={error}
        loading={loading}
        title={title}
        localization={withoutLocalization}
        languageChange={this.getContent}
      >
        <div className="container news-detail">
          <div className="row no-gutter">
            <div className="col-xl-12 breadcrumb-wrapper">
              <Breadcrumbs title={title} />
            </div>
          </div>
          <ContentBlock>
            {/*<Helmet>*/}
            {/*  <meta property="og:title" content="Test" />*/}
            {/*  <meta property="og:type" content="article" />*/}
            {/*</Helmet>*/}
            <div className="news-section">
              <div className="news-item-wrapper">
                <div className="news-item-title_wrapper">
                  <h3 className="news-item-title">
                    {title}
                  </h3>
                  <div className="news-info-share_wrapper">
                    <p className="align-items-center news-date">
                      <strong className="p-1">{date.hhmm}</strong>
                      <span className="p-1">{date.ddmmyyyy}</span>
                    </p>
                    <div className="share-news-wrapper" style={{ justifyContent: 'flex-end' }}>
                      <div>
                        <ReactToPrint
                          trigger={() => <div className="print"/>}
                          content={() => this.description.current}
                          pageStyle="padding: 10px"
                        />
                        <ShareButton page down link={this.state.link} title={title}/>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  ref={this.description}
                  className="news-item-description from-editor"
                >
                  {!!description && description.map((item, i) => {
                    if('text' === item.type){
                      return ReactHtmlParser(item.text);
                    } else if('media' === item.type){
                      return (<Gallery key={i} media={item.media} title={item.title} />);
                    } else if ('iframe' === item.type) {
                      return (<IframeContainer iframe={item.media[0].iframe} />);
                    } else if (item.type === 'iblocks') {
                      return (<Iblocks key={i} media={item.media} title={item.title} />);
                    }
                  })}
                </div>
	              <div className="tags">
		              <BigNewsTags tags={tags} all />
	              </div>
                <div className="bottom-date-wrapper">
                  <p className="align-items-center news-date">
                    <strong className="p-1">{date.hhmm}</strong>
                    <span className="p-1">{date.ddmmyyyy}</span>
                  </p>
                  <div className="share-news-wrapper">
                    {/*<div className="tags">*/}
                    {/*  <BigNewsTags tags={tags} all />*/}
                    {/*</div>*/}
                    <div>
                      <ReactToPrint
                          trigger={() => <div className="print"/>}
                          content={() => this.description.current}
                          pageStyle=""
                      />
                      <ShareButton page down link={this.state.link} title={title}/>
                    </div>
                  </div>
                </div>
	              <div className="news-separator"></div>
              </div>
            </div>
            <div className="list-wrapper news-item-related">
              <div className="list-title">Схожі новини</div>
              <SliderSecondary
                settings={{
                  dots: true,
                  infinite: true,
                  speed: 500,
                  slidesToShow: 4,
                  slidesToScroll: 4,
                  responsive: [
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                  ],
                }}
              >
                {related.collection && related.collection.map((item, index) => (
                  <React.Fragment key={`news-related-item-${index}`}>
                    <SmallNewsBlock parent="/news" page={item} />
                  </React.Fragment>
                ))}
              </SliderSecondary>
            </div>
          </ContentBlock>
        </div>
      </Page>
    );
  }
}

NewsItem.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  lang: state.general.localization.language.locale,
});

export default connect(mapStateToProps)(NewsItem);
