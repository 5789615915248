/* eslint-disable react/prop-types, no-nested-ternary */
import React from 'react';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { NavLink } from 'react-router-dom';
import sstcImg from '../../assets/images/sstc.png';
import DynamicBreadcrumb from "./DynamicBreadcrumb";

const HomePartBreadcrumb = () => (
  <img
    style={{ marginRight: 5 }}
    src={sstcImg}
    alt=""
  />
);

const routes = [
  { path: '/', breadcrumb: HomePartBreadcrumb },
  { path: '/:path', breadcrumb: DynamicBreadcrumb },
  { path: '/:path/:alias', breadcrumb: '' },
];

const maxlimit = 50;

const Breadcrumbs = ({ breadcrumbs, string, title }) => (
  <div className="line-breadcrumb">
    {breadcrumbs.map(({ match, breadcrumb }, index) => (
      <span key={match.url}>
        <NavLink to={match.url}>
          {(string && match.url !== '/')
            ? (string.length > maxlimit ? `${(string).substring(0, maxlimit - 3)}...` : string)
            : (match.params.alias && title
              ? ((title).length > maxlimit)
                ? (`${(title).substring(0, maxlimit - 3)}...`)
                : title
              : breadcrumb)}
          {(index !== breadcrumbs.length - 1) && <i className="fas fa-angle-right" />}
        </NavLink>
      </span>
    ))}
  </div>
);

export default withBreadcrumbs(routes)(Breadcrumbs);
