/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';

import '../assets/styles/pages/books.scss';

import { connect } from 'react-redux';
import { CORE_URL, GET_BOOK_SERIES, GET_FILTERED_BOOKS } from '../constants/urls';
import fetchOne from '../services/fetch';

import Page from '../modules/views/Page';
import ContentBlock from '../components/ContentBlock';
import Breadcrumbs from '../components/util/Breadcrumbs';

import Book from '../components/editions/Book';
import SliderSecondary from '../components/SliderSecondary';

import Filters from '../components/editions/FiltersBooks';
import BooksFilterAccordion from '../components/editions/BooksFilterAccordion';
import { clearPageDescription, getTranslation } from '../services/main';

class Edition extends Component {
  state = {
    pastFilterData: {
      tags: [],
      covers: [],
    },

    withoutLocalization: false,
    loading: true,
    error: false,

    books_series: [],
    books_sale: [],
    books_no_series: [],
    books: [],
    books_filtered: [],
    type: 'page',

    search: '',
    query: '',
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.location.search) {
      this.getBooks(this.props.location.search);
    } else {
      this.getBooks();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search) {
      this.getBooks(nextProps.location.search);
    }else {
      this.getBooks();
    }
  }

  getBooks = async (type) => {
    try {
      let books_series;
      let books;
      if (type) {
        let updType = type === '?type=fiction' ? '?type=1' : '?type=2';
        books_series = await fetchOne({url: `${CORE_URL}/book-series${updType}&is_published=true&isActive=true&limit=1000`});
        books = await fetchOne({url: `${CORE_URL}/books${updType}&is_published=true&isActive=true&limit=1000`});

        this.setState({
          type: type.split('?')[1]
        });

      } else {
        books_series = await fetchOne({url: `${CORE_URL}/book-series?is_published=true&isActive=true&limit=1000`});
        books = await fetchOne({url: `${CORE_URL}/books?is_published=true&isActive=true&limit=1000`});

        this.setState({
          type: 'page'
        });
      }

      const books_sale = books.collection
      .filter(item => item.is_sale && item.is_active)
      .filter(item => !!item.book_localizations.length)
      .sort((a, b) => {
        const prev = a.published_at || a.created_at;
        const next = b.published_at || b.created_at;
        const prevDate = new Date(prev);
        const nextDate = new Date(next);
        return -(prevDate - nextDate);
      });

      const books_no_series = books.collection
        .filter(item => !item.series && item.is_active)
        .filter(item => !!item.book_localizations.length)
        .sort((a, b) => {
          const prev = a.published_at || a.created_at;
          const next = b.published_at || b.created_at;
          const prevDate = new Date(prev);
          const nextDate = new Date(next);
          return -(prevDate - nextDate);
      });

      this.setState({
        books: books.collection.filter(item => !!item.book_localizations.length),
        books_sale,
        books_no_series,
        books_filtered: [],
      });

      // const withLocalization = books_series.every(item => item.book_series_localizations[0]);
      const withLocalization = books_series.filter(item => item.book_series_localizations[0]);

      if (withLocalization) {
        const fitInModel = books_series
          .filter(item => item.book_series_localizations[0] && item.books.length)
          .sort((a, b) => (a.priority - b.priority))
          .map((item) => {
            const { book_series_localizations, books, ...rest } = item;
            const { id, ...localizationData } = book_series_localizations[0];
            return {
              series: {
                ...localizationData,
                ...rest,
              },
              books,
            };
          });

        this.setState({
          books_series: fitInModel,
          loading: false,
        });
      } else {
        this.setState({
          books_series: [],
          withoutLocalization: true,
          loading: false,
          error: true,
        });
      }
    } catch (err) {
      this.setState({
        books_series: [],
        books: [],
        books_no_series: [],
        books_sale: [],
        books_filtered: [],
        type: 'page',
        error: true,
        loading: false,
      });
    }
  };

  getFilteredBooks = async () => {
    this.setState({loading: true});
    const {query, search} = this.state;

    let withQuery;
    const type = this.props.location.search;
    if (type) {
      withQuery = query || search ? `${type}&is_published=true&isActive=true&${query && (`${query}&`)}${search && search}` : '';
    } else {
      withQuery = query || search ? `?is_published=true&isActive=true&${query && (`${query}&`)}${search && search}` : '';
    }
    const url = GET_FILTERED_BOOKS + (withQuery && withQuery);

    const books = await fetchOne({ url });

    this.setState({
      books: [],
      books_filtered: books.collection.filter(item => !!item.book_localizations.length),
      books_no_series: [],
      books_sale: [],
      books_series: [],
      loading: false,
    });
  };

  syncPastFilterData = pastFilterData => this.setState({ pastFilterData });

  setFiltersQuery = query => this.setState({ query }, this.getFilteredBooks);

  onStartChange = ({ target }) => this.setState({ search: target.value }, this.getFilteredBooks);

  render() {
    const {
      withoutLocalization, error,
      books, books_sale, books_no_series, loading, books_filtered, pastFilterData, type,
    } = this.state;

    const count = books.length ? books.length : books_filtered.length;
    const count_active = books.length ? books.filter(item => item.is_active).length : null;

    const { match, translations } = this.props;

    let { books_series } = this.state;

    if (this.state.type !== 'page') {
      const ntype = this.state.type.split('=')[1] === 'fiction' ? 1 : 2;
      // const ntype = parseInt(this.state.type.split('=')[1]);
      const copyBookSeries = [...books_series];
      const filteredCopyBookSeries = copyBookSeries.map(s => {
        const num = s.books.filter(b => b.is_active && b.type === ntype);
        if (num.length > 0) {
          s.books = num;
          return s;
        }
      }).filter(s => !!s);
      books_series = filteredCopyBookSeries;
    }

    return (
      <Page
        error={error}
        loading={loading}
        title={clearPageDescription(getTranslation(`book-${type}`, translations))}
        localization={withoutLocalization}
        languageChange={this.getBooks}
      >
        <div className="container editions-page">
          <div className="row no-gutter d-block">
            <div className="col-xl-12 breadcrumb-wrapper">
              <Breadcrumbs
                // string={clearPageDescription(getTranslation(`book-${type}`, translations))}
                // string={clearPageDescription(getTranslation(`book-${type}`, translations))}
              />
            </div>
          </div>
          <div className="row no-gutter">
            <div className="col-xl-12 p-0">
              <p className="page-title">
                {clearPageDescription(getTranslation(`book-page`, translations))}
                {/*{clearPageDescription(getTranslation(`book-${type}`, translations))}*/}
              </p>
            </div>
          </div>
          <ContentBlock>
            {/*<div className="news-item-wrapper">*/}
              {/*<div className="book-filter-block-wrapper">*/}
              {/*  <BooksFilterAccordion*/}
              {/*    header={(*/}
              {/*      <div className="search-block">*/}
              {/*        <input type="search" onChange={this.onStartChange} />*/}
              {/*        <button type="submit" className="search-submit" />*/}
              {/*      </div>*/}
              {/*    )}*/}
              {/*    button={(<p>{clearPageDescription(getTranslation('filters', translations))}</p>)}*/}
              {/*    body={(*/}
              {/*      <Filters*/}
              {/*        onFilterFind={this.setFiltersQuery}*/}
              {/*        syncPastFilterData={this.syncPastFilterData}*/}
              {/*        pastFilterData={pastFilterData}*/}
              {/*      />*/}
              {/*    )}*/}
              {/*    count={count}*/}
              {/*    count_active={count_active}*/}
              {/*  />*/}
              {/*</div>*/}
            {/*</div>*/}

            {!!books_sale.length && (
              <div
                className="row no-gutter d-block"
              >
                <div
                  className="col-xl-12 about-content"
                >
                  <div className="books-category">
                    <div className="books-category-header">
                      {clearPageDescription(getTranslation('book-sale', translations))}
                      {' '}
                      :
                      {' '}
                      {books_sale.length}
                    </div>
                  </div>
                </div>

                {/*<div className={books_sale.length > 4 ? 'row-main with-arrows' : 'row-main'}>*/}
                <div className={books_sale.length > 4 ? 'row-main with-hr with-arrows' : 'row-main with-hr'}>
                  <SliderSecondary
                    settings={{
                      dots: true,
                      infinite: books_sale.length > 4,
                      speed: 500,
                      slidesToShow: 4,
                      slidesToScroll: 4,
                      initialSlide: 0,
                      responsive: [
                        {
                          breakpoint: 1024,
                          settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            // infinite: true,
                            // dots: true,
                          },
                        },
                        {
                          breakpoint: 600,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2,
                          },
                        },
                        {
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false,
                          },
                        },
                      ],
                    }}
                  >
                    {books_sale.map(book => (
                      <div
                        key={`book-item-${book.id}`}
                        className="book-item-wrapper"
                      >
                        <div className="book-item single-centered">
                          <Book
                            photo={book.photo}
                            cover={book.cover}
                            title={book.book_localizations.length ? book.book_localizations[0].title : ''}
                            rating={book.rating}
                            reviews={book.reviews && book.reviews.filter(i => i.status === 2).length}
                            id={book.id}
                            alias={book.alias}
                            parent={match.path}
                            tags={book.book_localizations.length ? book.book_localizations[0].tags : ''}
                            price={book.price}
                            currency={book.currency}
                            is_sale={book.is_sale}
                          />
                        </div>
                      </div>
                    ))}
                  </SliderSecondary>
                </div>
              </div>
            )}

            {!!books_series.length
            && books_series.map((item, index) => {
               return <div
                        key={`book-category-${index + 1}`}
                        className="row no-gutter d-block"
                >
                  <div
                          key={`book-category-${index}`}
                          className="col-xl-12 about-content"
                  >
                    <div className="books-category">
                      <div className="books-category-header">
                        {item.series.title}
                        {' '}
                        :
                        {' '}
                        {item.books.length}
                      </div>
                    </div>
                  </div>

                  {/*<div className={item.books.length > 4 ? 'row-main with-arrows' : 'row-main'}>*/}
                  <div className={item.books.length > 4 ? 'row-main with-hr with-arrows' : 'row-main with-hr'}>
                    <SliderSecondary
                            settings={{
                              dots: true,
                              infinite: item.books.length > 4,
                              speed: 500,
                              slidesToShow: 4,
                              slidesToScroll: 4,
                              initialSlide: 0,
                              responsive: [
                                {
                                  breakpoint: 1024,
                                  settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3,
                                    // infinite: true,
                                    // dots: true,
                                  },
                                },
                                {
                                  breakpoint: 600,
                                  settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2,
                                    initialSlide: 2,
                                  },
                                },
                                {
                                  breakpoint: 480,
                                  settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    dots: false,
                                  },
                                },
                              ],
                            }}
                    >
                      {item
                      .books.filter(b => b.is_active === true)
                      .sort((a, b) => {
                        const prev = a.published_at || a.created_at;
                        const next = b.published_at || b.created_at;
                        const prevDate = new Date(prev);
                        const nextDate = new Date(next);
                        return -(prevDate - nextDate);
                      })
                      .map(book => {
                        return (
                                <div
                                        key={`book-item-${book.id}`}
                                        className="book-item-wrapper"
                                >
                                  <div className="book-item single-centered">
                                    <Book
                                            photo={book.photo}
                                            cover={book.cover}
                                            title={book.book_localizations.length ? book.book_localizations[0].title : ''}
                                            rating={book.rating}
                                            reviews={book.reviews && book.reviews.filter(i => i.status === 2).length}
                                            id={book.id}
                                            alias={book.alias}
                                            parent={match.path}
                                            tags={book.book_localizations ? book.book_localizations[0].tags : ''}
                                            price={book.price}
                                            currency={book.currency}
                                            is_sale={book.is_sale}
                                    />
                                  </div>
                                </div>)
                      })}
                    </SliderSecondary>
                  </div>
                </div>
            })}

            {!!books_no_series.length && (
              <div
                className="row no-gutter d-block"
              >
                <div
                  className="col-xl-12 about-content"
                >
                  <div className="books-category">
                    <div className="books-category-header">
                      {clearPageDescription(getTranslation('book-no-series', translations))}
                      {' '}
                      :
                      {' '}
                      {books_no_series.length}
                    </div>
                  </div>
                </div>

                <div className={books_no_series.length > 4 ? 'row-main with-arrows' : 'row-main'}>
                {/*<div className={books_no_series.length > 4 ? 'row-main with-hr with-arrows' : 'row-main with-hr'}>*/}
                  <SliderSecondary
                    settings={{
                      dots: true,
                      infinite: books_no_series.length > 4,
                      speed: 500,
                      slidesToShow: 4,
                      slidesToScroll: 4,
                      initialSlide: 0,
                      responsive: [
                        {
                          breakpoint: 1024,
                          settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            // infinite: true,
                            // dots: true,
                          },
                        },
                        {
                          breakpoint: 600,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2,
                          },
                        },
                        {
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false,
                          },
                        },
                      ],
                    }}
                  >
                    {books_no_series
                      .map(book => {
                    return <div
                        key={`book-item-${book.id}`}
                        className="book-item-wrapper"
                      >
                        <div className="book-item single-centered">
                          <Book
                            photo={book.photo}
                            cover={book.cover}
                            title={book.book_localizations.length ? book.book_localizations[0].title : ''}
                            rating={book.rating}
                            reviews={book.reviews && book.reviews.filter(i => i.status === 2).length}
                            id={book.id}
                            alias={book.alias}
                            parent={match.path}
                            tags={book.book_localizations.length ? book.book_localizations[0].tags : ''}
                            price={book.price}
                            currency={book.currency}
                            is_sale={book.is_sale}
                          />
                        </div>
                      </div> }
                    )}
                  </SliderSecondary>
                </div>
              </div>
            )}

            {!!books_filtered.length && (
              <div
                className="row no-gutter d-block"
              >
                <div
                  className="col-xl-12 about-content"
                >
                  <div className="books-category">
                    <div className="books-category-header">
                      {clearPageDescription(getTranslation('search', translations))}
                      {' '}
                      :
                      {' '}
                      {books_filtered.length}
                    </div>
                  </div>
                </div>

                <div className={books_filtered.length > 4 ? 'row-main with-hr with-arrows' : 'row-main with-hr'}>
                  <SliderSecondary
                    settings={{
                      dots: true,
                      infinite: books_filtered.length > 4,
                      speed: 500,
                      slidesToShow: 4,
                      slidesToScroll: 4,
                      initialSlide: 0,
                      responsive: [
                        {
                          breakpoint: 1024,
                          settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            // infinite: true,
                            // dots: true,
                          },
                        },
                        {
                          breakpoint: 600,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2,
                          },
                        },
                        {
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false,
                          },
                        },
                      ],
                    }}
                  >
                    {books_filtered.map(book => (
                      <div
                        key={`book-item-${book.id}`}
                        className="book-item-wrapper"
                      >
                        <div className="book-item single-centered">
                          <Book
                            photo={book.photo}
                            cover={book.cover}
                            title={book.book_localizations.length ? book.book_localizations[0].title : ''}
                            rating={book.rating}
                            reviews={book.reviews && book.reviews.filter(i => i.status === 2).length}
                            id={book.id}
                            alias={book.alias}
                            parent={match.path}
                            tags={book.book_localizations.length ? book.book_localizations[0].tags : ''}
                            price={book.price}
                            currency={book.currency}
                            is_sale={book.is_sale}
                          />
                        </div>
                      </div>
                    ))}
                  </SliderSecondary>
                </div>
              </div>
            )}

            {!(books.length || books_series.length || books_filtered.length)
            && (
            <h3 className="not-found-text">
              {clearPageDescription(getTranslation('not-found', translations))}
            </h3>
            )}
          </ContentBlock>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translations
});

export default connect(mapStateToProps)(Edition);
