/* eslint-disable react/no-array-index-key */
import React from 'react';
import '../assets/styles/pages/contacts.scss';
import { connect } from 'react-redux';
import Breadcrumbs from '../components/util/Breadcrumbs';
import fetchOne from '../services/fetch';
import ContactAffiliatesAccordion from '../components/util/ContactAffiliatesAccordion';
import Page from '../modules/views/Page';
import { clearPageDescription, getTranslation } from '../services/main';
import FeedbackForm from '../components/contacts/FeedbackForm';
import ContactMap from '../components/contacts/ContactMap';
import { CORE_URL } from '../constants/urls';

class Contact extends React.Component {
  state = {
    contacts: [],
    showMap: false,
    titleButton: clearPageDescription(getTranslation('map-show', this.props.translations)),
    initialCenter: {},
    contactTitle: '',
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getContacts();
  }

  getContacts = async () => {
    const contacts = await fetchOne({ url: `${CORE_URL}/system-contacts` });
    this.setState({ contacts });
  };

  toggleFormMap = (idContact) => {
    const { translations } = this.props;
    const contact = this.state.contacts.find(c => c.department.id === idContact);
    this.setState(state => ({
      showMap: !state.showMap,
      titleButton: !state.showMap
        ? clearPageDescription(getTranslation('map-hide', translations))
        : clearPageDescription(getTranslation('map-show', translations)),
      initialCenter: {
        lat: contact.department.latitude, lng: contact.department.longitude,
      },
      contactTitle: contact.department.department_localizations[0].title,
    }));
  };

  hideMap = () => this.setState({
    showMap: false,
    titleButton: clearPageDescription(getTranslation('map-show', this.props.translations)),
  });

  render() {
    const { contacts, showMap, titleButton, initialCenter, contactTitle } = this.state;
    const { translations } = this.props;

    return (
      <Page
        error={false}
        loading={false}
        title={clearPageDescription(getTranslation('contacts-page', translations))}
        languageChange={this.getContacts}
      >
        <div className="container contacts-page">
          <div className="row no-gutter">
            <div className="col-xl-12 breadcrumb-wrapper">
              <Breadcrumbs />
            </div>
          </div>
          <div className="contact-big-name">
            {clearPageDescription(getTranslation('contacts-page', translations))}
          </div>
          <div className="row">
            <div className="col-xl-6 equal-height">

              <div className="contact-block">

                {!!contacts.length && (
                  <div className="top-border">
                    <ContactAffiliatesAccordion
                      contacts={contacts}
                      toggleFormMap={this.toggleFormMap}
                      hideMap={this.hideMap}
                      titleButton={titleButton}
                    />
                  </div>
                )
                }
              </div>
            </div>

            <div className="col-xl-6 equal-height">
              {showMap ? (
                <div className="contact-block right" style={{ maxHeight: '645px' }}>
                  <ContactMap
                    show={showMap}
                    initialCenter={initialCenter}
                    contactTitle={contactTitle}
                  />
                </div>
              )
                : <FeedbackForm contacts={contacts} translations={translations} />
              }
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ translations }) => ({
  translations,
});

export default connect(mapStateToProps)(Contact);
