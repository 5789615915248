import { SWITCH_LANGUAGE } from '../constants/redux';

import addressContentRU from '../services/languages/ru/ru_address';
import linksContentRU from '../services/languages/ru/ru_links';
import confidentialContentRU from '../services/languages/ru/ru_confidential';
import servicesContentRU from '../services/languages/ru/ru_services';
import partnersContentRU from '../services/languages/ru/ru_partners';
import bigHeadersContentRU from '../services/languages/ru/ru_big_headers';
import aesStateRU from "../services/languages/ru/ru_aes_state";
import booksRU from "../services/languages/ru/ru_books";

import addressContentUA from '../services/languages/ua/ua_address';
import linksContentUA from '../services/languages/ua/ua_links';
import confidentialContentUA from '../services/languages/ua/ua_confidential';
import servicesContentUA from '../services/languages/ua/ua_services';
import partnersContentUA from '../services/languages/ua/ua_partners';
import bigHeadersContentUA from '../services/languages/ua/ua_big_headers';
import aesStateUA from "../services/languages/ua/ua_aes_state";
import booksUA from "../services/languages/ua/ua_books";

import addressContentEN from '../services/languages/en/en_address';
import linksContentEN from '../services/languages/en/en_links';
import confidentialContentEN from '../services/languages/en/en_confidential';
import servicesContentEN from '../services/languages/en/en_services';
import partnersContentEN from '../services/languages/en/en_partners';
import bigHeadersContentEN from '../services/languages/en/en_big_headers';
import aesStateEN from "../services/languages/en/en_aes_state";
import booksEN from "../services/languages/en/en_books";

const blocks = {
  eng: {
    address: addressContentEN,
    links: linksContentEN,
    confidential: confidentialContentEN,
    services: servicesContentEN,
    partners: partnersContentEN,
    big_headers: bigHeadersContentEN,
    aes_state: aesStateEN,
    // books: booksEN,
  },
  rus: {
    address: addressContentRU,
    links: linksContentRU,
    confidential: confidentialContentRU,
    services: servicesContentRU,
    partners: partnersContentRU,
    big_headers: bigHeadersContentRU,
    aes_state: aesStateRU,
    // books: booksRU,
  },
  uk: {
    address: addressContentUA,
    links: linksContentUA,
    confidential: confidentialContentUA,
    services: servicesContentUA,
    partners: partnersContentUA,
    big_headers: bigHeadersContentUA,
    aes_state: aesStateUA,
    // books: booksUA,
  },
};

const i18n = (state = blocks.uk, action) => {
  switch (action.type) {
    case SWITCH_LANGUAGE:
      return blocks[action.language];
    default:
      return state;
  }
};

export default i18n;
