/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Area, AreaChart, Tooltip, XAxis, YAxis,
} from 'recharts';

import '../assets/styles/chartCard.scss';
import PropTypes from "prop-types";
import {fetchStructure} from "../actions/org_structure";
import connect from "react-redux/es/connect/connect";
import { clearPageDescription, getTranslation } from '../services/main';

class ChartCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chart: 'radiation',
      data: props.data,
    };
  }

  changeChart = (chart) => {
    this.setState({
      chart,
    });
  };

  render() {
    const { chart, data } = this.state;
    const {translations, screen} = this.props;

    const name = data.name;
    const dataChart = data.data;

    return (
      <div className="chart-item">
        <div className="chart-item_title">
          {name && name === 'khmelnytskyi_npp' && clearPageDescription(getTranslation('aes-khmelnytskyi', translations))}
          {name && name === 'rivne_npp' && clearPageDescription(getTranslation('aes-rivne', translations))}
          {name && name === 'south_ukraine_npp' && clearPageDescription(getTranslation('aes-south-ukraine', translations))}
          {name && name === 'zaporizhia_npp' && clearPageDescription(getTranslation('aes-zaporizhia', translations))}
          </div>
        <ul className="chart-item_nav">
          <li className={`${chart === 'radiation' ? 'active' : ''}`} onClick={() => this.changeChart('radiation')}>
            {clearPageDescription(getTranslation('aes-radiation', translations))}
          </li>
          {dataChart.length && dataChart[0].blocks.map(i => (
            <li key={i}
                className={`${chart === i ? 'active' : ''}`} onClick={() => this.changeChart(i)}>
              {clearPageDescription(getTranslation('aes-block', translations))} {i}
            </li>
          ))}
        </ul>
        <div className="chart-item_content">
          <p className="chart-item_desc">
            {clearPageDescription(getTranslation('aes-radiation-about', translations))}
          </p>
          <div className="chart-item_area">
            <AreaChart
              width={screen ? 270 : 350}
              height={200}
              data={dataChart}
            >
              <defs>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#df6d17" stopOpacity={0.5} />
                  <stop offset="95%" stopColor="#df6d17" stopOpacity={0.4} />
                </linearGradient>
              </defs>
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey={chart} stroke="#df6d17" fillOpacity={1} fill="url(#colorPv)" />
            </AreaChart>
          </div>
        </div>
      </div>
    );
  }
}

ChartCard.propTypes = {
  translations: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  translations: state.translations,
});

const mapDispatchToProps = dispatch => ({
  getStructure: () => dispatch(fetchStructure()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartCard);
