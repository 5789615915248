import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Logo from '../../components/util/Logo';
import Social from '../../components/util/Social';

import { switchLanguage } from '../../actions/i18n';
import { withParent } from '../../services/main';

import '../../assets/styles/mobileHeader.scss';

import Burger from '../../assets/svg/Burger.svg';
import Close from '../../assets/svg/close.svg';
import { initSearch } from '../../actions/main';

const itemHeight = 36;

class MobileHeader extends Component {
  constructor(props) {
    super(props);
    this.linkTimeout = '';
    this.state = {
      telegramMenu: false,
      langMenu: false,
      subNav: false,
      open: false,
      num: 0,
      search: '',
    };
  }

  componentWillUnmount() {
    clearTimeout(this.linkTimeout);
  }

  setLanguage = (index, language) => {
    this.setState(state => ({ langMenu: !state.langMenu }));
    if (index) {
      this.setState({ langMenu: false });
      const { changeLanguage } = this.props;
      changeLanguage(language);
    }
  };

  selectBot = () => this.setState({ telegramMenu: false });

  toggle = () => this.setState(state => ({ open: !state.open }));

  toggleTelegramMenu = () => this.setState(state => ({ telegramMenu: !state.telegramMenu }));

  toggleSubNav = num => (ev) => {
    const { content: { menu } } = this.props;
    const { num: current } = this.state;
    const children = menu.length ? menu[num].children : [];
    if (children.length && current !== num) {
      ev.preventDefault();
      this.setState({ subNav: true, num });
    } else {
      this.linkTimeout = setTimeout(() => {
        this.setState({
          subNav: false,
          open: false,
        });
      }, 100);
    }
  };

  // setSearch = () => {
  //   const { initGlobalSearch, history } = this.props;
  //   // const { value } = this.searchRef.current;
  //   // initGlobalSearch(value);
  //   history.push('search');
  // };
  //
  // onKeyDownHandler = ({ keyCode }) => {
  //   if (keyCode === 13) {
  //     const { initGlobalSearch, history } = this.props;
  //     // const { value } = this.searchRef.current;
  //     // initGlobalSearch(value);
  //     history.push('search');
  //   }
  // };

  onSubmitSearch = (e) => {
    const { initGlobalSearch, history } = this.props;
    e.preventDefault();
    initGlobalSearch(this.state.search);
    history.push('search');
  }

  searchOnChange = (e) => this.setState({ search: e.target.value });

  render() {
    const { localization, content } = this.props;
    const {
      subNav, langMenu,
      telegramMenu, open, num,
    } = this.state;

    const { menu, header } = content;
    const { languages } = localization;
    const children = menu.length ? menu[num].children : [];

    return (
      <div className={open ? 'header-block opened-nav' : 'header-block'}>
        <div className="mobile-top-header">
          <div className="mobile-logo-level">
            <Logo header />
            <div
              onClick={this.toggle}
              role="presentation"
            >
              {open
                ? <img className="mobile-open-close-image" src={Close} alt="" />
                : <img className="mobile-open-close-image" src={Burger} alt="" />}
            </div>
          </div>
          <div className="col-xl-4 mobile-header-right p-0">
            <form onSubmit={this.onSubmitSearch}>
              <div className="mobile-search-block">
                <input className=""
                       value={this.state.search}
                       onChange={this.searchOnChange}
                       type="search"
                />
                <button
                        className="search-submit"
                />
              </div>
            </form>
            <div className="header-right_top">

              <ul
                className={langMenu ? 'language-menu opened' : 'language-menu'}
                style={{
                  height: langMenu
                    ? `${languages.filter(el => el.is_active).length * itemHeight}px`
                    : `${itemHeight}px`,
                }}
              >
                {languages.filter(el => el.is_active).map((lang, i) => (
                  <li
                    key={`languages-menu-item-${lang.id}`}
                    className="language-menu_item"
                    style={{
                      transform: langMenu ? `translateY(${`${i * 100}%`})` : 'translateY(0)',
                      borderTop: i ? '1px solid #f6f6f6' : '',
                      zIndex: languages.length - i,
                    }}
                    onTouchEnd={() => this.setLanguage(i, lang.locale)}
                    role="presentation"
                  >
                    <img
                      src={lang.icon && (lang.icon.resize || lang.icon.href)}
                      className="language-menu-icon"
                      alt=""
                    />
                    <span className="lm_item_text">
                      {lang.locale.toUpperCase()}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {open && (
          <div className="mobile-nav-block">
            <div className="mobile-main-nav">
              {menu.map((item, i) => (
                <div
                  key={`nav-item-${item.id}`}
                  className="mob-nav-itm"
                >
                  {withParent(menu, item) !== '/empty' ?
                  <div className="mob-nav-itm-block">
                    <Link
                      to={withParent(menu, item)}
                      onClick={this.toggleSubNav(i)}
                    >
                      {(num === i && subNav) && (
                        <div className="sub-item-rotate">
                          <div className="arrow-right" />
                        </div>
                      )}
                      {item.menu_localizations.length
                        && item.menu_localizations[0].title}
                    </Link>
                  </div> :
                          <div className="mob-nav-itm-block">
                            <Link
                                    // to={withParent(menu, item)}
                                    onClick={this.toggleSubNav(i)}
                            >
                              {(num === i && subNav) && (
                                      <div className="sub-item-rotate">
                                        <div className="arrow-right" />
                                      </div>
                              )}
                              {item.menu_localizations.length
                              && item.menu_localizations[0].title}
                            </Link>
                          </div>
                  }

                  {(subNav && !!children.length && (num === i)) && (
                    <div className="mob-sub-nav-itm-block">
                      {children.map(sub => (
                        <div
                          key={`nav-sub-item-${sub.id}`}
                          className="mob-sub-nav-itm"
                          onClick={this.toggle}
                          role="presentation"
                        >
                          <Link to={withParent(menu, sub)}>
                            {sub.menu_localizations.length
                              && sub.menu_localizations[0].title}
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="mobile-nav-bottom">
              {header.telegram_bots && header.telegram_bots.length && (
                <div
                  className={telegramMenu ? 'telegram-wrapper opened' : 'telegram-wrapper'}
                  // onTouchEnd={this.toggleTelegramMenu}
                  onClick={this.toggleTelegramMenu}
                >
                  <div className="telegram-button">
                    <i className="fab fa-telegram-plane" />
                  </div>
                  {telegramMenu && (
                    <ul className="telegram-list">
                      {header.telegram_bots.map(bot => (
                        <li
                          key={`telegram-bot-item${bot.id}`}
                          role="presentation"
                        >
                          <a href={
                            bot.telegram_bot_localizations[0]
                              && bot.telegram_bot_localizations[0].link
                          }
                          >
                            {bot.telegram_bot_localizations[0]
                              && bot.telegram_bot_localizations[0].name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
              <Social socials={header} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

MobileHeader.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
  localization: PropTypes.objectOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapDispatchToProps = dispatch => ({
  changeLanguage: (lan) => {
    localStorage.setItem('language', lan);
    dispatch(switchLanguage(lan));
  },
  initGlobalSearch: search => dispatch(initSearch(search)),
});

export default withRouter(connect(null, mapDispatchToProps)(MobileHeader));
