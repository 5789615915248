/* eslint-disable react/no-array-index-key,camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import '../assets/styles/news.scss';

import sortNumUp from '../assets/svg/sotr-9-1.svg';
import sortNumDown from '../assets/svg/sort-1-9.svg';
import sortAZ from '../assets/svg/sort-a-z.svg';
import sortZA from '../assets/svg/sort-z-a.svg';

import { CORE_URL } from '../constants/urls';

import {
  clearDate, clearDate2, clearPageDescription, getTranslation,
} from '../services/main';
import fetchOne from '../services/fetch';

import Breadcrumbs from '../components/util/Breadcrumbs';
import Filters from '../components/FiltersNews';
import ContentBlock from '../components/ContentBlock';
import AccordionFilters from '../components/util/AccordionFilters';
import BigNewsBlock from '../components/news/BigNewsBlock';
import Page from '../modules/views/Page';

import Pagination from '../components/news/Pagination';

class News extends React.Component {
  state = {

    collection: [],
    pagination: {},

    search: '',

    sortParams: {
      sortBy: 'publishedAt',
      orderBy: 'desc',
    },

    filters: [],
    filterParams: '',
    time: '',
    dateFilter: '&is_published=true',

    loading: true,
    error: false,

    // состояния для плагина сортировки
    activeSort: {
      type: 'publishedAt',
      order: 'desc',
      image: sortNumUp,
      label: 'home-sort-date-publication',
    },
    sort: [
      {
        type: 'publishedAt',
        order: 'asc',
        image: sortNumDown,
        label: 'home-sort-date-publication',
      },
      {
        type: 'title',
        order: 'asc',
        image: sortAZ,
        label: 'home-sort-name',
      },
      {
        type: 'title',
        order: 'desc',
        image: sortZA,
        label: 'home-sort-name',
      },
    ],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getContent();
  }

  getContent = async (page = 1) => {
    window.scrollTo(0, 0);
    try {
      const {
        filterParams, sortParams, search, dateFilter,
      } = this.state;
      // параметры запроса
      const newsPerPage = 8;
      const activeParams = 'isActive=true';

      const pageParams = `&page=${page}&limit=${newsPerPage}`;
      const sort = `&sortBy=${sortParams.sortBy}&orderBy=${sortParams.orderBy}`;
      const searchParams = search.trim() ? `&q=${search}` : '';
      const filter = `${filterParams}`;

      // строка запроса
      const query = `${activeParams}${pageParams}${sort}${searchParams}${filter}${dateFilter}`;

      // запрос
      const { collection, pagination } = await fetchOne({
        url: `${CORE_URL}/news?${query}`,
      });

      this.setState({
        collection,
        pagination,
        loading: false,
      });
    } catch (err) {
      this.setState({ error: true });
    }
  };

  filtersAccept = () => {
    const { filters, time } = this.state;
    const { from, to } = this.getDateLimits(time);
    this.setState({
      filterParams: filters.map(link => `&tags[]=${link.id}`).join(''),
      dateFilter: time ? `&published_start=${from}&published_finish=${to}` : '&is_published=true',
    }, this.getContent);
  };

  clearTime = (date) => {
    const [fr, to] = date.split('/');
    return `${clearDate(fr)} - ${clearDate(to)}`;
  };

  getDateLimits = (date) => {
    let [from, to] = date.split('/');

    // fix если выбран один день добавляет +1 день к выбраной дате
    if (clearDate(from) === clearDate(to)) {
      to = new Date(to).setDate(new Date(to).getDate() + 1);
    }
    return { from: clearDate2(from), to: clearDate2(to) };
  };

  setFilter = (item) => {
    const { filters } = this.state;
    if (!filters.some(filter => filter.id === item.id)) {
      this.setState({
        filters: [...filters, item],
      });
    } else {
      this.removeFilter(item);
    }
  };

  removeFilter = (item) => {
    const { filters } = this.state;
    if (filters.some(filter => filter.id === item.id)) {
      this.setState({
        filters: [...filters.filter(el => el.id !== item.id)],
      }, this.filtersAccept);
    } else {
      this.setState({ time: '', dateFilter: '&is_published=true' }, this.filtersAccept);
    }
  };

  // обработчик для сортировки
  changeSort = (item) => {
    const { sort, activeSort } = this.state;
    const changed = sort.filter(el => el.type !== item.type || el.order !== item.order);
    const sortParams = {
      sortBy: item.type,
      orderBy: item.order,
    };
    this.setState({
      sort: [activeSort, ...changed],
      activeSort: item,
      sortParams,
    }, this.getContent);
  };

  // обработчик поиска
  searchInput = ({ target }) => this.setState({ search: target.value });

  setTime = time => this.setState(state => ({ time: state.time !== time ? time : '' }));

  removeAllFilters = () => this.setState({ filters: [], time: '' }, this.filtersAccept);

  onKeyDownHandler = ({ keyCode }) => (keyCode === 13) && this.getContent();

  render() {
    const {
      error, loading, time,
      sort, activeSort, collection, filters, pagination,
    } = this.state;
    const { translations } = this.props;
    const filterValues = time ? [this.clearTime(time), ...filters] : filters;

    return (
      <Page
        error={error}
        loading={loading}
        title={clearPageDescription(getTranslation('news-page', translations))}
        languageChange={this.getContent}
      >
        <div className="container news-page">
          <div className="row no-gutter">
            <div className="col-xl-12 breadcrumb-wrapper">
              <Breadcrumbs />
            </div>
          </div>
          <div className="row no-gutter">
            <div className="col-xl-12 p-0">
              <p className="page-title">
                {clearPageDescription(getTranslation('news-page', translations))}
              </p>
            </div>
          </div>
          <ContentBlock>
            <div className="row no-gutter">
              <div className="w-100 p-0">

                {/* Поиск */}
                <AccordionFilters
                  header={(
                    <div className="search-block">
                      <input
                        className="search-input"
                        type="search"
                        onChange={this.searchInput}
                        onKeyDown={this.onKeyDownHandler}
                      />
                      <button
                        className="search-submit"
                        type="submit"
                        onClick={this.getContent.bind(this, 1)}
                      />
                    </div>
                  )}
                  button={(<p>{clearPageDescription(getTranslation('filters', translations))}</p>)}
                  body={(
                    <Filters
                      filters={filters}
                      time={time}
                      setFilter={this.setFilter}
                      setTime={this.setTime}
                      translations={translations}
                    />
                  )}
                  close={this.filtersAccept}
                />
                <div className="selected-filters_wrapper">
                  {!!filterValues.length && (
                    <ul className="selected-filters">
                      {filterValues.map((filter, index) => (
                        <li
                          key={`filter-item-${index}`}
                          className="selected-filters_item"
                          onClick={() => this.removeFilter(filter)}
                          role="presentation"
                        >
                          <div className="selected-filter-text">
                            {filter.title || filter}
                          </div>
                          <div className="filter-cross" />
                        </li>
                      ))}
                      <li
                        className="filter-cross_big"
                        onClick={this.removeAllFilters}
                        role="presentation"
                      />
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="row no-gutter">
              <div className="w-100 p-0">
                <div className="pagination-wrapper">

                  {/* Верхняя сортировка по дате и названию */}
                  { !!collection.length && (
                  <AccordionFilters
                    button={(
                      <p className="sort-text_wr">
                        <img
                          className="sort-image"
                          src={activeSort.image}
                          alt=""
                        />
                        {clearPageDescription(getTranslation(activeSort.label, translations))}
                      </p>
                    )}
                    body={(
                      <ul className="sorting-list">
                        {!!sort.length && sort.map(item => (
                          <li
                            key={`sort-item-${item.order}-${item.type}`}
                            className="sort-text_wr"
                            onClick={() => this.changeSort(item)}
                            role="presentation"
                          >
                            <img
                              className="sort-image"
                              src={item.image}
                              alt=""
                            />
                            <p>{clearPageDescription(getTranslation(item.label, translations))}</p>
                          </li>
                        ))}
                      </ul>
                    )}
                  />
                  ) }
                  {/* Конец - Верхняя сортировка по дате и названию */}

                  { !!collection.length && <Pagination handler={this.getContent} pagination={pagination} /> }

                </div>
              </div>
            </div>
            <div className="row no-gutter">
              <div className="col-xl-12 news-wrapper p-0">
                {collection.length
                  ? collection.map(item => (
                    <div
                      key={`news-item-${item.id}`}
                      className="col-12 col-md-6 news-page-item"
                    >
                      <div className="news-double-item">
                        <BigNewsBlock parent="news" page={item} />
                      </div>
                    </div>
                  )) : (
                    <p className="empty-text">
                      {clearPageDescription(getTranslation('not-found', translations))}
                    </p>
                  )}
              </div>
            </div>
            <div className="row no-gutter">
              <div className="w-100 p-0">
                <div className="pagination-wrapper">

                  {/* Нижняя сортировка по дате и названию */}
                  { !!collection.length && (
                  <AccordionFilters
                    button={(
                      <p className="sort-text_wr">
                        <img
                          className="sort-image"
                          src={activeSort.image}
                          alt=""
                        />
                        {clearPageDescription(getTranslation(activeSort.label, translations))}
                      </p>
                      )}
                    body={(
                      <ul className="sorting-list">
                        {!!sort.length && sort.map(item => (
                          <li
                            key={`sort-item-${item.order}-${item.type}`}
                            className="sort-text_wr"
                            onClick={() => this.changeSort(item)}
                            role="presentation"
                          >
                            <img
                              className="sort-image"
                              src={item.image}
                              alt=""
                            />
                            <p>{clearPageDescription(getTranslation(item.label, translations))}</p>
                          </li>
                        ))}
                      </ul>
                      )}
                  />
                  ) }
                  {/* Конец - Нижняя сортировка по дате и названию */}

                  { !!collection.length && <Pagination handler={this.getContent} pagination={pagination} /> }

                </div>
              </div>
            </div>
          </ContentBlock>
        </div>
      </Page>
    );
  }
}

News.propTypes = {
  translations: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default News;
