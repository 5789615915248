import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../components/util/Breadcrumbs';

import '../assets/styles/pages/notFound.scss';
import StateAES from '../assets/images/aes-state.png';
import { clearPageDescription, getTranslation } from '../services/main'


class NotFound extends React.Component {
  render() {
    const { localization, translations } = this.props;
    return (
      <div className="container">
        <Helmet>
          <title>{clearPageDescription(getTranslation('page-not-found', translations))}</title>
        </Helmet>
        <div className="row no-gutter">
          <div className="col-xl-12 breadcrumb-wrapper">
            <Breadcrumbs />
          </div>
        </div>
        <div className="container">
          <div className="error-page_wrapper">
            {localization ? (
              <div className="error-text-wrapper localization-error">
                <div className="error-page_text">
                  {clearPageDescription(getTranslation('translate-not-found', translations))}
                </div>
                <div className="error-page_link">
                  <Link to="/">
                    {clearPageDescription(getTranslation('goto-main', translations))}
                  </Link>
                </div>
                {/*<div className="error-page_additional">*/}
                {/*  {clearPageDescription(getTranslation('translate-not-found', translations))}*/}
                {/*</div>*/}
              </div>
            ) : (
              <div className="error-text-wrapper">
                <h2 className="error-page_title">404</h2>
                <div className="error-page_text">
                  {clearPageDescription(getTranslation('page-not-found', translations))}
                </div>
                <div className="error-page_link">
                  <a href="/">
                    {clearPageDescription(getTranslation('goto-main', translations))}
                  </a>
                </div>
              </div>
            )}
            <div className="error-icon-wrapper">
              <img
                src={StateAES}
                className="error-page_icon"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translations,
});

export default connect(mapStateToProps)(NotFound);
