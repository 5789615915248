/* eslint-disable react/prop-types */
import React from 'react';
import {
  Switch, Route, Redirect, withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';

import { withParent } from '../../services/main';
import ErrorWrapper from '../../modules/views/ErrorWrapper';

import Home from '../Home';
import News from '../News';
import NewsItem from '../NewsItem';
import PageItem from '../PageItem';
import NotFound from '../NotFound';
import About from '../About';
import Contacts from '../Contact';
import Editions from '../Editions';
import BookView from '../BookView';
import Search from '../Search';
import StateAES from '../StateAES';
import Incident from '../Incident';
import IncidentItem from '../IncidentItem';
import Affiliate from '../Affiliate';

class Main extends React.PureComponent {
  render() {
    const {
      match, routes,
      settings, translations, initiated,
    } = this.props;
    if (!initiated) return null;
    return (
      <main className="App-main">
        <ErrorWrapper>
          <Switch>
            <Route exact path={`/${settings.home_url}`} component={Home} />

            <Route
              exact
              path="/news"
              render={props => (
                <News
                  {...props}
                  match={match}
                  translations={translations}
                />
              )}
            />

            <Route exact path={`/${settings.about_us_url}`} component={About} />

            <Route exact path="/search" component={Search} />

            <Route exact path={`/${settings.npp_url}`} component={StateAES} />
            <Route exact path={`/${settings.npp_url}/:page`} component={Incident} />
            <Route exact path={`/${settings.npp_url}/:page/:item`} component={IncidentItem} />

            <Route exact path={`/${settings.contact_url}`} component={Contacts} />

            <Route exact path={`/${settings.book_url}`} component={Editions} />
            <Route exact path={`/${settings.book_url}/:alias`} component={BookView} />
            <Route
              exact
              path="/url-category-test/:affiliate"
              component={Affiliate}
            />

            <Route exact path="/news/:alias" component={NewsItem} />

            {routes.map(route => (
              <Route
                key={route.id}
                exact
                path={withParent(routes, route)}
                component={PageItem}
              />
            ))}

            <Route exact path="/:alias" component={PageItem} />

            <Route exact path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </ErrorWrapper>
      </main>
    );
  }
}

const mapStateToProps = ({ general, translations }) => ({
  initiated: general.initiated,
  settings: general.settings,
  routes: general.routes,
  translations,
});

export default withRouter(connect(mapStateToProps)(Main));
