import React, { Component } from 'react';
import { connect } from 'react-redux';

import { clearPageDescription, getTranslation } from '../../services/main';
import { allFalse, validateEmail } from '../../actions/common';
import fetchOne from '../../services/fetch';
import { CORE_URL } from '../../constants/urls';

class FeedbackForm extends Component {
    state = {
      username: '',
      email: '',
      department: '',
      message: '',
      departmentTitle: '',

      departmentMenu: false,

      fields: {
        username: true,
        email: true,
        department: true,
        message: true,
      },

      errors: {},
      feedbacks: [],
    };

    componentDidMount() {
      this.getFeedbacks();
    }

    componentDidUpdate(oldProps) {
      const { language } = this.props;
      if (oldProps.language !== language) {
        this.setState({
          username: '',
          email: '',
          department: '',
          message: '',
          departmentTitle: '',
        });
        this.getFeedbacks();
      }
    }

  getFeedbacks = async () => {
      const feedbacks = await fetchOne({ url: `${CORE_URL}/feedbacks` });
      const filteredFeedbacks = feedbacks.filter(item => !!item.feedback_localizations.length);
      this.setState({
        feedbacks: filteredFeedbacks,
      });
    };

    onChange = ({ target }) => {
      const { fields, errors } = this.state;
      if (target.value.trim() !== '') {
        if (target.name === 'email') {
          if (validateEmail(target.value)) {
            this.setState({
              fields: { ...fields, [target.name]: false },
              errors: { ...errors, [target.name]: false },
            });
          } else {
            this.setState({ fields: { ...fields, [target.name]: true } });
          }
        } else {
          this.setState({
            fields: { ...fields, [target.name]: false },
            errors: { ...errors, [target.name]: false },
          });
        }
      }
      this.setState({ [target.name]: target.value });
    };

    onSubmit = async (ev) => {
      ev.preventDefault();
      const {
        fields, username,
        email, department, message,
      } = this.state;
      if (allFalse(fields)) {
        const feedbackData = {
          name: username,
          email,
          feedback_localization_id: department,
          message,
        };
        const sent = await fetchOne({
          url: `${CORE_URL}/feedbacks/messages/send`,
          method: 'POST',
          body: JSON.stringify(feedbackData),
        });
        if (sent.ok) {
          this.setState({
            username: '',
            email: '',
            department: '',
            message: '',
            departmentTitle: '',

            departmentMenu: false,

            fields: {
              username: true,
              email: true,
              department: true,
              message: true,
            },

            errors: {},
          });
        }
      } else {
        this.setState({ errors: fields });
      }
    };

    selectDepartment = (item) => {
      const { localizationId } = this.props;
      const { fields, errors } = this.state;
      this.setState({
        // department: item.feedback_localizations[0].id,
        department: item.feedback_localizations[0].id,
        // departmentTitle: item.department.department_localizations[0].title,
        departmentTitle: item.department.department_localizations.find(el => el.localization.id === localizationId).title,
        departmentMenu: false,
        fields: {
          ...fields,
          department: false,
        },
        errors: {
          ...errors,
          department: false,
        },
      });
    };

    toggleDepartmentMenu = () => this.setState(state => ({ departmentMenu: !state.departmentMenu }));

    parse = arr => [].concat(...arr.map(item => (item.children.length
      ? [].concat(...[item, [].concat(...this.parse(item.children))]) : item)));

    render() {
      const { translations, localizationId } = this.props;
      const {
        departmentMenu, errors, feedbacks, departmentTitle, username, email, message,
      } = this.state;

      return (
        <div className="contact-block left" style={{ maxHeight: '645px' }}>
          <div className="contact-form-header">
            {clearPageDescription(getTranslation('callback', translations))}
          </div>
          <span>
            {clearPageDescription(getTranslation('fio', translations))}
                    *
          </span>
          <input
            type="text"
            className={!errors.username ? 'feedback-input' : 'feedback-input-error'}
            name="username"
            onChange={this.onChange}
            value={username}
          />
          <div className="row">
            <div className="col-xl-6">
              <span>
                {clearPageDescription(getTranslation('email', translations))}
                            *
              </span>
              <input
                type="email"
                className={!errors.email ? 'feedback-input' : 'feedback-input-error'}
                onChange={this.onChange}
                name="email"
                value={email}
              />
            </div>
            <div className="col-xl-6">
              <div className="department-block">
                <span>
                  {clearPageDescription(getTranslation('department', translations))}
                              *
                </span>
                <input
                  type="text"
                  className={!errors.department ? 'feedback-input' : 'feedback-input-error'}
                  list="data"
                  onChange={this.onChange}
                  onClick={() => this.toggleDepartmentMenu()}
                  name="department"
                  value={departmentTitle}
                />
                {departmentMenu && (
                <ul className="depart-wrapper">
                  {feedbacks.length && feedbacks
                  // .filter(item => item.department.department_localizations[0])
                  .sort((a, b) => a.priority - b.priority)
                  .map((item, i) => (
                    <li
                      key={`departments-item-${i}`}
                      onClick={() => this.selectDepartment(item)}
                      role="presentation"
                    >
                      {!!item.department.department_localizations.find(el => el.localization.id === localizationId) &&
                      item.department.department_localizations.find(el => el.localization.id === localizationId).title}
                    </li>
                  ))}
                </ul>
                )}
              </div>
            </div>
          </div>
          <span>{clearPageDescription(getTranslation('message', translations))}</span>
          <textarea
            className={!errors.message ? 'feedback-textarea' : 'feedback-textarea-error'}
            onChange={this.onChange}
            name="message"
            value={message}
          />
          {
                    /**
                     *        <div className="captcha-block">
                     <div className="row">
                     <div className="col-xl-4 offset-2">
                     <span>CAPTCH</span>
                     </div>
                     </div>
                     <div className="row">
                     <div className="col-xl-4 offset-2 col-md-offset-4">
                     <input type="text" className="captcha-input" />
                     </div>
                     <div className="col-xl-4">
                     <span />
                     <input type="text" className="captcha-input" />
                     </div>
                     </div>
                     </div>
                     */
          }
          <div className="sub-but">
            <button
              className="button-contact-submit"
              type="button"
              onClick={this.onSubmit}
            >
              {clearPageDescription(getTranslation('send', translations))}
            </button>
          </div>
        </div>
      );
    }
}

const mapStateToProps = state => ({
  language: state.general.localization.language.locale,
  localizationId: state.general.localization.language.id,
});

export default connect(mapStateToProps)(FeedbackForm);
