import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SideNewsBlock from './SideNewsBlock';

const SideNews = ({ advertising }) => {
  const validInfo = advertising
    .filter(el => el.is_active)
    .sort((a, b) => a.priority - b.priority)
    .filter(el => el.advertising_localizations[0]);
  const maxInfo = validInfo.length >= 6 ? validInfo.slice(0, 6) : validInfo;
  return (
    <div className="side-news-wrapper">
      {maxInfo
        .map(({ id, advertising_localizations }) => (
          <SideNewsBlock
            key={`ad-item-${id}`}
            item={advertising_localizations[0]}
          />
        ))}
    </div>
  );
};

SideNews.propTypes = {
  advertising: PropTypes.arrayOf(PropTypes.any).isRequired
};

const mapStateToProps = ({ general }) => ({
  advertising: general.content.advertising
});

export default connect(mapStateToProps)(SideNews);
