import React from 'react';
import Location from '../../assets/svg/location.svg';
import Phone2 from '../../assets/svg/phone2.svg';
import Phone from '../../assets/svg/phone.svg';
import Email from '../../assets/svg/email.svg';

const iconSrc = (type) => {
  switch (type) {
    case 'phone':
      return Phone2;
    case 'email':
      return Email;
    case 'fax':
      return Phone;
    default:
      return Phone2;
  }
};

const ContactBlock = ({ department: { id, photo, department_localizations: locale }, children, toggleFormMap, titleButton }) => (
  <div className="contact-orgstr-addrr-block">
    {/*  Заголовок  */}
    {/*<div className="orgstr-addrr-header">*/}
    {/*  <Link to="/url-category-test/kharkiv">*/}
    {/*    <span>{locale[0].title}</span>*/}
    {/*  </Link>*/}
    {/*</div>*/}
    <div className="orgstr-addrr-addrr-photo">
      <div className="orgstr-icon-photo">
        <img
          src={photo && (photo.resize || photo.href)}
          alt=""
          className="orgstr-addrr-office-img orgstr-right-indent"
        />
      </div>
      <div style={{
        paddingLeft: '30px', background: `url(${Location}) no-repeat `, backgroundPosition: 'top 5px left 0px',
      }}
      >
        {locale[0].first_address && (
          <div className="orgstr-addrr-point">
            <div
              className="office-address"
              role="presentation"
            >
              {locale[0].first_address}
            </div>
          </div>
        )}
        {locale[0].second_address && (
          <div className="orgstr-addrr-point">
            <div
              className="office-address"
              role="presentation"
            >
              {locale[0].second_address}
            </div>
          </div>
        )}
          <div className="orgstr-addrr-point">
              <button
                  className="office-address-button"
                  onClick={toggleFormMap.bind(this, id)}
              >
                  {titleButton}
              </button>
          </div>
      </div>
    </div>

    <div className="orgstr-addrr-contacts" style={{marginBottom: '15px'}}>
      {!!locale[0].contact && locale[0].contact.contact_entries.map(el => (
        <div
          key={`address-item-${el.id}`}
          className="orgstr-addrr-point-left orgstr-right-indent"
        >
          <div className="orgstr-addrr-point">
            <div className="mini-img orgstr-right-indent">
              <img src={iconSrc(el.type)} alt="" />
            </div>
            <div className="tel-numbers orgstr-right-indent">
              {el.value}
            </div>
          </div>
        </div>

      ))}
    </div>

      {!!children.length && <div className="press-center">
      {children && children
          .sort((a, b) => a.priority - b.priority)
          .map(el => (
        <div className="press-contact" key={`press-${el.id}`}>
          <div className="press-center-header">
            {el.department.department_localizations[0].title}
          </div>

          <div className="orgstr-addrr-contacts">
            {el.department.department_localizations[0].contact.contact_entries
          && el.department.department_localizations[0].contact.contact_entries.map(contact => (
            <div
              key={`address-item-${contact.id}`}
              className="orgstr-addrr-point-left orgstr-right-indent"
            >
              <div className="orgstr-addrr-point">
                <div className="mini-img orgstr-right-indent">
                  <img src={iconSrc(contact.type)} alt="" />
                </div>
                <div className="tel-numbers orgstr-right-indent">
                  {contact.value}
                </div>
              </div>
              <hr className="split-press" />
            </div>
          ))}
          </div>
        </div>
      ))}
    </div>}

  </div>
);

export default ContactBlock;
