/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import '../assets/styles/pages/AES.scss';

import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import ReactHtmlParser from 'react-html-parser';
import ContentBlock from '../components/ContentBlock';
import Page from '../modules/views/Page';
import Breadcrumbs from '../components/util/Breadcrumbs';
import fetchOne from '../services/fetch';
import { CORE_URL } from '../constants/urls';
import ChartCard from '../components/ChartCard';
import {
  addLoadPlaceholders, addMedia, clearPageDescription, getDescriptionWidgets, getTranslation,
} from '../services/main'
import Gallery from '../components/Gallery';
import FilesGallery from '../components/FilesGallery';
import IframeContainer from '../components/util/IframeContainer';
import Iblocks from '../components/Iblocks'

const parseContent = (key, translations) => (
  getDescriptionWidgets(addLoadPlaceholders(getTranslation(key, translations))).map(
    (item, i) => {
      if (item.type === 'text') {
        return ReactHtmlParser(item.text);
      }
      if (item.type === 'media') {
        return (
          <Gallery
            key={i}
            media={item.media}
            title={item.title}
          />
        );
      }
      if (item.type === 'files') {
        return (
          <FilesGallery
            key={i}
            media={item.media}
            title={item.title}
          />
        );
      }
      if (item.type === 'iblocks') {
        return (
          <Iblocks
            key={i}
            media={item.media}
            title={item.title}
          />
        );
      }
      if (item.type === 'iframe') {
        return (
          <IframeContainer
            key={`iframe-${i}`}
            iframe={item.media[0].iframe}
            itext={item.media[0].itext}
          />
        );
      }
    },
  )
);

async function createCharts(charts) {
  const chartsAES = ['khmelnytskyi_npp', 'rivne_npp', 'south_ukraine_npp', 'zaporizhia_npp'];

  charts = chartsAES.map((aes) => {
    const dates = charts.map((item) => {
      if (item.hasOwnProperty(aes)) {
        const radiation = item[aes].radiation[0].replace(/,/g, '.');
        const load = item[aes].load;
        const blocks = [];

        let data = {
          date: item.date, radiation, blocks,
        };

        Object.keys(load).map((i) => {
          if (!i.includes('_')) {
            blocks.push(i);
            data = { ...data, [i]: load[i] };
          }
        });

        return data;
      }
    });
    return ({ name: aes, data: dates });
  });

  return (charts);
}

function ComponentAES({ translations }) {
  const [screen, setScreen] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState(null);

  const { gallery, files, charts } = data || { gallery: '', files: '', charts: [] };

  function measure() {
    const size = window.innerWidth <= 465;
    setScreen(size);
  }

  async function getData(days) {
    setLoading(true);
    const now = new Date();

    const prev = new Date(now.getTime() - (days * 24 * 60 * 60 * 1000));

    const today_formatted = `${now.getFullYear()}-${(`0${now.getMonth() + 1}`).slice(-2)}-${(`0${now.getDate()}`).slice(-2)}`;
    const prev_formatted = `${prev.getFullYear()}-${(`0${prev.getMonth() + 1}`).slice(-2)}-${(`0${prev.getDate()}`).slice(-2)}`;

    const links = [`/nuclear-power-plants?from=${prev_formatted}&to=${today_formatted}`, '/pages/alias/	ines', '/pages/alias/violations'];
    const [chartsAll, galleryRes, filesRes] = await Promise
      .all(links.map(link => fetchOne({ url: `${CORE_URL}${link}` })));
    const charts = await createCharts(chartsAll);

    const res = {
      gallery: galleryRes.page_localizations.length ? galleryRes.page_localizations[0].description : '',
      files: filesRes.page_localizations.length ? filesRes.page_localizations[0].description : '',
      charts,
    };

    setData(res);
    setPeriod(days);
    setLoading(false);
  }

  useEffect(() => {
    (async () => {
      measure();
      window.addEventListener('resize', measure);
      getData(31).then();
      return () => window.removeEventListener('resize', measure);
    })();
  }, []);

  return (
    <Page
      error={error}
      loading={loading}
      title={clearPageDescription(getTranslation('aes-title', translations))}
      languageChange={() => getData(30)}
    >
      <div className="container state-page">
        <div className="row no-gutter">
          <div className="col-xl-12 breadcrumb-wrapper">
            <Breadcrumbs string={clearPageDescription(getTranslation('aes-title', translations))} />
          </div>
        </div>
        <h3 className="page-title">
          {clearPageDescription(getTranslation('aes-title', translations))}
        </h3>
        <ContentBlock>
          <div className="page-wrapper">
            <div className="list-wrapper section-wrapper">
              <div className="list-title">
                {clearPageDescription(getTranslation('aes-ines', translations))}
              </div>
              {!!gallery
                && (
                <div>
                  {ReactHtmlParser(addLoadPlaceholders(gallery))}
                </div>
                )
              }
            </div>

            <div className="section-wrapper">
              <div className="section-title">
                {clearPageDescription(getTranslation('aes-title-regulation', translations))}
              </div>
              <div className="section-subtitle">
                  {parseContent('aes-regulation', translations)}
              </div>
            </div>

            <div className="list-wrapper section-wrapper">
              <div className="list-title">
                {clearPageDescription(getTranslation('aes-violation', translations))}
              </div>
              {!!files && getDescriptionWidgets(addLoadPlaceholders(addMedia(files))).map((item, i) => {
                if('text' === item.type){
                  return ReactHtmlParser(item.text);
                } else if('media' === item.type){
                  return (<Gallery key={i} media={item.media} title={item.title} />);
                } else if ('iframe' === item.type) {
                  return (<IframeContainer iframe={item.media[0].iframe} />);
                } else if (item.type === 'iblocks') {
                  return (<Iblocks key={i} media={item.media} title={item.title} />);
                }
              })}
            </div>

            <div className="section-wrapper">
              <div
                className="section-title"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <span style={{ fontSize: '22px' }}>
                  {clearPageDescription(getTranslation('aes-status', translations))}
                </span>

                <div className="charts-period-wrapper">
                  {clearPageDescription(getTranslation('data_text', translations))}
                  <ul>
                    <li
                      className={period === 7 ? 'active' : ''}
                      onClick={() => getData(7)}
                    >
                      {clearPageDescription(getTranslation('data_week', translations))}
                    </li>
                    <li
                      className={period === 31 ? 'active' : ''}
                      onClick={() => getData(31)}
                    >
                      {clearPageDescription(getTranslation('data_month', translations))}
                    </li>
                    <li
                      className={period === 365 ? 'active' : ''}
                      onClick={() => getData(365)}
                    >
                      {clearPageDescription(getTranslation('data_year', translations))}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="charts-wrapper">
                {charts.length && charts.map((item, index) => (
                  <ChartCard
                    data={item}
                    key={`char-${index}`}
                    screen={screen}
                  />
                ))}
              </div>
            </div>
          </div>
        </ContentBlock>
      </div>
    </Page>
  );
}

ComponentAES.propTypes = {
  translations: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  i18n: state.i18n,
  translations: state.translations,
});

export default connect(mapStateToProps)(ComponentAES);
