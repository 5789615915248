import React from 'react'
import Voting from '../util/Voting'
import TagSVG from '../util/TagSVG'

const BooksBottom = ({ score, tags, reviews }) => {
	// console.log(score, tags, reviews);
	return (<div className="books-bottom-block">
						<div className="books-rate-tags-block">
							{!!score && <div className="xxx">
								<Voting score={score}/>
								&nbsp;&nbsp;
								<span>{reviews}</span>
							</div>
							}
						</div>
						<div className="book-share-wrapper">
							{tags.length ? <TagSVG num={tags.length}/> : ''}
						</div>
					</div>
	)
};

export default BooksBottom;
