import React from 'react';
import Close from '../../assets/svg/close.svg';
import {validateEmail, validatePhone} from "../../actions/common";
import {POST_BUY_BOOK} from "../../constants/urls";
import fetchOne from "../../services/fetch";
import InputElement from 'react-input-mask';

class BuyBook extends React.Component {

  state = {
    book: this.props.book,
    email: '',
    comment: '',
    phone: '',
    name: '',
    surname: '',
    middle_name: '',
    delivery_type: {},
    delivery_index: '',
    submitted: false,
    emailError: false,
    phoneError: false,
    showDeliveryType: false
  };

  onChange = name => event => {
    const {target} = event;
    this.setState({
      [name]: target.value,
      showDeliveryType: false
    });

    if (name === 'email') {
      this.setState({emailError: false});
    }
    if (name === 'phone') {
      this.setState({phoneError: false});
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({submitted: true});

    const {
      book, email, delivery_type, middle_name,
      phone, name, surname, emailError, phoneError
    } = this.state;

    if (!validateEmail(email)) {
      this.setState({emailError: true});
    }

    if (phone && !validatePhone(phone)) {
      this.setState({phoneError: true});
    }

    if (!emailError && !phoneError && book && email && phone && delivery_type.id && middle_name && name && surname) {
      this.buyBook();
    }
  };

  buyBook = async () => {
    try {
      const {
        book, email, delivery_index, delivery_type, middle_name, comment,
        phone, name, surname,
      } = this.state;

      let method = 'POST';
      let url = `${POST_BUY_BOOK}`;

      const pageBody = JSON.stringify({
        book, email, delivery_index, delivery_type: +delivery_type.id, middle_name, comment,
        phone, name, surname
      });

      const createOrder = await fetchOne({url, method, body: pageBody});

      if (createOrder.status === 201 || createOrder.status === 204) {
        this.setState({submitted: true});
        const order = await createOrder.json();
        console.log(order);
        this.props.handleClose(order.id);
      } else {
        const order = await createOrder.json();
        console.log(order);
        if (order.error === 'The phone is not a valid mobile number') {
          this.setState({submitted: true, phoneError: true});
        }
      }
    } catch (err) {
      this.setState({submitted: true});
    }
  };

  toggleDeliveryType = () => {
    this.setState(prevState => ({
      showDeliveryType: !prevState.showDeliveryType
    }));
  };

  selectDeliveryType = (item) => {
    this.setState({
      delivery_type: item,
    });
  };

  render() {
    const {
      email, delivery_index, delivery_type, middle_name, comment,
      phone, name, surname, showDeliveryType, emailError, phoneError, submitted
    } = this.state;
    const {handleClose, show} = this.props;
    return (
      <div className={show ? "modal display-block" : "modal display-none"}>
        <div className="modal-background"
             onClick={handleClose}></div>
        <section className="modal-book-content">
          <div className="modal-header">
            <h2>Оформлення покупки книги</h2>
            <button className='close-btn' onClick={handleClose}>
              <img src={Close} alt=""/>
            </button>
          </div>

          <form action="">
            <div className="form-group">
              <label>
                <span>Ім'я*</span>
                <input type="text"
                       value={name}
                       className={submitted && !name ? 'error' : ''}
                       onChange={this.onChange('name')}
                       required
                />
              </label>

              <label>
                <span>Прізвище*</span>
                <input type="text"
                       value={surname}
                       className={submitted && !surname ? 'error' : ''}
                       onChange={this.onChange('surname')}
                       required
                />
              </label>
            </div>

            <div className="form-group">
              <label>
                <span>По батькові*</span>
                <input type="text"
                       value={middle_name}
                       className={submitted && !middle_name ? 'error' : ''}
                       onChange={this.onChange('middle_name')}
                       required
                />
              </label>
            </div>

            <div className="form-group">
              <label>
                <span>E-mail*</span>
                <input type="text"
                       value={email}
                       className={submitted && (!email || emailError) ? 'error' : ''}
                       onChange={this.onChange('email')}
                       required
                />
              </label>

              <label>
                <span>Номер телефону*</span>
                <InputElement type="text"
                       mask="+380999999999"
                       value={phone}
                       className={submitted && (!phone || phoneError) ? 'error' : ''}
                       // className={submitted && (!phone || phoneError) ? 'error' : ''}
                       onChange={this.onChange('phone')}
                       required
                />
              </label>
            </div>

            <div className="form-group">
              <label>
                <span>Тип доставки*</span>
                <input
                  type="text"
                  className={submitted && !delivery_type.id ? 'error' : ''}
                  list="data"
                  onChange={this.onChange('delivery_type')}
                  onClick={() => this.toggleDeliveryType()}
                  value={delivery_type.title}
                />

                {!!showDeliveryType &&
                  <ul className="book-delivery-types">
                    <li onClick={() => this.selectDeliveryType({id: 1, title: 'Нова пошта'})}
                        role="presentation">
                      Нова пошта
                    </li>
                    <li onClick={() => this.selectDeliveryType({id: 2, title: 'Укрпошта'})}
                        role="presentation">
                      Укрпошта
                    </li>
                    <li onClick={() => this.selectDeliveryType({id: 3, title: 'Самовивіз'})}
                        role="presentation">
                      Самовивіз
                    </li>
                  </ul>
                }
              </label>

              <label>
                <span>Індекс / Номер відділення</span>
                <input type="text"
                       value={delivery_index}
                       onChange={this.onChange('delivery_index')}
                />
              </label>
            </div>

            <div className="form-group-textarea">
              <label className="textarea-label">
                <span>Коментар</span>
                <textarea value={comment}
                          className='feedback-textarea'
                          onChange={this.onChange('comment')}> </textarea>
              </label>
            </div>

            <button className="book-buy-button"
                    onClick={(e) => this.submitForm(e)}>
              <span>Придбати</span>
            </button>
          </form>

        </section>
      </div>
    );
  };
}

export default BuyBook;
