import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

const Logo = ({ header, language }) => {
  return (
    <div className="logo-container">
      {header ? (
        <Link to="/">
          <img className="" src={language.header_logo} alt="" />
        </Link>
      ) : (
        <img className="" src={language.footer_logo} alt="" />
      )}
    </div>
  )
};

const mapStateToProps = state => ({
  language: state.general.localization.language
});

Logo.defaultProps = {
  header: false,
};

Logo.propTypes = {
  header: PropTypes.bool,
  language: PropTypes.object
};

export default connect(mapStateToProps)(Logo);
