/* eslint-disable camelcase */

import React from 'react';
import { connect } from 'react-redux';
import {clearPageDescription, getTranslation} from "../../services/main";

const ContactsBlock = ({ address, translations }) => {
  const { department_localizations, is_active } = address;
  if (!department_localizations[0] || !is_active) return null;
  const { contact, first_address, second_address } = department_localizations[0];
  return (
    <React.Fragment>
      <p className="footer-address-wrapper">
        {first_address}
      </p>
      <p className="footer-address-wrapper">
        {second_address}
      </p>
      {contact.contact_entries.map(el => (
        <p
          key={`address-item-${el.id}`}
          className="footer-address-wrapper"
        >
          {`${clearPageDescription(getTranslation(el.type, translations)).toLowerCase()}: ${el.value}`}
        </p>
      ))}
    </React.Fragment>
  );

};

const mapStateToProps = state => ({
    translations: state.translations
});

export default connect(mapStateToProps)(ContactsBlock);
