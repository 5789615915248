/* eslint-disable no-restricted-syntax,no-useless-escape */


export function allFalse(obj) {
  for (const i in obj) {
    if (obj[i] === true) return false;
  }
  return true;
}

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhone(phone) {
  return phone.match(/\d/g).length===12;
}
