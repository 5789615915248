/* eslint-disable react/no-array-index-key,react/prop-types */
/* eslint-disable camelcase */

import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.scss';
import '../assets/styles/slider.scss';

import LinkWrapper from './util/LinkWrapper';

class SliderMain extends React.PureComponent {
  componentDidMount() {
    this.getContainerSize();
    window.addEventListener('resize', this.getContainerSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getContainerSize);
  }

  getContainerSize = () => {
    const container = document.getElementsByClassName('container')[0];

    const prevButton = document.getElementsByClassName('slick-prev')[0];
    const nextButton = document.getElementsByClassName('slick-next')[0];
    const dotsList = document.getElementsByClassName('slick-dots')[0];

    if (prevButton && nextButton && dotsList) {
      if (window.innerWidth <= 1440) {
        prevButton.style.left = `${container.offsetLeft}px`;
        nextButton.style.left = `${container.offsetLeft}px`;
        dotsList.style.right = `${container.offsetLeft}px`;
      } else {
        prevButton.style.left = '';
        nextButton.style.left = '';
        dotsList.style.right = '';
      }
    }
  };

  render() {
    const { speed, banners } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: speed * 1000,
    };

    return (
      <Slider {...settings}>
        {banners
          .filter(({ banner_localizations, is_active }) => is_active && banner_localizations.length)
          .sort((a, b) => (a.banner_localizations[0].priority - b.banner_localizations[0].priority))
          .map(({ banner_localizations: item }) => (
            <div
              key={`slider-main-item-${item.id}`}
              className="item-wrapper"
            >
              <LinkWrapper link={item[0].link}>
                <img src={item[0].image && item[0].image.href} alt="" />
                <div className="slider-gradient" />
                {item[0].title.trim() && (
                  <div className="slide-title_wrapper">
                    <div className="container">
                      <div className="slide-title_text">
                        {item[0].title}
                      </div>
                    </div>
                  </div>
                )}
              </LinkWrapper>
            </div>
          ))}
      </Slider>
    );
  }
}

export default SliderMain;
