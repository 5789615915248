/* eslint-disable react/prop-types */
import React from 'react';
import { StickyContainer, Sticky } from 'react-sticky';

import '../assets/styles/news.scss';

import SideNews from './news/SideNews';

const ContentBlock = ({ children, empty }) => (
  <StickyContainer className="main-content-block">
    <div className="news-content-block">
      {!empty && children}
    </div>
    <Sticky>
      {({ style }) => (
        <div
          style={style}
          className="side-news-block"
        >
          <SideNews />
        </div>
      )}
    </Sticky>
  </StickyContainer>
);

export default ContentBlock;
