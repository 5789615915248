/* eslint-disable react/no-array-index-key,camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import '../assets/styles/news.scss';

import Breadcrumbs from '../components/util/Breadcrumbs';
import Page from '../modules/views/Page';

import FiltersSearch from '../components/FiltersSearch';
import ContentBlock from '../components/ContentBlock';
import AccordionFilters from '../components/util/AccordionFilters';
import {clearPageDescription, getTranslation} from "../services/main";
import fetchOne from '../services/fetch';
import { CORE_URL } from '../constants/urls';
import Pagination from '../components/news/Pagination';
import BottomScrollListener from 'react-bottom-scroll-listener';
import loadingCircles from '../assets/images/loading-circles.svg';

const getLinkAndFormat = (item) => {
  if (item.page_format === 'news') {
    return { link: `news/${item.alias}`, format: 'news-page' };
  } else if (item.page_format === 'page') {
    return { link: `${item.alias}`, format: 'search-pages' };
  } else if (item.page_format === 'book') {
    return { link: `books/${item.alias}`, format: 'book-page' };
  }
};

class Search extends React.Component {
  state = {
    title: '',

    loading: true,
    error: false,

    // search: this.props.search,
    // filters: this.props.routes,

    page: 1,
    results: [],
    pagination: {},
    loadingSearch: false,
  };

  componentDidMount() {
    this.getContent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.search !== prevProps.search) {
      this.getContent();
      return;
    }
    if (this.state.page !== prevState.page && this.state.page !== 1) {
      this.loadContent();
      return;
    }
  }

  getContent = async () => {
    const { search } = this.props;
    try {
      const s = search.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
      if (s.length > 2) {
        const q = encodeURIComponent(s);
        const { collection, pagination } = await fetchOne({
          url: `${CORE_URL}/search?q=${q}&limit=20&page=1`,
        });
        this.setState({
          page: 1,
          results: collection,
          pagination,
          loading: false,
          loadingSearch: false,
        });
      } else {
        this.setState({
          loading: false,
          loadingSearch: false,
        });
      }
    } catch (err) {
      this.setState({
        error: true,
        loadingSearch: false,
      });
      console.log(err);
    }
  };

  loadContent = async () => {
    const { search } = this.props;
      try {
        const s = search.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
        if (s.length > 2) {
          const q = encodeURIComponent(s);
          const { collection } = await fetchOne({
            url: `${CORE_URL}/search?q=${q}&limit=20&page=${this.state.page}`,
          });
          this.setState({
            results: [...this.state.results, ...collection],
            loading: false,
            loadingSearch: false,
          });
        } else {
          this.setState({
            loading: false,
            loadingSearch: false,
          });
        }
      } catch (err) {
        this.setState({ error: true, loadingSearch: false });
        console.log(err);
      }
  }

  bottomListener = () => {
    const totalPages = Math.ceil(this.state.pagination.currentItemCount / 20);
    if (this.state.page < totalPages) {
      this.setState(state => ({ page: state.page + 1, loadingSearch: true }));
    }
  }

  onKeyDownHandler = ({ keyCode }) => (keyCode === 13) && this.getContent();

  setFilters = () => {};

  render() {
    const { translations } = this.props;
    const {
      title, results, search, pagination, loadingSearch,
      error, loading, filters,
    } = this.state;

    return (
      <Page
        error={error}
        title={title}
        loading={loading}
        languageChange={this.getContent}
      >
        <div className="container news-page">
          <div className="row no-gutter">
            <div className="col-xl-12 breadcrumb-wrapper">
              <Breadcrumbs string={clearPageDescription(getTranslation('search', translations))} />
            </div>
          </div>
          <div className="row no-gutter">
            <div className="col-xl-12 p-0">
              <p className="page-title">
                {`${clearPageDescription(getTranslation('search', translations))}: ${pagination.currentItemCount || 0}`}
              </p>
            </div>
          </div>
          <ContentBlock>
            <div className="row no-gutter">
              <div className="w-100 p-0">
                {/*<AccordionFilters*/}
                {/*  header={(*/}
                {/*    <div className="search-block">*/}
                {/*      <input*/}
                {/*        className="search-input"*/}
                {/*        type="search"*/}
                {/*        defaultValue={search}*/}
                {/*        onChange={this.searchInput}*/}
                {/*        onKeyDown={this.onKeyDownHandler}*/}
                {/*      />*/}
                {/*      <button*/}
                {/*        className="search-submit"*/}
                {/*        type="submit"*/}
                {/*        onClick={this.getContent}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  )}*/}
                {/*  button={(<p>{clearPageDescription(getTranslation('filters', translations))}</p>)}*/}
                {/*  body={(*/}
                {/*    <FiltersSearch*/}
                {/*      filters={filters}*/}
                {/*      setFilter={this.setFilters}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*  close={this.filtersAccept}*/}
                {/*/>*/}
              </div>
            </div>
            <BottomScrollListener onBottom={this.bottomListener} offset={600} />
            <div className="row no-gutter">
              <div className="col-xl-12 search-wrapper">
                {(results.length ? results
                  .map((item, index) => (
                    <div
                      key={`news-item-${index}`}
                      className="search-item_wrapper"
                    >
                      <p className="search-item_text">
                        {clearPageDescription(getTranslation(getLinkAndFormat(item).format, translations))}
                      </p>
                      <p className="search-item_title">
                        <Link to={getLinkAndFormat(item).link}>{item.title}</Link>
                      </p>
                    </div>
                  )) : (
                    <p className="empty-text">
                      {clearPageDescription(getTranslation('not-found', translations))}
                    </p>
                ))}
                {!loading && loadingSearch &&
                <div className="rotating-loading">
                  <img src={loadingCircles} alt="" />
                </div>
                }
              </div>
            </div>
          </ContentBlock>
        </div>
      </Page>
    );
  }
}

Search.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.any).isRequired,
  translations: PropTypes.arrayOf(PropTypes.any).isRequired,
  search: PropTypes.string.isRequired,
};

const mapStateToProps = ({ general, translations }) => ({
  routes: general.routes,
  search: general.search,
  translations,
});

export default connect(mapStateToProps)(Search);
