import React from 'react';
import { Link } from 'react-router-dom';

import fetchOne from '../../../services/fetch';
import { CORE_URL } from '../../../constants/urls';

import Location from '../../../assets/svg/location.svg';
import Phone2 from '../../../assets/svg/phone2.svg';
import Phone from '../../../assets/svg/phone.svg';
import Email from '../../../assets/svg/email.svg';

import './org-structure.scss';

const iconSrc = (type) => {
  switch (type) {
    case 'phone':
      return Phone2;
    case 'email':
      return Email;
    case 'fax':
      return Phone;
    default:
      return Phone2;
  }
};

class OrgContacts extends React.Component {
	state = {
	  section: null,
	  department: {
	  	first_address: null,
	    second_address: null,
	  },
	};

	componentDidMount() {
	  this.setState({
	    section: this.props.node.department ? 'department' : 'employee',
	  });
	  this.getDepartmentData();
	}

	componentDidUpdate(prevProps) {
	  if (prevProps.node.id !== this.props.node.id) {
	    this.setState({
	      section: this.props.node.department ? 'department' : 'employee',
		    department: {
			    first_address: null,
			    second_address: null,
		    },
	    });
	    this.getDepartmentData();
	  }
	}

	changeSection = () => {
	  if (this.state.section === 'department') {
	    this.setState({ section: 'employee' });
	  } else {
	    this.setState({ section: 'department' });
	  }
	};

	getDepartmentData = async () => {
	  if (this.props.node.department) {
	    try {
	      const data = await fetchOne({ url: `${CORE_URL}/departments/${this.props.node.department.id}` });
	      if (data.department_localizations.length) {
	        this.setState({
	          department: {
	            first_address: data.department_localizations[0].first_address,
	            second_address: data.department_localizations[0].second_address,
	          },
	        });
	      }
	    } catch (e) {
	      console.log(e);
	    }
	  }
	}

	render() {
	  const { node } = this.props;

	  return (
  <div className="section-contacts">
    {/* Department */}
    {node.department && node.department.department_localizations.length && (
    <div>
      <div className="org-fold">
	      <div className="folder-title">
		      <div className="name" style={{ marginTop: '0' }}>
			      {!!node.node_localizations[0].page ?
							<Link to={node.node_localizations[0].page.alias} className="link">
								{!!node.node_localizations.length && node.node_localizations[0].title}
							</Link>
							:
							<span>{!!node.node_localizations.length && node.node_localizations[0].title}</span>
			      }
		      </div>
	      </div>
	      <div onClick={this.changeSection} className="folder-button">
		      <i className={this.state.section === 'employee' ? 'fas fa-angle-up' : 'fas fa-angle-down'} />
	      </div>
      </div>
      {this.state.section === 'department' && (
      <div className="org-fold-cont">
        <div className="address-block">
          <div className="picture">
            <img
              src={node.department.photo && (node.department.photo.resize || node.department.photo.href)}
              alt=""
              className=""
            />
          </div>
          {(this.state.department.first_address || this.state.department.second_address)
				      	&& <div className="location" style={{ background: `url(${Location}) no-repeat ` }} />}
          <div className="address">
            <p>{this.state.department.first_address}</p>
            <p>{this.state.department.second_address}</p>
          </div>
        </div>
        <div className="contacts">
          {node.department.department_localizations[0].contact.contact_entries.map(el => (
            <div key={`address-item-${el.id}`} className="one-contact">
              <div className="mini-img orgstr-right-indent" style={{ width: '21px', height: '21px' }}>
                <img src={iconSrc(el.type)} alt="" />
              </div>
              <div className="tel-numbers orgstr-right-indent" style={{ fontSize: '14px' }}>
                {el.value}
              </div>
            </div>
				      ))}
        </div>
      </div>
		      )}
    </div>
		    )}

    {/* Employee */}
    {node.employee && node.employee.employee_localizations.length && (
    <div>
      <div className="org-fold">
	      <div className="folder-title">
		      <div className="name">
			      {node.employee.employee_localizations[0].link
							      ? (
											      <Link to={node.employee.employee_localizations[0].link} className="link">
												      {node.employee && node.employee.employee_localizations[0].name}
												      {' '}
												      {node.employee && node.employee.employee_localizations[0].middle_name}
												      {' '}
												      {node.employee && node.employee.employee_localizations[0].surname}
											      </Link>
							      )
							      : (
											      <span>
                {node.employee && node.employee.employee_localizations[0].name}
												      {' '}
												      {node.employee && node.employee.employee_localizations[0].middle_name}
												      {' '}
												      {node.employee && node.employee.employee_localizations[0].surname}
              </span>
							      )}
		      </div>
		      <div className="position">
			      <div className="arrow"/>
			      <span>{node.node_localizations[0].title}</span>
		      </div>
	      </div>
	      <div onClick={this.changeSection} className="folder-button">
		      <i className={this.state.section === 'employee' ? 'fas fa-angle-up' : 'fas fa-angle-down'} />
	      </div>
      </div>
      {this.state.section === 'employee' && (
      <div className="org-fold-cont">
        <div className="address-block">
          <div className="avatar">
            <img
              src={node.employee.photo && (node.employee.photo.resize || node.employee.photo.href)}
              alt=""
              className=""
            />
          </div>
          {(node.employee.employee_localizations[0].first_address || node.employee.employee_localizations[0].second_address)
				      	&& <div className="location" style={{ background: `url(${Location}) no-repeat bottom` }} />}
          <div className="address">
            <p>{node.employee.employee_localizations[0].first_address}</p>
            <p>{node.employee.employee_localizations[0].second_address}</p>
          </div>
        </div>
        <div className="contacts">
          {node.employee.employee_localizations[0].contact.contact_entries.map(el => (
            <div key={`address-item-${el.id}`} className="one-contact">
              <div className="mini-img orgstr-right-indent" style={{ width: '21px', height: '21px' }}>
                <img src={iconSrc(el.type)} alt="" />
              </div>
              <div className="tel-numbers orgstr-right-indent" style={{ fontSize: '14px' }}>
                {el.value}
              </div>
            </div>
				      ))}
        </div>

      </div>
		      )}
    </div>
		    )}
  </div>
	  );
	}
}

export default OrgContacts;
