import {
  RECEIVE_NEWS,
} from '../constants/redux';

const initialState = [];

const news = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_NEWS:
      return [...action.data];
    default:
      return state;
  }
};

export default news;
