const bigHeadersContentEN = {
    propositions: 'Our services',
    partners: 'Our partners',
    about_about: 'About us',
    about_history: 'History',
    about_partners: 'Partners (Ukraine and the world)',
    about_nodes: 'Organization structure',
    about_nodes_leadership: 'Leadership'
};

export default bigHeadersContentEN;
