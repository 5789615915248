import React from 'react';
import ShareButton from '../util/ShareButton';
import { BASE_URL } from '../../constants/urls';

const NewsBottom = ({ date, social, tags, title, url, image }) => (
  <div className="news-bottom">
    <div className="news-time-block">
      <span>
        {date.hhmm}
      </span>
      {date.ddmmyyyy}
    </div>
    <div className="tags-share-wrapper">
      <div
        className="compact-tags"
        style={{ opacity: (tags && tags.length) ? 1 : 0 }}
      >
        {!!(tags && tags.length) && tags.length}
      </div>
      {social && (
        <div className="share-wrapper">
          <ShareButton title={title} link={`${BASE_URL}${url}`} image={image} />
        </div>
      )}
    </div>
  </div>
);

export default NewsBottom;
