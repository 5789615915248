const servicesContentEN = [
  { text: 'Services', url: '/', img: '/images/mask.png' },
  { text: 'Platform', url: '/', img: '/images/mask.png' },
  { text: 'Certificates', url: '/', img: '/images/mask.png' },
  { text: 'Logs', url: '/', img: '/images/icon_3.png' },
  { text: 'NPP Status', url: '/aes-state', img: '/images/icon_4.png' },
  { text: 'Base NPA of Ukraine and the Magate', url: '/', img: '/images/group-2-copy.png' },
];

export default servicesContentEN;
