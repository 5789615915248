import React from 'react'
import { Link } from 'react-router-dom'

import '../../assets/styles/smallNewsBlock.scss'

import BooksBottom from './BooksBottom'
import LinkWrapper from '../util/LinkWrapper'

const Book = ({
	              photo, cover, title, rating, reviews, tags, alias, parent, price, currency, is_sale
              }) => (
				<LinkWrapper link={`${parent}/${alias}`}>
					<div className="small-card">
						<div className="book-image_wrapper">
							<img
											className="book-image"
											src={photo.resize || photo.href}
											alt=""
							/>
							{!!price && is_sale &&
							<span className='currency-editions'>
          {price}
								{' '}
								{currency ? currency : 'UAH'}
        </span>
							}
						</div>
						<div className="book-text_wr">
							{/*<Link to={`${parent}/${alias}`}>*/}
							{title}
							{/*</Link>*/}
						</div>
						<BooksBottom score={rating} reviews={reviews} tags={tags}/>
					</div>
				</LinkWrapper>
)

export default Book
