import { TRANSLATIONS_DOWNLOADED } from '../constants/redux';

const translations = (state = [], action) => {
  switch (action.type) {
    case TRANSLATIONS_DOWNLOADED:
      return action.translations;
    default:
      return state;
  }
};

export default translations;
