import {
  INIT_LANGUAGE,
  TOGGLE_MODAL,
  SWITCH_LANGUAGE,
  INIT_SEARCH,
  INIT_APP,
} from '../constants/redux';

const initialState = {
  settings: {
    news_amount_on_main_page: 0,
    banner_rotation_delay: 0,
    google_plus_link: '',
    instagram_link: '',
    linked_in_link: '',
    facebook_link: '',
    twitter_link: '',
    about_us_url: '',
    youtube_link: '',
    contact_url: '',
    book_url: '',
    home_url: '',
    npp_url: '',
  },

  localization: {
    languages: [{ locale: 'en', title: '' }],
    language: {},
  },

  modal: {
    open: false,
    type: '',
    id: '',
  },

  content: {
    advertising: [],
    header: {},
    footer: {},
    menu: [],
  },

  routes: [],
  search: '',

  initiated: false,
};

const news = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        modal: {
          open: !state.modal.open,
          type: action.value.type,
          data: action.value.data,
          title: action.value.title,
        },
      };
    case INIT_APP:
      return {
        ...state,
        routes: action.data.routes,
        settings: { ...action.data.settings },
        content: {
          header: { ...action.data.header },
          footer: { ...action.data.footer },
          advertising: [...action.data.advertising],
          menu: [...action.data.menu],
        },
        initiated: true,
      };
    case INIT_LANGUAGE:
      return {
        ...state,
        localization: {
          languages: action.data.languages
            .sort(({ id }) => (id === action.data.language.id ? -1 : 1)),
          language: action.data.language,
        },
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        localization: {
          languages: state.localization.languages
            .sort(({ locale }) => (locale === action.language ? -1 : 1)),
          language: state.localization.languages
            .find(({ locale }) => locale === action.language),
        },
      };
    case INIT_SEARCH:
      return {
        ...state,
        search: action.value,
      };
    default:
      return state;
  }
};

export default news;
