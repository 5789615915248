/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LinkWrapper from './util/LinkWrapper';

import { getTranslation, clearPageDescription } from '../services/main';
import '../assets/styles/propositions.scss';

import SliderSecondary from './SliderSecondary';

const secondarySettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const emptyArr = new Array(4).fill({});

const PropositionItem = ({ item }) => (
  <div className={`propositions-item${!item ? ' no-pointer' : ''}`}>
    <LinkWrapper link={item && item[0].link}>
      <div className="propositions-image_wrapper">
        <img
          className="propositions-image"
          src={item && (item[0].image && item[0].image.href)}
          alt=""
        />
      </div>
      <div className="propositions-text">
        {item && item[0].title}
      </div>
    </LinkWrapper>
  </div>
);

const Propositions = ({
  translations, information, index,
}) => {
  const withLocale = information.filter(item => item.information_block_localizations[0]);
  const sortedList = withLocale.sort((a, b) => a.priority - b.priority);
  const allActive = sortedList.filter(item => item.is_active);

  return (
    <div className="propositions-wrapper">
      <div className="page-title">
        {index
          ? clearPageDescription(getTranslation('home-infozone3', translations))
          : clearPageDescription(getTranslation('home-infozone2', translations))}
      </div>
      <div className="row no-gutter">
        {allActive.length > 4 ? (
          <SliderSecondary settings={{ ...secondarySettings }}>
            {allActive
              .map(({ information_block_localizations: item, id }) => (
                <React.Fragment key={`propositions-item-${id}`}>
                  <PropositionItem item={item} />
                </React.Fragment>
              ))}
          </SliderSecondary>
        ) : (
          <div className="secondary-slider_empty">
            {emptyArr
              .map((item, i) => allActive[i] || item)
              .map(({ information_block_localizations: item, id }) => (
                <div key={`propositions-item-${id}`}>
                  <PropositionItem item={item} />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

Propositions.propTypes = {
  information: PropTypes.arrayOf(PropTypes.any).isRequired,
  translations: PropTypes.arrayOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
};

const mapStateToProps = ({ translations }) => ({ translations });

export default connect(mapStateToProps)(Propositions);
