import React from 'react';

const Pagination = (props) => {
  const {
    pagination: {
      first, last, next, previous, pagesInRange, lastPageInRange,
      firstPageInRange, pageCount, current,
    },
  } = props;
  const { handler } = props;

  return (
    <div className="pagination-list">
      <div
        className="slick-arrow slick-next"
        onClick={next && handler.bind(this, next)}
      />
      <ul>

        {(firstPageInRange !== first) && <li onClick={handler.bind(this, first)}>{first}</li>}
        {(firstPageInRange > 2) && <li className="no-active">...</li>}
        {pagesInRange && pagesInRange.map(el => (
          <li
            key={`page-${el}`}
            className={el === current ? 'active' : ''}
            onClick={handler.bind(this, el)}
          >
            {el}
          </li>
        ))}
        {(lastPageInRange < pageCount - 1) && <li className="no-active">...</li>}
        {(lastPageInRange !== last) && <li onClick={handler.bind(this, last)}>{last}</li>}
      </ul>
      <div
        onClick={previous && handler.bind(this, previous)}
        className="slick-arrow slick-prev"
      />
    </div>
  );
};

export default Pagination;
