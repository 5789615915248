const linksContentRU = [
  {
    title: { text: 'Новости', url: '/news' },
  },
  {
    title: { text: 'О НАС', url: '/about', point: 'about' },
    subItems: [
      { text: 'Мисия + визия', url: '#mission' },
      { text: 'Задание', url: '#task' },
      { text: 'Организационная структура', url: '#nodes' },
      { text: 'Система менеджмента', url: '#leadership' },
      { text: 'Антикорупционная кампания', url: '#anti-corruption' },
      { text: 'История', url: '#history' },
      { text: 'Партнеры(Украина и мир)', url: '#partners' },
    ],
  },
  {
    title: { text: 'Научная дейтельность', url: '' },
    subItems: [
      { text: 'Научные исследования', url: '' },
      { text: 'Научно-технический совет', url: '' },
      { text: 'Секция НТС', url: '' },
    ],
  },
  {
    title: { text: 'Экспертная дейтельность', url: '' },
    subItems: [
      { text: 'Осмотр', url: '' },
      { text: 'Лицензирование', url: '' },
      { text: 'Нормирование', url: '' },
      { text: 'Международная дейтельность', url: '' },
    ],
  },
  {
    title: { text: 'Издания', url: '/editions' },
    subItems: [
      { text: 'Художественные книги', url: '' },
      { text: 'Научные работы', url: '' },
    ],
  },
  {
    title: {
      text: 'Услуги', url: '', img: '', point: 'proposition',
    },
    subItems: [
      { text: 'Радиактивный монитор', url: '', img: '' },
      { text: 'Учеба и повышение квалификации', url: '', img: '' },
      { text: 'R & D', url: '' },
      { text: 'Технические переводы', url: '', img: '' },
    ],
  },
  {
    title: { text: 'Филиалы', url: '', point: 'affiliates' },
    subItems: [
      {
        text: 'Славутичский', url: '', name: 'Славутицкий филиал', address: 'вул. 77-ї Гвардійської дивізії, 7/4; а/с 56', data: 'good filial',
      },
      {
        text: 'Харьковский', url: '', name: 'Харьковский филиал', address: 'вул. Чернишевська, 53, 61002, Харків', data: 'good filial',
      },
    ],
  },
  {
    title: { text: 'Контакты', url: '/contacts', point: 'contacts' },
    subItems: [
      { text: 'Канцелярия', url: '' },
      { text: 'Приемная директора', url: '' },
      { text: 'Пресс-центр', url: '' },
    ],
  },
];

export default linksContentRU;
