/* eslint-disable react/no-array-index-key,camelcase */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReactHtmlParser from 'react-html-parser';

import '../assets/styles/newsItem.scss';

import ReactToPrint from 'react-to-print';
import { BASE_URL, CORE_URL } from '../constants/urls'

import { toggleModal } from '../actions/main';
import { getDescriptionWidgets, addLoadPlaceholders } from '../services/main';
import fetchOne from '../services/fetch';

import Loader from '../modules/views/Loader';
import ContentBlock from '../components/ContentBlock';
import Breadcrumbs from '../components/util/Breadcrumbs';
import Page from '../modules/views/Page';
import NotFound from './NotFound';
import ShareButton from '../components/util/ShareButton';
import Gallery from '../components/Gallery';
import FilesGallery from '../components/FilesGallery';
import IframeContainer from '../components/util/IframeContainer';
import Iblocks from '../components/Iblocks'

class PageItem extends React.Component {
  constructor(props) {
    super(props);

    this.description = React.createRef();
    this.prevX = 0;
    this.prevY = 0;

    this.state = {
      pageItem: {},
      loading: true,
      error: false,
      url: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getContent();
  }

  componentDidUpdate(oldProps) {
    const { match, lang } = this.props;
    if (oldProps.match.url !== match.url || oldProps.lang !== lang) {
      this.getContent();
      window.scrollTo(0, 0);
    }
  }

  getContent = async () => {
    const {
      match: {
        path,
        params: { alias },
      },
    } = this.props;
    let url = path.slice(1);
    if (alias) {
      url = alias;
    }

    this.setState({ url });

    try {
      const pageItem = await fetchOne({
        url: `${CORE_URL}/pages/alias/${url}`,
      });
      if (pageItem.error) {
        // console.log('point 1');
        this.setState({
          loading: false,
          error: true,
        });
      } else {
        // console.log('point 2');
        this.setState({
          pageItem,
          loading: false,
        });
      }
    } catch (err) {
      // console.log('point 3');
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  render() {
    const { pageItem, loading, error, url } = this.state;

    if (loading) return <Loader />;

    const {
      page_localizations,
      is_printable,
      is_social_networks_on,
    } = pageItem;

    if (error) {
      return <NotFound localization={page_localizations} />;
    }

    if (pageItem.page_localizations && !pageItem.page_localizations.length) {
      return <NotFound localization />;
    }

    const { title, description } = page_localizations[0];

    return (
      <Page
        error={error}
        loading={loading}
        title={title}
        languageChange={this.getContent}
      >
        <div className="container news-detail">
          <div className="row no-gutter">
            <div className="col-xl-12 breadcrumb-wrapper">
              <Breadcrumbs string={title} />
            </div>
          </div>
          <ContentBlock>
            <div className="news-section">
              <div className="news-item-wrapper">
                <div className="news-item-title_wrapper page">
                  <h3 className="news-item-title">{title}</h3>
                  <div className="share-news-wrapper page-item">
                    {is_printable && (
                      <ReactToPrint
                        trigger={() => <div className="print" />}
                        content={() => this.description.current}
                        pageStyle="padding: 10px"
                      />
                    )}
                    {is_social_networks_on && <ShareButton page down title={title} link={`${BASE_URL}/${url}`} />}
                  </div>
                </div>

                <div ref={this.description} className="news-item-description from-editor">
                  {getDescriptionWidgets(addLoadPlaceholders(description)).map(
                    (item, i) => {
                      if (item.type === 'text') {
                        return ReactHtmlParser(item.text);
                      }
                      if (item.type === 'media') {
                        return (
                          <Gallery
                            key={i}
                            media={item.media}
                            title={item.title}
                          />
                        );
                      }
                      if (item.type === 'files') {
                        return (
                          <FilesGallery
                            key={i}
                            media={item.media}
                            title={item.title}
                          />
                        );
                      }
                      if (item.type === 'iblocks') {
                        return (
                          <Iblocks
                             key={i}
                             media={item.media}
                             title={item.title}
                          />
                        );
                      }
                      if (item.type === 'iframe') {
                        return (
                          <IframeContainer
                            key={`iframe-${i}`}
                            iframe={item.media[0].iframe}
                            itext={item.media[0].itext}
                          />
                        );
                      }
                    },
                  )
                  }
                </div>
              </div>
            </div>
          </ContentBlock>
        </div>
      </Page>
    );
  }
}

PageItem.propTypes = {
  toggle: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  lang: state.general.localization.language.locale,
});

const mapDispatchToProps = dispatch => ({
  toggle: (id, type) => dispatch(toggleModal(id, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageItem);
