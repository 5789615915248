import React from 'react';
import Img from 'react-image';
import VisibilitySensor from 'react-visibility-sensor';
import Loader from '../../assets/svg/loader.svg';

const Image = ({ src, ...etc }) => (
  <VisibilitySensor>
    <Img
      {...etc}
      src={src}
      loader={(
        <img
          style={{
            height: 50,
            width: 50,
          }}
          src={Loader}
          alt=""
        />
      )}
    />
  </VisibilitySensor>
);

export default Image;
