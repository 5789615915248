/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { GET_BOOKS_TAGS } from '../../constants/urls';

import Loader from '../../modules/views/Loader';
import fetchOne from '../../services/fetch';
import { clearPageDescription, getTranslation } from '../../services/main';

const years = [
  { text: 2015, value: 2015 },
  { text: 2016, value: 2016 },
  { text: 2017, value: 2017 },
  { text: 2018, value: 2018 },
  { text: 2019, value: 2019 },
  { text: 2014, value: 2014 },
  { text: 2013, value: 2013 },
];

const covers = [
  { text: 'book-softcover', value: 'delicate' },
  { text: 'book-hardcover', value: 'solid' },
  { text: 'book-matte', value: 'matte' },
  { text: 'book-glossiness', value: 'glossiness' },
];

const languages = [
  { text: 'українська', value: 'uk' },
  { text: 'російська', value: 'rus' },
  { text: 'англійська', value: 'eng' },
];

const type = [
  { text: 'художня', value: '1' }, // (1-literature, 2-scientific)
  { text: 'наукова', value: '2' },
];

class FiltersBooks extends React.Component {
 state = {
   filterData: {
     tags: [],
     covers: [],
   },
   checkedItems: {},
   pastFilterData: this.props.pastFilterData,
   isLoading: true,
   tags: [],
 };

 componentDidMount() {
   this.getTags();
 }

 componentWillUnmount() {
   this.setFilters();
   this.syncFilters();
 }

  getTags = async () => {
    const { localization } = this.props;
    try {
      const tagsData = await fetchOne({ url: `${GET_BOOKS_TAGS}?filter=books&localization=${localization}` });
      this.setState({
        isLoading: false,
        tags: tagsData,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
      });
    }
  };

  setFilters = () => {
    const queryArr = [];
    const { onFilterFind } = this.props;
    const { filterData } = this.state;

    if (filterData.type && filterData.type !== '') {
      queryArr.push(`type=${filterData.type}`);
    }

    if (filterData.language && filterData.language !== '') {
      queryArr.push(`language=${filterData.language}`);
    }

    if (filterData.year && filterData.year !== '') {
      queryArr.push(`year=${filterData.year}`);
    }

    if (filterData.tags.length) {
      filterData.tags.forEach(item => queryArr.push(`tags[]=${item}`));
    }

    if (filterData.covers.length) {
      filterData.covers.forEach(item => queryArr.push(`covers[]=${item}`));
    }

    const queryString = queryArr.length === 1 ? queryArr[0] : queryArr.join('&');

    if (queryString.length) {
      onFilterFind(queryString);
    }
  };

  syncFilters = () => {
    const { syncPastFilterData } = this.props;
    const { filterData, pastFilterData } = this.state;
    syncPastFilterData({
      ...pastFilterData,
      ...filterData,
      covers: (pastFilterData.covers.length && !filterData.covers.length)
        ? pastFilterData.covers : filterData.covers,
      tags: (pastFilterData.tags.length && !filterData.tags.length)
        ? pastFilterData.tags : filterData.tags,
    });
  };

  removeAll = (arr, ...rest) => {
    let ax;
    let what;

    const a = rest;
    let L = a.length;

    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  };

  tagFilterChange = ({ target: { name, value } }) => {
    const { checkedItems, filterData } = this.state;
    if (checkedItems[value]) {
      delete checkedItems[value];
      this.removeAll(filterData.tags, value);
      this.setState({
        filterData: { ...filterData },
        checkedItems: { ...checkedItems },
      });
    } else {
      this.setState({
        filterData: {
          ...filterData,
          [name]: [...filterData.tags, value],
        },
        checkedItems: {
          ...checkedItems,
          [value]: true,
        },
      });
    }
  };

  coverFilterChange = ({ target: { name, value } }) => {
    const { checkedItems, filterData } = this.state;
    if (checkedItems[value]) {
      delete checkedItems[value];
      this.removeAll(filterData.covers, value);
      this.setState({
        filterData: { ...filterData },
        checkedItems: { ...checkedItems },
      });
    } else {
      this.setState({
        filterData: {
          ...filterData,
          [name]: [...filterData.covers, value],
        },
        checkedItems: {
          ...checkedItems,
          [value]: true,
        },
      });
    }
  };

  radioFilterChange = ({ target: { name, value } }) => {
    const { filterData } = this.state;
    if (filterData[name]) {
      delete filterData[name];
      this.setState({
        filterData: {
          ...filterData,
          [name]: value,
        },
      });
    } else {
      this.setState({
        filterData: {
          ...filterData,
          [name]: value,
        },
      });
    }
  };

  render() {
    const {
      pastFilterData, isLoading, tags,
    } = this.state;

    const { translations } = this.props;

    return (
      <div className="filters-select-wrapper">
        <div className="books-filter-row">
          <div className="books-filter-wrapper">
            <p className="filters-sub-title">
              {clearPageDescription(getTranslation('book-view-edition-date', translations))}
            </p>
            <ul className="books-filter-list">
              {years.map((item, index) => (
                <li
                  key={`filter-periods-tem-${index}`}
                  className="books-filter-item"
                >
                  <label htmlFor={item.id}>
                    <div className="checkbox-wrapper">
                      <input
                        type="radio"
                        id={item.id}
                        value={item.value}
                        name="year"
                        onChange={this.radioFilterChange}
                        defaultChecked={pastFilterData.year === item.value.toString()}
                      />
                      <div className="checkbox-facade" />
                    </div>
                    <p className="filters-item_title">
                      {item.text}
                    </p>
                  </label>
                </li>
              ))}
            </ul>
          </div>

          <div className="books-filter-wrapper">
            <p className="filters-sub-title">
              {clearPageDescription(getTranslation('book-view-cover', translations))}
            </p>
            <ul className="books-filter-list">
              {covers.map((item, index) => (
                <li
                  key={`filter-periods-tem-${index}`}
                  className="books-filter-item"
                >
                  <label htmlFor={item.id}>
                    <div className="checkbox-wrapper">
                      <input
                        type="checkbox"
                        id={item.id}
                        value={item.value}
                        name="covers"
                        defaultChecked={pastFilterData.covers[0] === item.value}
                        onChange={this.coverFilterChange}
                      />
                      <div className="checkbox-facade" />
                    </div>
                    <p className="filters-item_title">
                      {clearPageDescription(getTranslation(item.text, translations))}
                    </p>
                  </label>
                </li>
              ))}
            </ul>
          </div>

          <div className="books-filter-wrapper">
            <p className="filters-sub-title">
              {clearPageDescription(getTranslation('book-view-language', translations))}
            </p>
            <ul className="books-filter-list">
              {languages.map((item, index) => (
                <li
                  key={`filter-periods-tem-${index}`}
                  className="books-filter-item"
                >
                  <label htmlFor={item.id}>
                    <div className="checkbox-wrapper">
                      <input
                        type="radio"
                        id={item.id}
                        value={item.value}
                        name="language"
                        onChange={this.radioFilterChange}
                        defaultChecked={pastFilterData.language === item.value}
                      />
                      <div className="checkbox-facade" />
                    </div>
                    <p className="filters-item_title">{item.text}</p>
                  </label>
                </li>
              ))}
            </ul>
          </div>

          <div className="books-type-wrapper">
            <p className="filters-sub-title">
              {clearPageDescription(getTranslation('book-view-type', translations))}
            </p>
            <ul className="books-filter-list">
              {type.map((item, index) => (
                <li
                  key={`filter-periods-tem-${index}`}
                  className="books-filter-item"
                >
                  <label htmlFor={`type-${index}`}>
                    <div className="checkbox-wrapper">
                      <input
                        type="radio"
                        id={`type-${index}`}
                        value={item.value}
                        name="type"
                        onChange={this.radioFilterChange}
                        defaultChecked={pastFilterData.type === item.value}
                      />
                      <div className="checkbox-facade" />
                    </div>
                    <p className="filters-item_title">{item.text}</p>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {isLoading
          ? <Loader />
          : (
            <div
              className="books-filter-tags-wrapper"
              onChange={this.tagFilterChange}
            >
              <p className="filters-sub-title">
                {clearPageDescription(getTranslation('tags', translations))}
              </p>
              <ul className="filters-list">
                {tags.map((item, index) => (
                  <li
                    key={`filter-periods-tem-${index}`}
                    className="filters-item"
                  >
                    <label htmlFor={item.id}>
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.id}
                          name="tags"
                          defaultChecked={pastFilterData.tags.some(el => el === item.id)}
                        />
                        <div className="checkbox-facade" />
                      </div>
                      <p className="filters-item_title">
                        {item.title}
                      </p>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          )
        }
      </div>
    );
  }
}

FiltersBooks.propTypes = {
  onFilterFind: PropTypes.func.isRequired,
  syncPastFilterData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  localization: state.general.localization.language.id,
  locale: state.general.localization.language.locale,
  translations: state.translations,
});

export default connect(mapStateToProps)(FiltersBooks);
