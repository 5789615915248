/* eslint-disable react/no-array-index-key,camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import BottomScrollListener from 'react-bottom-scroll-listener';
import '../assets/styles/smallNewsBlock.scss';

import ReactHtmlParser from 'react-html-parser';
import ReactToPrint from 'react-to-print';
import Breadcrumbs from '../components/util/Breadcrumbs';
import Voting from '../components/util/Voting';
import BigNewsTags from '../components/news/BigNewsTags';

import { BASE_URL, GET_BOOK_BY_ID, GET_BOOK_REVIEW_BY_ID } from '../constants/urls'
import { formatDateString, arrayToObject, clearPageDescription } from '../services/main';
import fetchOne from '../services/fetch';

import Feedback from '../assets/svg/feedback.svg';
import Loader from '../modules/views/Loader';
import ContentBlock from '../components/ContentBlock';
import NotFound from './NotFound';
import BuyBook from '../components/editions/BuyBook';
import FeedbackModal from '../components/editions/Feedback';
import loadingCircles from '../assets/images/loading-circles.svg';
import ShareButton from '../components/util/ShareButton';

class BookView extends Component {
  state = {
    books: {},
    isLoading: true,
    error: null,
    comments: [],
    showBuyModal: false,
    showFeedbackModal: false,
    showFeedbackAlert: false,
    showBuyAlert: false,
    loadingComments: false,
  };

  description = React.createRef();
  prevX = 0;
  prevY = 0;

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.getData();
    }
  }

  getData = async () => {
    window.scrollTo(0, 0);
    try {
      const { match } = this.props;
      const books = await fetchOne({ url: `${GET_BOOK_BY_ID}/alias/${match.params.alias}` });
      const comments = await fetchOne({ url: `${GET_BOOK_REVIEW_BY_ID}?type=2&book=${books.id}&limit=5&page=1` });

      this.setState({ books, isLoading: false, comments });
    } catch (err) {
      this.setState({ error: err, isLoading: false });
    }
  }

  toggleBuyModal = (id) => {
    if (typeof (id) === 'string') {
      this.setState({
        showBuyAlert: true,
      });
    }

    this.setState(prevState => ({
      showBuyModal: !prevState.showBuyModal,
    }));
  };

  toggleFeedbackModal = (id) => {
    if (typeof (id) === 'string') {
      this.setState({
        showFeedbackAlert: true,
      });
    }

    this.setState(prevState => ({
      showFeedbackModal: !prevState.showFeedbackModal,
    }));
  };

  toggleCommentLoadingState = (prevState) => {
    let newState = {
      loadingComments: !prevState.loadingComments,
    };
    this.setState(newState);
  }


  loadContent = async() => {
    if (!this.state.loadingComments) {
      this.toggleCommentLoadingState(this.state);

      await this.loadComments().then(() => {
        this.toggleCommentLoadingState(this.state);
      });
    }

  };

  loadComments = async () => {
    const { comments, books } = this.state;

    const total = comments.pagination.totalCount;
    const page = comments.pagination.current + 1;
    const data = comments.collection;

    if (data.length < total) {
      const res = await fetchOne({ url: `${GET_BOOK_REVIEW_BY_ID}?type=2&book=${books.id}&limit=5&page=${page}` });

      const new_comments = comments.collection.concat(res.collection);

      this.setState({
        comments: {
          collection: new_comments,
          pagination: res.pagination,
        },
      });
    }
  };

  render() {
    const { translations } = this.props;
    const {
      books, isLoading, error, comments, showBuyModal, showFeedbackModal, showFeedbackAlert, showBuyAlert, loadingComments,
    } = this.state;

    if (error) return <NotFound />;

    if (isLoading) return (<Loader />);

    if (!books.book_localizations[0]) {
      return (
        <NotFound localization />
      );
    }

    const translatedArr = arrayToObject(translations);
    const {
      title, page_amount, isbn, year, cover,
      language, additional_information, description, tags,
    } = books.book_localizations[0];

    return (
      <React.Fragment>
        <div className="container">
          <div className="col-xl-12 breadcrumb-wrapper">
            <Breadcrumbs title={title} />
          </div>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <ContentBlock><div ref={this.description}>
            <div className="book-view-header">
              {title}
              <div className="share-news-wrapper page-item">
                <ReactToPrint
                        trigger={() => <div className="print"/>}
                        content={() => this.description.current}
                        pageStyle="padding: 10px"
                />
                <ShareButton page down title={title} link={`${BASE_URL}/books/${this.props.match.params.alias}`}/>
              </div>
            </div>
            <div className="product-book-block">
              <div className="row no-gutter">

                <div className="product-book-image">
                  <img src={books.photo && (books.photo.resize || books.photo.href)} alt=""/>
                </div>
                <div className="product-book-info">
                  <table>
                    <tbody>
                    <tr>
                      <td className="book-table-key">
                        {translatedArr['book-view-type']}:
                      </td>
                      <td className="book-table-value">
                        {+books.type === 1 ? translatedArr['book-type=1'] : translatedArr['book-type=2']}
                      </td>
                    </tr>
                    <tr>
                      <td className="book-table-key">{translatedArr['book-view-page-count']}:</td>
                      <td className="book-table-value">{page_amount}</td>
                    </tr>
                    <tr>
                      <td className="book-table-key">
                        ISBN:
                      </td>
                      <td className="book-table-value">
                        {isbn || '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className="book-table-key">
                        {translatedArr['book-view-edition-date']}:
                      </td>
                      <td className="book-table-value">{year || '-'}</td>
                    </tr>
                    <tr>
                      <td className="book-table-key">
                        {translatedArr['book-view-cover']}:
                      </td>
                      <td className="book-table-value">
                        {cover ? (
                                        <span> {cover.split(',').map(item => {
                                          return (
                                                  <span key={item}>{translatedArr[`book-${item}`]} </span>
                                          )
                                        })
                                        } </span>
                                )
                                :
                                (<span>-</span>)
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="book-table-key">
                        {translatedArr['book-view-language']}
                        :
                      </td>
                      <td className="book-table-value">
                        {language ? language : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className="book-table-key">
                        {translatedArr['book-view-series']}:
                      </td>
                      <td className="book-table-value">
                        {books.series && books.series.book_series_localizations
                        && books.series.book_series_localizations[0].title}
                      </td>
                    </tr>
                    <tr>
                      <td className="book-table-key">
                        {translatedArr['book-view-addition-information']}:
                      </td>
                      <td className="book-table-value">{additional_information || '-'}</td>
                    </tr>
                    </tbody>
                  </table>
                  <div className="product-price-block">
                    <div className="rating-comment">
                      <Voting score={books.rating}/>
                      <div className="comment-rating-ratio">
                        {books.rating}/5
                      </div>
                    </div>
                    {books.is_sale &&
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span style={{
                      textShadow: '0 6px 18px rgba(0, 0, 0, 0.12)',
                      fontSize: '24px',
                      fontWeight: '600',
                      color: '#e46c2a',
                      margin: '18px 30px 0 0',
                    }}>
                        {books.price ? books.price : '-'}
                      {' '}
                      {books.currency ? books.currency.toUpperCase() : 'UAH'}
                      </span>
                      <button
                              type="button"
                              className="book-buy-button"
                              onClick={this.toggleBuyModal}
                      >
                      <span>
                        {clearPageDescription(translatedArr['book-button-buy'])}
                      </span>
                      </button>
                    </div>
                    }
                    {!!showBuyAlert &&
                    <div style={{
                      marginTop: 10,
                      fontSize: '16px'
                    }}>
                      {clearPageDescription(translatedArr['book-buy-alert'])}
                    </div>
                    }
                  </div>
                </div>

              </div>
            </div>
            <div className="edition-block-description">
              <div className="product-book-description">
                {ReactHtmlParser(description)}
              </div>

              <div className="comment-tag-block">
                <div className="product-book-tags">
                  <BigNewsTags tags={tags || ''} all/>
                </div>
                <div className="book-comments-header">
                  <div className="comment-main-header">
                    {clearPageDescription(translatedArr['review'])}
                  </div>
                  <div className="comments-ratio-numbers">
                    {comments.collection.length} / {comments.pagination.totalCount}
                  </div>
                  <div className="book-leave-comment-block"
                       onClick={this.toggleFeedbackModal}>
                    <img src={Feedback} alt=""/>
                  </div>

                  {!!showFeedbackAlert &&
                  <div style={{
                    marginLeft: 20,
                    fontSize: '16px'
                  }}>
                    {clearPageDescription(translatedArr['book-feedback-alert'])}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div className="book-comment-block">
              <BottomScrollListener onBottom={this.loadContent} offset={500}/>
              {comments.collection.map((comment, ind) => (
                      <div className="comment-block-item" key={`book-comment-${ind}`}>
                        <div className="comment-block-header">
                          <div className="comment-author">
                            {comment.surname} {comment.name}
                            <div className="rating-comment">
                              <Voting score={comment.rating}/>
                              <div className="comment-rating-ratio">
                                {comment.rating}
                                /5
                              </div>
                            </div>
                          </div>
                          <div className="news-time-block">
                      <span>
                        {formatDateString(comment.created_at).hhmm}
                        {' '}
                        &nbsp;
                      </span>
                            {formatDateString(comment.created_at).ddmmyyyy}
                          </div>
                        </div>
                        <div className="comment-content-block">
                          {comment.review}
                        </div>
                      </div>
              ))}
            </div>
          </div>

            {!isLoading && loadingComments &&
              <div className="rotating-loading">
                <img src={loadingCircles} alt="" />
              </div>
            }
          </ContentBlock>
        </div>

        <BuyBook
          show={showBuyModal}
          book={books.id}
          handleClose={id => this.toggleBuyModal(id)}
        />
        <FeedbackModal
          show={showFeedbackModal}
          book={books.id}
          handleClose={id => this.toggleFeedbackModal(id)}
        />
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => ({
  translations: state.translations,
  language: state.general.localization.language.locale,
});


export default connect(mapStateToProps)(BookView);
