/* eslint-disable react/no-array-index-key, react/prop-types */

import React from 'react';
import { connect } from 'react-redux';

import LinkWrapper from './LinkWrapper';

const itemHeight = 30;

class ShareButton extends React.Component {
  state = {
    open: false,
    resources: [
      {
        icon: 'fa-share-alt',
        link: '',
      },
      {
        icon: 'fa-facebook',
        link: `https://www.facebook.com/sharer/sharer.php?u=${this.props.link}&amp;src=sdkpreparse`,
      },
      {
        icon: 'fa-twitter',
        link: `https://twitter.com/intent/tweet?text=${this.props.title}&url=${this.props.link}`,
      },
      {
        icon: 'fa-envelope',
        link: `mailto:?subject=${this.props.title}&body=${this.props.link}`,
      },
    ],
  };

  // componentWillUnmount() {
  //   const meta = document.querySelectorAll('meta[property]');
  //   for (let i = 0; i < meta.length; i++) {
  //     meta[i].parentNode.removeChild(meta[i]);
  //   }
  // }

  toggleMenu = open => this.setState({ open });

  render() {
    const { open, resources } = this.state;
    const { page, down } = this.props;

    const directionClass = down ? 'share-list-down' : 'share-list';
    const openClass = open ? `${directionClass} opened` : directionClass;

    return (
      <ul
        className={openClass}
        style={{
          height: open ? `${resources.length * itemHeight}px` : `${itemHeight}px`,
          color: page ? '#000' : '#acacac',
        }}
        onMouseEnter={() => {
          this.toggleMenu(true);
          //
          // const title = document.createElement('meta');
          // title.setAttribute('property', 'og:title');
          // title.content = this.props.title;
          // document.getElementsByTagName('head')[0].appendChild(title);
          //
          // const article = document.createElement('meta');
          // article.setAttribute('property', 'og:type');
          // article.content = 'article';
          // document.getElementsByTagName('head')[0].appendChild(article);
          //
          // const image = document.createElement('meta');
          // image.setAttribute('property', 'og:image');
          // image.content = this.props.image;
          // document.getElementsByTagName('head')[0].appendChild(image);
          //
          // console.log(title, article, image);
        }}

        onMouseLeave={() => {
          this.toggleMenu(false);
          // const meta = document.querySelectorAll('meta[property]');
          // for (let i = 0; i < meta.length; i++) {
          //   meta[i].parentNode.removeChild(meta[i]);
          // }
        }}
      >
        {resources.map((item, index) => (
          <li
            key={`share-item-${index}`}
            className="share-item"
            style={{
              transform: open
                ? `translateY(${!down ? '-' : ''}${`${index * itemHeight}px`})`
                : 'translateY(0)',
              zIndex: resources.length - index + 10,
            }}
          >
            {!index ? (
              <span>
                <i className={`fa ${item.icon}`} />
              </span>
            ) : (
              <LinkWrapper link={item.link}>
                <i className={`fa ${item.icon}`} />
              </LinkWrapper>
            )}
          </li>
        ))}
      </ul>
    );
  }
}

const mapStateToProps = ({ general }) => ({ settings: general.settings });

export default connect(mapStateToProps)(ShareButton);
