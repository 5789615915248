/* eslint-disable react/prop-types,react/no-array-index-key,camelcase */
import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import ContactsBlock from '../../components/util/ContactsBlock';

import '../../assets/styles/footer.scss';

import { generateURL, generateSubURL, getTranslation } from '../../services/main'

import Logo from '../../components/util/Logo';
import Social from '../../components/util/Social';
import { connect } from 'react-redux'

function chunkArray(myArray, chunkSize) {
  const arrayLength = myArray.length;
  const tempArray = [];
  for (let index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize);
    tempArray.push(myChunk);
  }
  return tempArray;
}

const customBlocks = ['mission', 'task', 'mission', 'leadership', 'anti-corruption', 'history'];

const FooterLinksBlockSingle = ({ chunk, translations }) => {
  // console.log(generateURL(chunk), chunk.menu_localizations[0].title);
  return <React.Fragment>
    {generateURL(chunk) !== '/empty' ? <div className="footer-link-block-header">
      <div className="arrow-right" />
      <NavLink
        to={generateURL(chunk)}
        className="link-header"
      >
        {!!chunk.menu_localizations.length
          && chunk.menu_localizations[0].title}
      </NavLink>
    </div> :
            <div className="footer-link-block-header footer-no-links">
              <div className="arrow-right" />
              <NavLink
                      className="link-header no-underline"
              >
                {!!chunk.menu_localizations.length
                && chunk.menu_localizations[0].title}
              </NavLink>
            </div>}
    {chunk.children && !!chunk.children.length && chunk.children
        .sort((a,b) => a.priority - b.priority)
        .filter(el => (el.url && el.url.split('#')[0]) !== 'about-us'
                        || !customBlocks.some(cb => cb === el.url.split('#')[1])
                        || !!getTranslation(el.url.split('#')[1], translations)
        )
        .map(item => {
        return <div key={`sub-text-${item.id}`} className="footer-sublinks">
          <Link to={generateSubURL(item)}>
            {!!item.menu_localizations.length
            && item.menu_localizations[0].title}
          </Link>
        </div>
      })}
  </React.Fragment>
};

const FooterLinksBlockDoubled = ({ chunks, translations }) => (
  <React.Fragment>
    {/*{<div className={generateURL(chunks[0]) === '/empty' ? 'footer-sublinks' : ''}>*/}
      <FooterLinksBlockSingle chunk={chunks[0]} />
      {/*<br />*/}
    {/*</div>}*/}
    {chunks.length === 2 ? <FooterLinksBlockSingle chunk={chunks[1]} translations={translations} /> : ''}
  </React.Fragment>
);

class Footer extends React.PureComponent {
  render() {
    const { content, translations } = this.props;
    return (
      <footer className="App-footer">
        <div className="container">
          <div className="footer-centered">
            <div className="footer-logo-block">
              <Logo />
            </div>
            <div className="footer-links">
              {chunkArray(content.menu, 2).map((chunk, index) => (
                <div
                  key={`footer-row-${index}`}
                  className="footer-link-category"
                >
                  <FooterLinksBlockDoubled chunks={chunk} translations={translations} />
                </div>
              ))}
            </div>
            <div className="footer-static-block">
              <div className="footer-logo-block">
                <Logo />
              </div>
              <div id="footer-contacts">
                {content.footer.department && (
                  <ContactsBlock address={content.footer.department} />
                )}
              </div>
              <div className="footer-social-block">
                {(content.footer.telegram_bots && !!content.footer.telegram_bots.length) && (
                  <div className="telegram-button">
                    <i className="fab fa-telegram-plane" />
                    <ul className="telegram-list telegram-list-footer">
                      {content.footer.telegram_bots.map(bot => (
                        <li
                          key={`telegram-bot-item${bot.id}`}
                          role="presentation"
                        >
                          <a href={
                            bot.telegram_bot_localizations[0]
                            && bot.telegram_bot_localizations[0].link
                          }
                          >
                            {bot.telegram_bot_localizations[0]
                            && bot.telegram_bot_localizations[0].name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <Social socials={content.footer} />
              </div>
            </div>

            <div className="footer-centered-confidential">
              <div id="footer-copyright">
              Copyright &copy; {new Date().getFullYear()} sstc.ua
              </div>
            </div>
          </div>
          <div className="footer-centered-confidential outside-confidential">
            <div id="footer-copyright">
              Copyright &copy; {new Date().getFullYear()} sstc.ua
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translations,
});

export default connect(mapStateToProps)(Footer);
