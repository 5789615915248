const oneDay = 24 * 60 * 60 * 1000;
const today = new Date().setHours(0, 0, 0, 0);

export const periods = [
  {
    title: 'filter-today',
    time: {
      fr: new Date(today),
      to: new Date(today),
      // to: new Date(today + oneDay),
    },
  },
  {
    title: 'filter-yesterday',
    time: {
      fr: new Date(today - oneDay),
      to: new Date(today - oneDay),
      // to: new Date(today),
    },
  },
  {
    title: 'filter-this-week',
    time: {
      fr: new Date(today - ((new Date().getDay() - 1) * oneDay)),
      to: new Date(today),
    },
  },
  {
    title: 'filter-last-month',
    time: {
      fr: new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)),
      to: new Date(new Date(new Date().setDate(1)) - oneDay),
      // to: new Date(new Date().setDate(1)),
    },
  },
  {
    title: 'filter-last-7-days',
    time: {
      fr: new Date(today - (7 * oneDay)),
      to: new Date(today),
    },
  },
  {
    title: 'filter-last-week',
    time: {
      fr: new Date(today - (((new Date().getDay() - 1) + 7) * oneDay)),
      // fr: new Date(today - (7 * 7 * oneDay)),
      to: new Date(today - ((new Date().getDay()) * oneDay)),
      // to: new Date(today - (7 * oneDay)),
    },
  },
  {
    title: 'filter-this-month',
    time: {
      fr: new Date(today - ((new Date().getDate() - 1) * oneDay)),
      to: new Date(today),
    },
  },
  {
    title: 'filter-last-90-days',
    time: {
      fr: new Date(today - (90 * oneDay)),
      to: new Date(today),
    },
  },
  {
    title: 'filter-last-180-days',
    time: {
      fr: new Date(today - (180 * oneDay)),
      to: new Date(today),
    },
  },
  {
    title: 'filter-this-year',
    time: {
      fr: new Date(new Date(new Date().setMonth(0)).setDate(1)),
      to: new Date(today),
    },
  },
  {
    title: 'filter-last-year',
    time: {
      fr: new Date(new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setMonth(0)).setDate(1)),
      to: new Date(new Date(new Date(new Date().setMonth(0)).setDate(1)) - oneDay),
    },
  },
];
