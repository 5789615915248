const aesStateEN = {
  data_text: 'Data for:',
  data_week: 'Week',
  data_month: 'Month',
  data_year: 'Year',
  khmelnytskyi_npp: 'Khmelnytskyi NPP',
  rivne_npp: 'Rivne NPP',
  south_ukraine_npp: 'South Ukraine NPP',
  zaporizhia_npp: 'Zaporizhia NPP',
  radiation: 'radiation background',
  radiation_about: 'Radiation background at the industrial site mcP/hour',
  block: 'Block',
  title_regulation: 'the analysis of events at NPPs',
  regulation: 'Regulation on the procedure for investigating and accounting violations in the work of nuclear power plants'
};

export default aesStateEN;
