import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SliderMedia from '../../components/SliderMedia';
import SliderFiles from '../../components/SliderFiles';

import { toggleModal } from '../../actions/main';

const Modal = ({ toggle, type, data, title }) => (
  <div
    className="modal-wrapper"
    onClick={toggle}
    role="presentation"
  >
    <div className="modal-content">
      {type === 'galleries' && (
        <SliderMedia media={data} title={title} />
      )}
      {type === 'files' && (
        <SliderFiles type={type} id={data} />
      )}
    </div>
  </div>
);

Modal.propTypes = {
  toggle: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

const mapStateToProps = ({ general }) => ({
  type: general.modal.type,
  data: general.modal.data,
  title: general.modal.title,
});

const mapDispatchToProps = dispatch => ({
  toggle: ({ target, currentTarget }) => (target === currentTarget) && dispatch(toggleModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
