import React from 'react';

import '../../assets/styles/accordion.scss';
import { GET_PARTNER_TYPES } from '../../constants/urls';
import Loader from '../../modules/views/Loader';
import fetchOne from '../../services/fetch';
import { connect } from 'react-redux';

const Fold = ({ active, handle, content }) => {

  const countPartnersWithLocale = content.partners.reduce((acc, partner) => {
    return (partner.partner_localizations[0] && partner.is_active) ? acc + 1 : acc;
  }, 0);

  return (
          // !!content.partners.length &&
          !!countPartnersWithLocale &&
          <div className="fold">
            <button
                    className={`fold_trigger ${active ? 'open' : ''}`}
                    onClick={handle}
            >
              {!!content.partner_type_localizations[0] &&
              <React.Fragment>
                <img src={content.partner_type_localizations[0].logo &&
                (content.partner_type_localizations[0].logo.resize || content.partner_type_localizations[0].logo.href)} alt="" />
                <span className="partners-title">{content.partner_type_localizations[0].title}</span>
              </React.Fragment>
              }
              <div className="partner-counter">
                <span>{countPartnersWithLocale}</span>
                <span>
          {active
                  ? <i className="fas fa-angle-up" />
                  : <i className="fas fa-angle-down" />}
        </span>
              </div>
            </button>
            <div className={`fold_content ${active ? 'open' : ''}`}>
              {active ? content.partners
              .sort((a, b) => a.priority - b.priority)
              .filter(p => p.is_active)
              .map(chunk => (
                      !!chunk.partner_localizations.length && (<div
                              key={`accordion-content-${chunk.id}`}
                              className="folder-item"
                      >
                        <div className="about-partner-image">
                          <img src={!!chunk.partner_localizations[0].logo &&
                          (chunk.partner_localizations[0].logo.resize || chunk.partner_localizations[0].logo.href)} alt=""/>
                        </div>
                        <div className="accordion-row-content">
                          <a href={chunk.partner_localizations[0].url} target="_blank" rel="noopener noreferrer">
                            <span  className="partners-title">{chunk.partner_localizations[0].title}</span>
                          </a>
                          <br />
                          <span className="partner-description">{chunk.partner_localizations[0].description}</span>
                        </div>
                      </div>)
              )) : null}
            </div>
          </div>
  )}

class Accordion extends React.Component {
  state = {
    active: false,
    partners: [],
    isLoading: false,
    error: null,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.getData();
    }
  }

  getData = async () => {
    this.setState({ isLoading: true });
    try {
      const resp = await fetchOne({ url: `${GET_PARTNER_TYPES}?limit=1000` });
      this.setState({ partners: resp, isLoading: false });
    } catch (err) {
      this.setState({ error: err, isLoading: false });
    }
  };

  selectFold = (foldNum) => {
    const { active } = this.state;
    this.setState({ active: active === foldNum ? false : foldNum });
  };

  render() {
    const { partners, error, isLoading } = this.state;
    if (error) {
      return <p>{error.message}</p>;
    }

    if (isLoading) return (<Loader />);

    return (
      <div className="accordion">
        {partners && partners
          .sort((a, b) => a.priority - b.priority)
          .map((content, i) => (
          <Fold
            key={`accordion-item-${i}`}
            handle={() => this.selectFold(i)}
            active={i === this.state.active}
            content={content}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.general.localization.language.locale,
});

export default connect(mapStateToProps)(Accordion);
