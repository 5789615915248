const linksContentEN = [
  {
    title: { text: 'News', url: '/news' },
  },
  {
    title: { text: 'ABOUT US', url: '/about', point: 'about' },
    subItems: [
      { text: 'Mission + vision', url: '#mission' },
      { text: 'Task', url: '#task' },
      { text: 'Organizational structure', url: '#nodes' },
      { text: 'Management System',url: '#leadership' },
      { text: 'Anti-corruption campaign', url: '#anti-corruption' },
      { text: 'History', url: '#history' },
      { text: 'Partners (Ukraine and the world)', url: '#partners' },
    ],
  },
  {
    title: { text: 'Scientific activity', url: '' },
    subItems: [
      { text: 'Research', url: '' },
      { text: 'Scientific and Technical Council', url: '' },
      { text: 'Section NTS', url: '' },
    ],
  },
  {
    title: { text: 'Expert activity', url: '' },
    subItems: [
      { text: 'Inspection', url: '' },
      { text: 'Licensing', url: '' },
      { text: 'Rationing', url: '' },
      { text: 'International activity', url: '' },
    ],
  },
  {
    title: { text: 'Editions', url: '/editions' },
    subItems: [
      { text: 'Art books', url: '' },
      { text: 'Scientific works', url: '' },
    ],
  },
  {
    title: {
      text: 'Services', url: '', img: '', point: 'proposition',
    },
    subItems: [
      { text: 'Radioactive monitor', url: '', img: '' },
      { text: 'Study and professional development', url: '', img: '' },
      { text: 'R & D', url: '' },
      { text: 'Technical translations', url: '', img: '' },
    ],
  },
  {
    title: { text: 'Affiliates', url: '', point: 'affiliates' },
    subItems: [
      {
        text: 'Slavutichsky', url: '', name: 'Славутицкий филиал', address: 'вул. 77-ї Гвардійської дивізії, 7/4; а/с 56', data: 'good filial',
      },
      {
        text: 'Kharkov', url: '', name: 'Харьковский филиал', address: 'вул. Чернишевська, 53, 61002, Харків', data: 'good filial',
      },
    ],
  },
  {
    title: { text: 'Contacts', url: '/contacts', point: 'contacts' },
    subItems: [
      { text: 'Office', url: '' },
      { text: 'Reception Director', url: '' },
      { text: 'Press Center', url: '' },
    ],
  },
];

export default linksContentEN;
