import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import './assets/styles/grid.scss';
import './index.scss';
import reducer from './reducers/index';
import { initLocalization } from './actions/main';
import App from './App';
import * as serviceWorker from './serviceWorker';

const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger();
  middleware.push(logger);
}

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

serviceWorker.unregister();

store.dispatch(initLocalization());

const render = Component => ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Component />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
