const bigHeadersContentRU = {
    propositions: 'Наши услуги',
    partners: 'Наши партнеры',
    about_about: 'О нас',
    about_history: 'История',
    about_partners: 'Партнеры (Украина и мир)',
    about_nodes: 'Организационная структура',
    about_nodes_leadership: 'Руководство'
};

export default bigHeadersContentRU;
