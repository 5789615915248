import React from 'react';

export default ({ num }) => (
  <svg width="36px" height="22px" viewBox="0 0 36 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>tags</title>
    <desc>Created with Sketch.</desc>
    <g id="All-final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Main-page" transform="translate(-511.000000, -1618.000000)" stroke="#444342" strokeWidth="0.9">
        <g id="News" transform="translate(150.000000, 876.000000)">
          <g id="Group-11-Copy" transform="translate(233.000000, 405.000000)">
            <g id="Group-9" transform="translate(129.000000, 338.000000)">
              <text y="14" x="20" className="book-tag-num">{num}</text>
              <g id="Group-5" transform="translate(17.000000, 10.000000) scale(-1, 1) translate(-17.000000, -10.000000) ">
                <path d="M3.06020877,0.151515152 L23.175086,0.151515152 C23.7062614,0.151515152 24.2272965,0.299090909 24.6815246,0.578181818 L32.4460509,5.34909091 C33.3181211,5.88484848 33.8507877,6.84484848 33.8507877,7.88 L33.8507877,13.1681818 C33.8507877,14.290303 33.2256649,15.3160606 32.2366825,15.8160606 L24.8735947,19.5387879 C24.4706649,19.7424242 24.0268754,19.8484848 23.5768228,19.8484848 L3.06020877,19.8484848 C1.45236667,19.8484848 0.149033333,18.5242424 0.149033333,16.8906061 L0.149033333,3.10909091 C0.149033333,1.47575758 1.45236667,0.151515152 3.06020877,0.151515152 Z" id="Stroke-1" />
                <path d="M30.122807,10 C30.122807,11.1715152 29.1881053,12.1212121 28.0350877,12.1212121 C26.8820702,12.1212121 25.9473684,11.1715152 25.9473684,10 C25.9473684,8.82848485 26.8820702,7.87878788 28.0350877,7.87878788 C29.1881053,7.87878788 30.122807,8.82848485 30.122807,10 Z" id="Stroke-3" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
