/* eslint-disable react/no-array-index-key */
import React from 'react';
import DatePicker from 'react-datepicker/es';
import uk from 'date-fns/locale/uk';
import ru from 'date-fns/locale/ru';

import { connect } from 'react-redux';

import { CORE_URL } from '../constants/urls';
import { periods } from '../constants/filters';

import { clearDate, clearPageDescription, getTranslation } from '../services/main';
import fetchOne from '../services/fetch';

class FiltersNews extends React.Component {
  state = {
    tags: [],

    // start: '',
    start: new Date(),
    // end: '',
    end: new Date(),

    input: 0,
  };

  componentDidMount() {
    this.getTags();
  }

  getTags = async () => {
    const tags = await fetchOne({ url: `${CORE_URL}/tags?filter=news&localization=${this.props.localization}` });
    this.setState({ tags });
  };

  toggleDatePicker = input => this.setState({ input });

  changeDateRange = (date) => {
    const { input } = this.state;
    const toChange = input === 0 ? 'start' : 'end';

    this.setState(({ [toChange]: date }), () => {
      const { setTime } = this.props;
      const { start, end } = this.state;

      const fr = start || new Date();
      const to = end || new Date();

      setTime(`${fr}/${to}`);
    });
  };

  setFiltersDate = (filter) => {
    const { setTime } = this.props;

    const { time } = filter;
    const { fr, to } = time;

    setTime(`${fr}/${to}`);
  };

  setFiltersOther = (filter) => {
    const { setFilter } = this.props;
    setFilter(filter);
  };

  render() {
    const {
      start, end, tags, input,
    } = this.state;
    const { filters, time, translations, locale } = this.props;

    const [tFr, tTo] = time ? time.split('/') : ['', ''];
    const selectedDate = periods
      .map((el, index) => {
        const left = clearDate(tFr) === clearDate(el.time.fr);
        const right = clearDate(tTo) === clearDate(el.time.to);
        return (left && (left === right)) ? index : '';
      });

    const lang = {
      uk,
      rus: ru,
      en: '',
    };

    return (
      <React.Fragment>
        <div className="datepicker-wrapper">
          <p>{clearPageDescription(getTranslation('home-sort-date-publication', translations))}</p>
          <div className="datepicker-container">
            <div className="dp-inputs-wrapper">
              <input
                readOnly
                type="text"
                // defaultValue={clearDate(start)}
                value={clearDate(start)}
                // defaultValue={clearDate(start)}
                className={`${input === 0 ? 'active' : ''}`}
                onFocus={() => this.toggleDatePicker(0)}
              />
              <p className="divider">-</p>
              <input
                readOnly
                type="text"
                // defaultValue={clearDate(end)}
                value={clearDate(end)}
                className={`${input === 1 ? 'active' : ''}`}
                onFocus={() => this.toggleDatePicker(1)}
              />
            </div>
            <DatePicker
              inline
              startDate={start}
              endDate={end}
              onChange={this.changeDateRange}
              locale={lang[locale]}
            />
          </div>
        </div>
        <div className="filters-select-wrapper">
          <div className="periods-wrapper">
            <p className="filters-sub-title">
              {clearPageDescription(getTranslation('filter-period', translations))}
            </p>
            <ul className="filters-list">
              {periods.map((item, index) => (
                <li
                  key={`filter-periods-tem-${index}`}
                  className="filters-item"
                >
                  <label htmlFor={item.title}>
                    <div className="checkbox-wrapper">
                      <input
                        type="checkbox"
                        id={item.title}
                        name={item.title}
                        checked={selectedDate.some(el => el === index)}
                        onChange={() => this.setFiltersDate(item)}
                      />
                      <div className="checkbox-facade" />
                    </div>
                    <p className="filters-item_title">
                      {clearPageDescription(getTranslation(item.title, translations))}
                    </p>
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className="tags-wrapper">
            <p className="filters-sub-title">
              {clearPageDescription(getTranslation('tags', translations))}
            </p>
            <ul className="filters-list">
              {tags.map(item => (
                <li
                  key={`filter-periods-tem-${item.id}`}
                  className="filters-item"
                >
                  <label htmlFor={item.id}>
                    <div className="checkbox-wrapper">
                      <input
                        type="checkbox"
                        id={item.id}
                        name={item.title}
                        checked={filters.findIndex(el => el.id === item.id) > -1}
                        onChange={() => this.setFiltersOther(item)}
                      />
                      <div className="checkbox-facade" />
                    </div>
                    <p className="filters-item_title">
                      {item.title}
                    </p>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  localization: state.general.localization.language.id,
  locale: state.general.localization.language.locale,
});

export default connect(mapStateToProps)(FiltersNews);
