import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

export class MapContainer extends Component {
    static defaultProps = {
      initialCenter: { lat: 50.4, lng: 30.5 },
      contactTitle: '',
    };

    render() {
      const { initialCenter, google, contactTitle } = this.props;

      return (
        <Map
          initialCenter={initialCenter}
          google={google}
          zoom={14}
        >
          <Marker
            title={contactTitle}
            position={initialCenter}
          />
        </Map>
      );
    }
}

export default GoogleApiWrapper({
  apiKey: (''),
})(MapContainer);
