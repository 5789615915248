import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ScrollUpButton from "react-scroll-up-button";

import PropTypes from 'prop-types';

import Logo from '../../components/util/Logo';
import Social from '../../components/util/Social';

import { switchLanguage } from '../../actions/i18n';
import { initSearch } from '../../actions/main';
import { getTranslation, withParent } from '../../services/main';

import '../../assets/styles/header.scss';
import MobileHeader from './MobileHeader';

const itemHeight = 36;

class Header extends Component {
  constructor(props) {
    super(props);

    // this.timeout = null;

    this.searchRef = React.createRef();

    this.state = {
      telegramMenu: false,
      langMenu: false,
      subNav: false,
      index: 0,
    };
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  setLanguage = (index, language) => {
    this.setState(state => ({ langMenu: !state.langMenu }));
    if (index) {
      this.setState({ langMenu: false });
      const { changeLanguage } = this.props;
      changeLanguage(language);
    }
  };

  navEnter = (index) => {
    // this.setState({ subNav: true, index });
    if (this.state.subNav) {
      this.timeout = setTimeout(() => this.setState({ subNav: true, index }), 500);
    } else {
      this.setState({ subNav: true, index });
    }
  }

  hideSubNav = (e) => {
    clearTimeout(this.timeout);
    if (e.relatedTarget.closest && e.relatedTarget.closest('.header-nav')) return;
    setTimeout(() => {
      this.setState({ subNav: false });
    }, 1000);
  };

  setSearch = () => {
    const { initGlobalSearch } = this.props;
    const { value } = this.searchRef.current;
    initGlobalSearch(value);
  };

  // selectBot = () => this.setState({ telegramMenu: false });

  toggleLangMenu = langMenu => this.setState({ langMenu });

  toggleTelegramMenu = telegramMenu => {
      this.setState({ telegramMenu });
  }

  onKeyDownHandler = ({ keyCode }) => {
    if (keyCode === 13) {
      const { history } = this.props;
      history.push('search');
      this.setSearch();
    }
  };

  updateDimensions = () => this.setState({ mobile: window.innerWidth >= 768 });

  render() {
    const {
      localization, content, translations,
    } = this.props;
    const {
      index, subNav, mobile,
      langMenu, telegramMenu,
    } = this.state;

    const { menu, header } = content;
    const { languages } = localization;

    const childrenAll = menu.length ? menu[index].children : [];

    // блок что бы отфильтровать из раздела "О нас" те пункты подменю которых нет в данной локализации
    const customBlocks = ['mission', 'task', 'mission', 'leadership', 'anti-corruption', 'history'];
    const children = childrenAll.filter(
      el => (el.url && el.url.split('#')[0]) !== 'about-us'
              || !customBlocks.some(cb => cb === el.url.split('#')[1])
              || !!getTranslation(el.url.split('#')[1], translations)
    );

    return (
      <header className="App-header">
        <div className="container">
          {mobile ? (
            <div className="header-block">
              <div className="top-header">
                <Logo header />

                <div className="col-xl-4 header-right p-0">
                  <div className="header-right_top">
                    {(header.telegram_bots && !!header.telegram_bots.length) && (
                      <div
                        className={telegramMenu ? 'telegram-wrapper opened' : 'telegram-wrapper'}
                        onMouseEnter={() => this.toggleTelegramMenu(true)}
                        onMouseLeave={() => this.toggleTelegramMenu(false)}
                      >
                        <div className="telegram-button">
                          <i className="fab fa-telegram-plane" />
                        </div>
                        {telegramMenu && (
                          <ul className="telegram-list">
                            {header.telegram_bots.map(bot => (
                              <li
                                key={`telegram-bot-item${bot.id}`}
                                role="presentation"
                              >
                                <a href={
                                  bot.telegram_bot_localizations[0]
                                  && bot.telegram_bot_localizations[0].link
                                }
                                >
                                  {bot.telegram_bot_localizations[0]
                                  && bot.telegram_bot_localizations[0].name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}

                    <Social socials={header} />

                    <ul
                      className={langMenu ? 'language-menu opened' : 'language-menu'}
                      style={{
                        height: langMenu
                          ? `${languages.filter(el => el.is_active).length * itemHeight}px`
                          : `${itemHeight}px`,
                      }}
                      onMouseEnter={() => this.toggleLangMenu(true)}
                      onMouseLeave={() => this.toggleLangMenu(false)}
                    >
                      {languages.filter(el => el.is_active).map((lang, i) => (
                        <li
                          key={`languages-menu-item-${lang.id}`}
                          className="language-menu_item"
                          style={{
                            transform: langMenu ? `translateY(${`${i * 100}%`})` : 'translateY(0)',
                            borderTop: i ? '1px solid #f6f6f6' : '',
                            zIndex: languages.length - i + 1000,
                          }}
                          onClick={() => this.setLanguage(i, lang.locale)}
                          role="presentation"
                        >
                          <img
                            src={lang.icon && (lang.icon.resize || lang.icon.href)}
                            className="language-menu-icon"
                            alt=""
                          />
                          <span className="lm_item_text">
                            {lang.locale.toUpperCase()}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="search-block">
                    <input
                      onKeyDown={this.onKeyDownHandler}
                      ref={this.searchRef}
                      type="search"
                    />
                    <button
                      type="button"
                      className="search-submit"
                      onClick={this.setSearch}
                    >
                      <Link to="search" />
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="header-nav"
                onMouseLeave={this.hideSubNav}
              >
                <ul className="nav-top">
                  {menu.map((item, i) => {
                    return withParent(menu, item) !== '/empty' ? <li key={`nav-item-${item.id}`}>
                      <Link
                          to={withParent(menu, item)}
                          onMouseEnter={() => this.navEnter(i)}
                          onMouseLeave={this.hideSubNav}
                      >
                        {!!item.menu_localizations.length
                        && item.menu_localizations[0].title}
                      </Link>
                    </li> :
                            <li key={`nav-item-${item.id}`} className="no-underline">
                              <Link
                                 onMouseEnter={() => this.navEnter(i)}
                                 onMouseLeave={this.hideSubNav}
                              >
                                {!!item.menu_localizations.length
                                && item.menu_localizations[0].title}
                              </Link>
                            </li>
                  })}
                </ul>
                {(subNav && !!children.length) && (
                  <ul className="nav-bot">
                    {children
                        .sort((a,b) => a.priority - b.priority)
                        .map(item => {
                      return !!item.menu_localizations.length
                          && item.menu_localizations[0].title
                          && <li
                            key={`nav-sub-item-${item.id}`}
                            className="nav-sub-item"
                            >
                                <Link to={withParent(menu, item)}>
                                  {item.menu_localizations[0].title}
                                </Link>
                            </li>
                        }
                    )}
                  </ul>
                )}
              </div>
            </div>
          ) : (
            <MobileHeader
              localization={localization}
              content={content}
            />
          )}
          <ScrollUpButton
                  ContainerClassName="ScrollUpButtonContainer"
                  TransitionClassName="ScrollUpButtonForTransition"
                  ShowAtPosition={250}
          >
            <div>
              <div class="arrow"></div>
            </div>
          </ScrollUpButton>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  initGlobalSearch: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  localization: PropTypes.objectOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  settings: state.general.settings,
  translations: state.translations,
});

const mapDispatchToProps = dispatch => ({
  changeLanguage: (lan) => {
    localStorage.setItem('language', lan);
    dispatch(switchLanguage(lan));
  },
  initGlobalSearch: search => dispatch(initSearch(search)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
