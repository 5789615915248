/* eslint-disable camelcase */

import React from "react";

export const generateURL = (item) => {
  // console.log(item);
  let URL = '';
  try {
    if (item.type === 3) {
      const { page } = item;
      URL = `${page.alias}`;
      // URL = `page/${page.id}`;
      URL = URL || `/${item.url}`;
    } else {
      URL = item.url;
    }
    return `/${URL}`;
  } catch (e) {
    return '';
  }
};

export const generateSubURL = (item) => {
  let URL = '';
  if (item.type === 3) {
    const { id } = item;
    URL = `${item.page.alias || item.url}`;
    // URL = `page/${id}`;
  } else {
    URL = item.url;
  }
  return `/${URL}`;
};

export const withParent = (arr, route) => {
  if (route.parent_id) {
    const parent = arr.find(item => item.id === route.parent_id);
    return withParent(arr, parent) + generateURL(route);
  }
  // console.log(generateURL(route));
  return generateURL(route);
};

export const parseRoutes = arr => [].concat(...arr.map(item => (item.children.length
  ? [].concat(...[item, [].concat(...parseRoutes(item.children))]) : item)));

export const formatPublishDate = (date) => {
  const d = new Date(date);
  let hh = d.getHours();
  let dd = d.getDate();
  let mm = d.getMonth() + 1;
  const yy = d.getFullYear();
  let nn = d.getMinutes();
  let ss = d.getSeconds();

  if (dd.toString().length < 2) {
    dd = `0${dd}`;
  }
  if (mm.toString().length < 2) {
    mm = `0${mm}`;
  }
  if (hh.toString().length < 2) {
    hh = `0${hh}`;
  }
  if (nn.toString().length < 2) {
    nn = `0${nn}`;
  }
  if (ss.toString().length < 2) {
    ss = `0${ss}`;
  }

  return `${yy}-${mm}-${dd} ${hh}:${nn}:${ss}`;
};

export const formatDateString = (date) => {
  const d = new Date(date);
  let hh = d.getHours();
  let dd = d.getDate();
  let mm = d.getMonth() + 1;
  const yy = d.getFullYear();
  let nn = d.getMinutes();

  if (dd.toString().length < 2) {
    dd = `0${dd}`;
  }
  if (mm.toString().length < 2) {
    mm = `0${mm}`;
  }
  if (hh.toString().length < 2) {
    hh = `0${hh}`;
  }
  if (nn.toString().length < 2) {
    nn = `0${nn}`;
  }

  return {
    hhmm: `${hh}:${nn}`,
    ddmmyyyy: `${dd}.${mm}.${yy}`,
  };
};

export const clearDate = (date) => {
  const time = new Date(date);
  let dd = time.getDate();
  let mm = time.getMonth() + 1;
  const yy = time.getFullYear();

  dd = dd < 10 ? `0${dd}` : dd;
  mm = mm < 10 ? `0${mm}` : mm;

  return `${dd}.${mm}.${yy}`;
};

export const clearDate2 = (date) => {
  const time = new Date(date);
  let dd = time.getDate();
  let mm = time.getMonth() + 1;
  const yy = time.getFullYear();

  dd = dd < 10 ? `0${dd}` : dd;
  mm = mm < 10 ? `0${mm}` : mm;

  // return `${dd}.${mm}.${yy}`;
  return `${yy}-${mm}-${dd}`;
};

// убирает обрамляющие теги HTML
export const clearPageDescription = str => str
  .replace(/&nbsp;/g, ' ')
  .replace(/(&lt;iframe\s.*?&lt;\/iframe&gt;)/g, '')
  .replace(/(&lt;gallery\stitle.*?&lt;\/gallery&gt;)/g, '')
  .replace(/(&lt;files\stitle.*?&lt;\/files&gt;)/g, '')
  .replace(/(&lt;iblocks\stitle.*?&lt;\/iblocks&gt;)/g, '')
  .replace(/((<img>(.*?)<\/img>)|(<.*?>)|({{(.*?)}}))/gi, '')
  .replace(/(?:^(?:&nbsp;)+)|(?:(?:&nbsp;)+$)/g, '');

// export const arrayToObject = array => array.reduce((obj, item) => {
//   obj[item.origin.system_name] = item.translation;
//   return obj;
// }, {});

export const arrayToObject = array => array.reduce((obj, item) => {
  obj[item.origin.system_name] = clearPageDescription(item.translation);
  return obj;
}, {});

export const addLoadPlaceholders = str => str.replace(/{{.*?}}/gi, (match) => {
  let placeholder = '';
  const clear = match.replace(/}/gi, '').replace(/{/gi, '');
  const [type, id] = clear.split('=');
  if (type === 'galleries') {
    if (id.match(/\[/gi)) {
      const clearID = id.replace(/\[/gi, '').replace(/]/gi, '');
      const arrID = clearID.split(',').map(itemId => (`ids[]=${itemId}`)).join('&');
      placeholder = `<div class="placeholder" id="${type}-?${arrID}"></div>`;
    } else {
      placeholder = `<div class="placeholder" id="${type}-${id}"></div>`;
    }
  }
  return placeholder;
});

export const addMedia = str => str.replace(/<oembed(.*?)\/oembed>/gi, (match) => {
  const url = match.match(/url="(.*?)"/gi)[0];
  if (url) {
    const startStr = url.indexOf('http');
    const clearURL = url.slice(startStr).replace(/"/gi, '');
    return (
      `
        <div 
          data-oembed-url="${clearURL}"
          class="iframe-container"
        >
          <iframe src="${clearURL}"></iframe>
        </div>
      `
    );
  }
  return '';
});

// парсит контент страницы на предмет галереи картинок или файлов,
// вытаскивает их в отдельный объект с атрибутами
export const getDescriptionWidgets = (str) => {
  let result = [];
  str
    .split(/(&lt;gallery\stitle.*?&lt;\/gallery&gt;)|(&lt;files\stitle.*?&lt;\/files&gt;)|(&lt;iframe\s.*?&lt;\/iframe&gt;)|(&lt;iblocks\s.*?&lt;\/iblocks&gt;)/g)
    .map(item => {
    const media = [];
    const isGallery = !!item && item.match(/(&lt;gallery\stitle.*?&lt;\/gallery&gt;)/g);
    const isFiles = !!item && item.match(/(&lt;files\stitle.*?&lt;\/files&gt;)/g);
    const isIframe = !!item && item.match(/(&lt;iframe\s.*?&lt;\/iframe&gt;)/g);
    const isIblocks = !!item && item.match(/(&lt;iblocks\s.*?&lt;\/iblocks&gt;)/g);
    if(!!isGallery){
      const titleMatch = item.match(/&lt;gallery\stitle="(.*?)"/);
      const matches = item.matchAll(/<\s*figure[^>]*>.*?src="(.*?)".*?(.(alt="(.*?)"))?.*?(.(<figcaption>(.*?)<\/figcaption))?.*?<\s*\/\s*figure>/g);
      if (!!matches) {
        for (const match of matches) {
          const [m, src, alt1, alt2, title, desc, desc2, description] = match;
          media.push({
            src        : src,
            title      : !!title ? title : '',
            description: description ? description : '',
          });
        }
        result.push({
          type : 'media',
          media: media,
          title: !!titleMatch ? titleMatch[1] : '',
        });
      }
    } else if (!!isFiles) {
      const titleMatch = item.match(/&lt;files\stitle="(.*?)"/);
      const matches = item.matchAll(/<\s*a\s*href="(.*?)".*?>(.*?)<\/a>/g);
      if (!!matches) {
        for (const match of matches) {
          const [m, href, description] = match;
          media.push({
            href,
            description: !!description ? description : '',
          });
        }
        result.push({
          type : 'files',
          media: media,
          title: !!titleMatch ? titleMatch[1] : '',
        });
      }
    } else if (!!isIframe) {
      const iframeMatch = item.match(/&lt;iframe\s(.*?)&gt;(.*?)&lt;\/iframe&gt;/);
      if (!!iframeMatch) {
          const [m, iframe, itext] = iframeMatch;
          media.push({
            iframe,
            itext,
          });
        }
        result.push({
          type : 'iframe',
          media: media,
          // title: !!titleMatch ? titleMatch[1] : '',
        });
    } else if (!!isIblocks) {
      const titleMatch = item.match(/&lt;iblocks\stitle="(.*?)"/);
      const matches = item.matchAll(/&lt;iblock\simg="(.*?)"\s*link="(.*?)"\s*caption="(.*?)".*?\/&gt;/g);
      for (const match of matches) {
        const [m, img, link, caption] = match;
        media.push({
          img,
          link,
          caption,
        });
      }
      result.push({
        type : 'iblocks',
        media: media,
        title: !!titleMatch ? titleMatch[1] : '',
      });
    } else if (!!item) {
          result.push({
          type: 'text',
          text: item,
      });
    }
  });
  return result;
};

export const getTranslation = (name, translations) => {
  const translationObj = translations.find(item => name === item.origin.system_name);
  return translationObj ? translationObj.translation : '';
};
