const aesStateUA = {
  data_text: 'Дані за:',
  data_week: 'Тиждень',
  data_month: 'Місяць',
  data_year: 'Рік',
  // khmelnytskyi_npp: 'Хмельницька АЕС',
  // rivne_npp: 'Рівенська АЕС',
  // south_ukraine_npp: 'Южноукраїнська АЕС',
  // zaporizhia_npp: 'Запорізька АЕС',
  // radiation: 'Радіаційний фон',
  // radiation_about: 'Радіаційний фон на промисловому майданчику мкР/годину',
  // block: 'Блок',

  // title_regulation: 'Національні НД щодо аналізу подій на АЕС',
  // regulation: 'Положення про порядок розслідування та обліку порушень в роботі атомних станцій'
};

export default aesStateUA;
