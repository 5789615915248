import React, { useCallback } from 'react';
import LinkWrapper from '../util/LinkWrapper';

const SideNewsBlock = ({ item }) => {

  const iframeRef = useCallback(node => {
    if (node !== null) {
      node.innerHTML = item.iframe;
    }
  }, []);

  if (!item.iframe) {
    return (
            <LinkWrapper link={item.link}>
              <div className="side-news-item">
                <img
                        className="side-news-image"
                        src={item.image && (item.image.resize || item.image.href)}
                        alt=""
                />
                {item.signature &&
                <div className="side-text_wr">
                  {item.signature}
                </div>}
              </div>
            </LinkWrapper>
    );
  } else {
    return (
            <LinkWrapper link={item.link}>
              <div className="side-news-item iframe-container" ref={iframeRef}>
              </div>
            </LinkWrapper>
    );
  }
};

export default SideNewsBlock;
