import {clearPageDescription, getTranslation} from "../../services/main";
import {connect} from "react-redux";
import React from "react";

const routes = {
    '/books': 'book-page',
    '/contacts': 'contacts-page',
    '/news' :  'news-page'
};

const DynamicBreadcrumb = ({ match, translations }) => (
    <span>{clearPageDescription(getTranslation(routes[match.url], translations))}</span>
);

const mapStateToProps = ({ translations }) => ({
    translations: translations
});

export default connect(mapStateToProps)(DynamicBreadcrumb);