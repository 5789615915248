export const CORE_URL = process.env.NODE_ENV !== 'development' ? 'https://backend.sstc.ua/api/v1' : '';
export const GET_ORG_STRUCTURE = `${CORE_URL}/nodes`;
export const GET_BOOK_SERIES = `${CORE_URL}/book-series`;
export const GET_BOOK_BY_ID = `${CORE_URL}/books`;
export const GET_BOOK_REVIEW_BY_ID = `${CORE_URL}/book-reviews`;
export const GET_FILTERED_BOOKS = `${CORE_URL}/books`;
export const GET_ALL_TRANSLATIONS = `${CORE_URL}/system-translations/`;
export const GET_PARTNER_TYPES = `${CORE_URL}/partner-types`;
export const GET_BOOKS_TAGS = `${CORE_URL}/tags`;
export const GET_EMPLOYEES = `${CORE_URL}/employees`;
export const GET_DEPARTMENTS = `${CORE_URL}/departments`;
export const POST_FEEDBACK = `${CORE_URL}/book-reviews`;
export const POST_BUY_BOOK = `${CORE_URL}/book-orders`;

export const BASE_URL = 'https://www.sstc.ua';
