/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from 'react-router-dom';

import '../assets/styles/services.scss';
import LinkWrapper from "./util/LinkWrapper"

const Services = ({ information }) => {
  const validInfo = information
    .filter(el => el.is_active)
    .filter(el => el.information_block_localizations[0])
    .sort((a, b) => a.priority - b.priority);

  const maxInfo = validInfo.length >= 6 ? validInfo.slice(0, 6) : validInfo;
  return (
    <div className="row App-service">
      {maxInfo
        .map(({ information_block_localizations: item, id }) => (
          <div key={`services-item-${id}`} className="col-xl-2 col-lg-4 col-md-6 services-item_wrapper">
            <LinkWrapper link={`${item[0].link}`} className="services-item">
              <div className="services-image_wrapper">
                <img
                  className="services-image"
                  src={item[0].image && (item[0].image.href || item[0].image.href)}
                  alt=""
                />
              </div>
              <div className="services-text">
                {item[0].title.toUpperCase()}
              </div>
            </LinkWrapper>
          </div>
        ))}
    </div>
  );
};


export default Services;
