const aesStateRU = {
  data_text: 'Данные за:',
  data_week: 'Неделя',
  data_month: 'Месяц',
  data_year: 'Год',
  khmelnytskyi_npp: 'Хмельницкая АЭС',
  rivne_npp: 'Ровенская АЭС',
  south_ukraine_npp: 'Южноукраинская АЭС',
  zaporizhia_npp: 'Запорожская АЭС',
  radiation: 'Радиационный фон',
  radiation_about: 'Радиационный фон на промышленной площадке мкР/час',
  block: 'Блок',
  title_regulation: 'Национальные ВС по анализу событий на АЭС',
  regulation: 'Положение о порядке расследования и учета нарушений в работе атомных станций'

};

export default aesStateRU;
