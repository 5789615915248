const servicesContentUA = [
  { text: 'Послуги', url: '/', img: '/images/mask.png' },
  { text: 'Платформа', url: '/', img: '/images/mask.png' },
  { text: 'Керування сертифікатами', url: '', img: '/images/mask.png' },
  { text: 'Журнали', url: '/', img: '/images/icon_3.png' },
  { text: 'Стан АЕС', url: '/aes-state', img: '/images/icon_4.png' },
  { text: 'База НПА України та МАГАТЕ', url: '/', img: '/images/group-2-copy.png' },
];

export default servicesContentUA;
