const getAllNodes = (mainNode) => {
  const allNodes = [];

  const getNodes = (node, arrNodes) => {
    const { children, ...rest } = node;
    const childrenUpd = children.map((el) => {
      const { children, ...rest } = el;
      return { isChildren: !!children.length, ...rest };
    });

    const nodeUpd = { children: childrenUpd, ...rest };

    arrNodes.push(nodeUpd);

    if (children.length) {
      children.forEach((child) => {
        getNodes(child, arrNodes);
      });
    }
  };

  getNodes(mainNode, allNodes);
  return allNodes;
};

export default getAllNodes;
