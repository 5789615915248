import { combineReducers } from 'redux';
import general from './general';
import news from './news';
import i18n from './i18n';
import orgStructure from './org_structure';
import translations from './translations';

export default combineReducers({
  general,
  news,
  i18n,
  orgStructure,
  translations,
});
