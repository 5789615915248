import { ORG_STRUCTURE_DOWNLOADED } from '../constants/redux';
import { GET_ORG_STRUCTURE } from '../constants/urls';

import fetchOne from '../services/fetch';

export function structureDownloaded(structure) {
  return { type: ORG_STRUCTURE_DOWNLOADED, structure };
}

export function fetchStructure() {
  return async function action(dispatch) {
    const data = await fetchOne({ url: GET_ORG_STRUCTURE });
    dispatch(structureDownloaded(data));
  };
}
