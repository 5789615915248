import React, { Component } from 'react';

import '../../assets/styles/accordionFilters.scss';
import Arrow from '../../assets/svg/arrow-grey.svg';

class AccordionFilters extends Component {
  state = {
    open: false,
  };

  toggle = () => {
    const { open } = this.state;
    const { close } = this.props;
    this.setState({ open: !open });
    if (close && open) close();
  };

  close = () => {
    const { open } = this.state;
    const { close } = this.props;
    this.setState({ open: false });
    if (close && open) close();
  };

  render() {
    const { open } = this.state;
    const { header, button, body } = this.props;
    return (
      <React.Fragment>
        {open && (
          <div
            className="overflow"
            onClick={this.close}
            role="presentation"
          />
        )}
        <div className={`accordion-wrapper${open ? ' opened' : ''}`}>
          <div className="accordion-head">
            <div
              className="accordion-button"
              onClick={this.toggle}
              role="presentation"
            >
              {button}
              <img src={Arrow} alt="" />
            </div>
            {header}
          </div>
          {open && (
            <div className="accordion-body">
              {body}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default AccordionFilters;
