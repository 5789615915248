import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import '../assets/styles/about.scss';
import '../assets/styles/pages/affiliate.scss';

import { Link } from 'react-router-dom';
import Breadcrumbs from '../components/util/Breadcrumbs';
import ContentBlock from '../components/ContentBlock';
import Phone2 from '../assets/svg/phone2.svg';
import Phone from '../assets/svg/phone.svg';
import Location from '../assets/svg/location.svg';
import Email from '../assets/svg/email.svg';

class Affiliate extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <Helmet>
            <title>Харківська філія</title>
          </Helmet>
          <div className="row no-gutter">
            <div className="col-xl-12 breadcrumb-wrapper">
              <Breadcrumbs />
            </div>
          </div>
          <ContentBlock>
            <div className="news-item-wrapper">
              <div className="affiliate-mediate-name">
                Харківська філія
              </div>
              <div className="affiliate-address-director">
                <div className="affiliate-office-contact">
                  <div className="contact-orgstr-addrr-block">
                    <div className="orgstr-addrr-header">
                      <Link to="/url-category-test/kharkiv">
                        <span>main</span>
                      </Link>
                    </div>
                    <div className="orgstr-addrr-addrr-photo">
                      <img
                        src={Phone2}
                        alt=""
                        className="orgstr-addrr-office-img orgstr-right-indent"
                      />
                      <div className="orgstr-addrr-point">
                        <div className="mini-img orgstr-right-indent">
                          <img src={Location} alt="" />
                        </div>
                        <div className="office-address" onClick={() => this.toggleFormMap(true)}>
                          address here
                        </div>
                      </div>
                    </div>

                    <div className="orgstr-addrr-contacts">
                      <div className="orgstr-addrr-point-left orgstr-right-indent">
                        <div className="orgstr-addrr-point">
                          <div className="mini-img orgstr-right-indent">
                            <img src={Phone2} alt="" />
                          </div>
                          <div className="tel-numbers orgstr-right-indent">
                            59689675876
                          </div>
                        </div>
                      </div>
                      <div className="orgstr-addrr-point-right">
                        <div className="orgstr-addrr-point">
                          <div className="mini-img orgstr-right-indent">
                            <img src={Phone} alt="" />
                          </div>
                          <div className="tel-numbers orgstr-right-indent">
                            507806750o67
                          </div>
                        </div>
                        <div className="orgstr-addrr-point">
                          <div className="mini-img orgstr-right-indent">
                            <img src={Email} alt="" />
                          </div>
                          <div className="tel-numbers orgstr-right-indent">
                            lghdkfjg;kfj
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="affiliate-director-contact">
                  <div className="affiliate-common-header">
                      Директор філії
                  </div>
                  <div className="affiliate-boss-identity">
                    <div className="boss-name-block">
                      <img className="affiliate-boss-image" src="/images/affiliate_director.png" alt="" />
                      <span>Трубчанінов Сергій Олександрович</span>
                    </div>
                  </div>
                  <div className="affiliate-street-spot">
                    <div className="aff-map-point-icon">
                      <img src={Location} alt="" />
                    </div>
                    <span>
                          вул. Чернишевська, 53
                          61002, Харків
                    </span>
                  </div>
                  <div className="telephone-numbers">
                    <div>
                      <img src={Phone2} alt="" />
                    </div>
                    <div className="tel-numbers">
                      <p>(057) 714-93-36</p>
                    </div>
                  </div>

                  <div className="telephone-numbers">
                    <div>
                      <img src={Email} alt="" />
                    </div>
                    <div className="tel-numbers">
                      <p>s.a.trubchaninov@gmail.com</p>
                      <p>sstc_kharkov@ukr.net</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="organization-explain-block">
                <div className="affiliate-common-header">
                  Організаційна структура
                </div>

                <div className="one-indentation">
                  <div className="structure-row">
                    <div className="arrow-right-black" />
                    <span>директор філії;</span>
                  </div>
                  <div className="structure-row">
                    <div className="arrow-right-black" />
                    <span>відділ аналізу безпеки керуючих та інформаційних систем АЕС;</span>
                  </div>
                </div>

                <div className="two-indentation">
                  <div className="structure-row">
                    <div className="arrow-right-black" />
                    <span>лабораторія аналізу безпеки керуючих систем;</span>
                  </div>
                  <div className="structure-row">
                    <div className="arrow-right-black" />
                    <span>лабораторія аналізу безпеки інформаційних систем.</span>
                  </div>
                </div>

                <div className="organization-security-block">
                  <div className="affiliate-common-header">
                    Відділ аналізу безпеки керуючих та інформаційних систем АЕС
                  </div>
                  <div className="affiliate-security-director">
                    <img className="" src="/images/security_director.png" alt="" />
                    <div className="security-boss-name-block">
                      <div className="security-boss-name-header">
                        Клевцов Олександр Леонідович
                      </div>
                      <p>Начальник відділу аналізу безпеки керуючих та інформаційних систем АЕС,</p>
                      <p>кандидат технічних наук</p>
                      <p>Тел.: (057) 714-93-36</p>
                      <p>E-mail: klevtsov.alex@gmail.com</p>
                    </div>
                  </div>

                  <p>
                    Мета діяльності відділу — науково-технічна підтримка діяльності Держатомрегулювання у
                    забезпеченні безпеки АЕС з питань, що стосуються інформаційно-керуючих систем (ІКС) та їх складових частин.
                  </p>

                  <div className="affiliate-common-header security-contact-top-intend">
                    Контактні телефони та електронні адреси
                  </div>

                  <div className="security-boss-name-header">
                    Розен Юрій Володимирович
                  </div>
                  <p>Начальник лабораторії аналізу безпеки керуючих систем</p>
                  <p>Тел.: (057) 719-36-08 </p>
                  <p>E-mail: y.v.rozen@gmail.com </p>

                  <div className="affiliate-common-header security-contact-top-intend">
                    Контактні телефони та електронні адреси
                  </div>

                  <div className="department-main-direction">
                    Основні напрями діяльності відділу:
                  </div>
                  <div className="one-indentation">
                    <div className="structure-row">
                      <div className="arrow-right-black" />
                      <span> виконання експертних робіт з оцінки ядерної та радіаційної безпеки ІКС, важливих для безпеки АЕС;</span>
                    </div>
                    <div className="structure-row">
                      <div className="arrow-right-black" />
                      <span>розробка норм і правил ядерної та радіаційної безпеки, а також нормативних документів з ІКС та їх компонентів;</span>
                    </div>
                    <div className="structure-row">
                      <div className="arrow-right-black" />
                      <span>проведення науково-дослідних робіт, спрямованих на забезпечення функціональної безпеки ІКС;</span>
                    </div>
                    <div className="structure-row">
                      <div className="arrow-right-black" />
                      <span>проведення аналітичних досліджень у галузі комп'ютерної безпеки ІКС ядерних установок;</span>
                    </div>
                    <div className="structure-row">
                      <div className="arrow-right-black" />
                      <span>участь у міжнародному науково-технічному співробітництві у сфері ІКС АЕС.</span>
                    </div>
                  </div>

                  <br />
                  <div className="department-main-direction">
                    Завдання відділу:
                  </div>
                  <div className="one-indentation">
                    <div className="structure-row">
                      <div className="arrow-right-black" />
                      <span> оцінка ядерної та радіаційної безпеки модернізованих ІКС АЕС;</span>
                    </div>
                    <div className="structure-row">
                      <div className="arrow-right-black" />
                      <span>оцінка ядерної та радіаційної безпеки нових технічних і програмних засобів автоматизації для ядерних установок;</span>
                    </div>
                    <div className="structure-row">
                      <div className="arrow-right-black" />
                      <span>оцінка технічних рішень АЕС про продовження ресурсу засобів автоматизації;</span>
                    </div>
                    <div className="structure-row">
                      <div className="arrow-right-black" />
                      <span>розробка нормативних документів, що стосуються ІКС АЕС, принципів і підходів до проведення експертиз ІКС АЕС, технічних і програмних засобів автоматизації;</span>
                    </div>
                    <div className="structure-row">
                      <div className="arrow-right-black" />
                      <span>розробка нормативних документів з розробки методів оцінки надійності ІКС АЕС і продовження ресурсу засобів автоматизації;</span>
                    </div>
                    <div className="structure-row">
                      <div className="arrow-right-black" />
                      <span>розробка пропозицій в частині регулюючих вимог до комп'ютерної безпеки ІКС ядерних установок.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ContentBlock>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  i18n: state.i18n,
});

export default connect(mapStateToProps)(Affiliate);
