import React from 'react';
import {CORE_URL} from "../../constants/urls";

const BossStructureItem = ({
  name, positionSchema, onClickFunction,
  bossImg, link, department, position, subSchema = null,
}) => (
  <div className="structure-item" onClick={onClickFunction}>
    <div className="boss_face">
      <img className="" src={bossImg} alt="" />
    </div>
    <div className="boss-fio">
        {!!link ? <a href={link}>{name}</a> : name}
    </div>

      {!!positionSchema && (
          <div className="sub-boss-position-row">
              <div className="arrow-right" />
              <span>{positionSchema}</span>
          </div>
      )}

      {/*{!!department && (*/}
      {/*    <div className="sub-boss-position-row">*/}
      {/*        <div className="arrow-right" />*/}
      {/*        <span>{department}</span>*/}
      {/*    </div>*/}
      {/*)}*/}

      {!!position && (
          <div className="sub-boss-position-row">
              <div className="arrow-right" />
              <span>{position}</span>
          </div>
      )}

    {/*{subSchema ? (*/}
    {/*  <div className="sub-boss-position-row">*/}
    {/*    <div className="arrow-right" />*/}
    {/*    <span>{subSchema}</span>*/}
    {/*  </div>*/}
    {/*) : ''}*/}
  </div>
);

export default BossStructureItem;
