import React, { useRef, useEffect } from 'react';

const IframeContainer = ({ iframe, itext }) => {
  const ifr = useRef(null);

  useEffect(() => {
    const i = `<iframe ${iframe}>${itext}</iframe>`;
    ifr.current.innerHTML = i;
  }, []);
  return (
      <div className="page-iframe" ref={ifr}></div>
  );
};

export default IframeContainer;
