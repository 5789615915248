import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './App.scss';

import Header from './views/layout/Header';
import Main from './views/layout/Main';
import Modal from './views/layout/Modal';
import Footer from './views/layout/Footer';

const App = (props) => {
  const {
    i18n, modal, routes,
    localization, content,
  } = props;
  const { open, type } = modal;
  return (
    <React.Fragment>
      <div className="App">
        <Header
          localization={localization}
          links={i18n.links}
          content={content}
        />
        <Main routes={routes} />
        {open && (<Modal type={type} />)}
      </div>
      <Footer
        confidential={i18n.confidential}
        content={content}
      />
    </React.Fragment>
  );
};

App.propTypes = {
  localization: PropTypes.objectOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  routes: PropTypes.arrayOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  modal: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  localization: state.general.localization,
  content: state.general.content,
  routes: state.general.routes,
  modal: state.general.modal,
  i18n: state.i18n,
});

export default withRouter(connect(mapStateToProps)(App));
