export const RECEIVE_NEWS = 'RECEIVE_NEWS';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const ORG_STRUCTURE_DOWNLOADED = 'ORG_STRUCTURE_DOWNLOADED';
export const INIT_APP = 'INIT_APP';
export const INIT_SEARCH = 'INIT_SEARCH';
export const INIT_LANGUAGE = 'INIT_LANGUAGE';
export const BOOK_SERIES_DOWNLOADED = 'BOOK_SERIES_DOWNLOADED';
export const TRANSLATIONS_DOWNLOADED = 'TRANSLATIONS_DOWNLOADED';
export const BOOK_FILTERED_DOWNLOADED = 'BOOK_FILTERED_DOWNLOADED';
export const EMPLOYEE = 'employee';
export const DEPARTMENT = 'department';
