const bigHeadersContentUA = {
    propositions: 'Наші послуги',
    partners: 'Наші партнери',
    about_about: 'Про нас',
    about_history: 'Історія',
    about_partners: 'Партнери (Україна і світ)',
    about_nodes: 'Організаційна структура',
    about_nodes_leadership: 'Керівництво'
};

export default bigHeadersContentUA;
