/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

const LinkWrapper = ({ link, children, className, style }) => {
  if (!link) {
    return (
      <div className="off-pointer">
        {children}
      </div>
    );
  }
  if (link.match(/http/gi)) {
    return (
      <a href={link} className={className}>
        {children}
      </a>
    );
  }
  return (
    <Link to={link} className={className}>
      {children}
    </Link>
  );
};

export default LinkWrapper;
