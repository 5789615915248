/* eslint-disable react/no-array-index-key,react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

import '../assets/styles/pages/home.scss';

import { CORE_URL } from '../constants/urls';
import fetchOne from '../services/fetch';

import Page from '../modules/views/Page';
import ContentBlock from '../components/ContentBlock';

import Services from '../components/Services';
import SliderMain from '../components/SliderMain';
import FluidGrid from '../components/FluidGrid';
import Propositions from '../components/Propositions';
import {clearPageDescription, getTranslation} from "../services/main";

class Home extends React.Component {
  state = {
    infoBottom: [],
    infoTop: [],
    lastNews: {},
    banners: [],

    loading: true,
    error: false,
  };

  componentDidMount() {
    this.getHomePageContent();
  }

  getHomePageContent = async () => {
    try {
      // const date = formatPublishDate(new Date());
      const { settings } = this.props;
      const limit = settings.news_amount_on_main_page;
      // const newsLink = `/news/last/news?is_active=true`;
      const newsLink = `/news/last/news?isActive=true&limit=${limit}`;

      const links = ['/banners', '/information-blocks', newsLink];
      const [banners, information, lastNews] = await Promise
        .all(links.map(link => fetchOne({ url: `${CORE_URL}${link}` })));

      const infoBlocks = information
        .reduce((prev, item) => ({
          ...prev,
          [item.zone]: prev[item.zone]
            ? [...prev[item.zone], item]
            : [item],
        }), {});

      const [infoTop, ...infoBottom] = Object.values(infoBlocks);
      this.setState({
        infoBottom,
        infoTop,
        lastNews,
        banners,
        loading: false,
      });
    } catch (err) {
      this.setState({ error: true });
    }
  };

  render() {
    const {
      loading, error,
      banners, lastNews,
      infoTop, infoBottom,
    } = this.state;
    const { settings, translations } = this.props;

    return (
      <Page
        error={error}
        loading={loading}
        title={clearPageDescription(getTranslation('home-page', translations))}
        languageChange={this.getHomePageContent}
      >
        {banners.length && banners.find(b => b.banner_localizations.length) ?
          <div className="slider-carousel main-slider">
            <SliderMain
              banners={banners.filter(b => b.banner_localizations.length)}
              speed={settings.banner_rotation_delay}
            />
          </div> : ''
        }
        <div className="container home-page">
          <Services information={infoTop} />
          <hr />
          <ContentBlock>
            <FluidGrid news={lastNews} />
            <hr className="cutted-hr" />
            {infoBottom.map((info, index) => (
              <React.Fragment key={`info-block-${index}`}>
                <Propositions
                  information={info}
                  index={index}
                />
                {(infoBottom.length - 1) > index && (<hr className="cutted-hr" />)}
              </React.Fragment>
            ))}
          </ContentBlock>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ general, translations }) => ({
  settings: general.settings,
  translations: translations,
});

export default connect(mapStateToProps)(Home);
