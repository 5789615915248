import {
  INIT_LANGUAGE,
  TOGGLE_MODAL,
  INIT_SEARCH,
  INIT_APP,
  TRANSLATIONS_DOWNLOADED,
} from '../constants/redux';
import { parseRoutes } from '../services/main';
import {
  GET_ALL_TRANSLATIONS,
  CORE_URL,
} from '../constants/urls';
import fetchOne from '../services/fetch';

export const toggleModal = (data, title, type) => ({
  type: TOGGLE_MODAL,
  value: { data, title, type },
});

const getTranslations = async (id) => {
  const data = await fetch(`${GET_ALL_TRANSLATIONS + id}/translations`)
    .then(response => response.json())
    .then(json => json)
    .catch(e => e);
  return data;
};

export function initApp() {
  return async function action(dispatch) {
    const links = [
      '/header-settings',
      '/footer-settings',
      '/menus?parent=true',
      '/advertising',
      '/settings',
    ];

    const [header, footer, menu, advertising, settings] = await Promise
      .all(links.map(link => fetchOne({ url: `${CORE_URL}${link}` })));
    const sorted = menu.sort((prev, next) => prev.priority - next.priority);

    dispatch({
      type: INIT_APP,
      data: {
        advertising,
        header,
        footer,
        settings,
        menu: sorted,
        routes: parseRoutes(menu),
      },
    });
  };
}


export function initLocalization() {
  return async function action(dispatch) {
    const languages = await fetchOne({ url: `${CORE_URL}/languages` });

    const stored = localStorage.getItem('language');
    const local = stored || navigator.language;

    const hasContent = languages.find(lang => local.match(lang.locale));
    const language = hasContent || languages[0];

    localStorage.setItem('language', language.locale);
    localStorage.setItem('language_id', language.id);
    dispatch({ type: INIT_LANGUAGE, data: { languages, language } });

    getTranslations(language.id).then((translations) => {
      dispatch({ type: TRANSLATIONS_DOWNLOADED, translations });
      dispatch(initApp());
    });
  };
}

export const initSearch = search => ({ type: INIT_SEARCH, value: search });
