import { ORG_STRUCTURE_DOWNLOADED } from '../constants/redux';

const orgStructure = (state = {}, action) => {
  switch (action.type) {
    case ORG_STRUCTURE_DOWNLOADED:
      return action.structure;
    default:
      return state;
  }
};

export default orgStructure;
