/* eslint-disable react/no-array-index-key,camelcase */
import React from 'react';

import '../assets/styles/news.scss';

import BigNewsBlock from './news/BigNewsBlock';
import SmallNewsBlock from './news/SmallNewsBlock';
import SliderSecondary from './SliderSecondary';

class FluidGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      news: [],
      mobile: false,
    };
  }

  componentDidMount() {
    this.observeScreenWidth();
    window.addEventListener('resize', this.observeScreenWidth);
  }

  static getDerivedStateFromProps(props) {
    let { news } = props;
    news = news.collection;
    if (!news || !news.length) return null;

    news = news
      .filter(({ is_active, news_localizations }) => is_active && news_localizations.length)
      .sort((a, b) => {
        const prev = a.published_at || a.created_at;
        const next = b.published_at || b.created_at;
        const prevDate = new Date(prev);
        const nextDate = new Date(next);
        return -(prevDate - nextDate);
      });

    if (news.length >= 6) {
      news = news.map((el, index) => (index <= 1 ? ({ ...el, type: 'big' }) : el));
    } else if (news.length === 5) {
      news = news.map((el, index) => (index <= 2 ? ({ ...el, type: 'big' }) : el));
    } else {
      news = news.map(el => ({ ...el, type: 'big' }));
    }

    return { news };
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.observeScreenWidth);
  }

  observeScreenWidth = () => {
    const { innerWidth } = window;
    this.setState({ mobile: innerWidth <= 768 });
  };

  render() {
    const { news, mobile } = this.state;

    if (!news.length) return null;

    if (mobile) {
      return (
        <SliderSecondary
          name="last-news-slider"
          settings={{
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
          }}
        >
          {news.map(item => (
            <div
              key={`news-item-${item.id}`}
              className="col-xl-6 last-news-item last-news-item_big"
            >
              <div className="news-double-item">
                <BigNewsBlock parent="/news" page={item} />
              </div>
            </div>
          ))}
        </SliderSecondary>
      );
    }

    return (
      <React.Fragment>
        {news.map(item => (
          <React.Fragment key={`news-item-${item.id}`}>
            {item.type === 'big' ? (
              <div className="col-xl-6 last-news-item last-news-item_big">
                <div className="news-double-item">
                  <BigNewsBlock parent="/news" page={item} />
                </div>
              </div>
            ) : (
              <div className="col-xl-3 last-news-item last-news-item_small">
                <div className="news-item">
                  <SmallNewsBlock parent="/news" page={item} />
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }
}

export default FluidGrid;
