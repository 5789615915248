/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.scss';
import '../assets/styles/sliderMedia.scss';

import Image from '../modules/views/Image';

const settingsTop = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const settingsBot = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
};

export default class SliderMedia extends Component {
  constructor(props) {
    super(props);
    const { media, title } = this.props;
    this.media = media;
    this.title = title;
    this.sliderTop = React.createRef();
    this.sliderBot = React.createRef();
  }

  onSlide = (oldIndex, newIndex) => {
    const currentIndex = newIndex || oldIndex;
    this.sliderTop.current.slickGoTo(currentIndex);
    this.sliderBot.current.slickGoTo(currentIndex);
  };

  render() {

    if (!this.media || !this.media.length) return null;

    return (
        <div className="slider-media">
          <div className="title-wrapper">
            <h2>{this.title}</h2>
            <div className="cross" />
          </div>

          <div className="slider-media_top">
            <Slider
                ref={this.sliderTop}
                {...settingsTop}
                beforeChange={this.onSlide}
                afterChange={this.afterChange}
            >
              {this.media.map((item, index) => (
                  <div
                      className="slider-media_top-item"
                      key={`sl-item-top-${index}`}
                  >
                    <Image src={item.src} />
                    <h5>{item.description}</h5>
                  </div>
              ))}
            </Slider>
          </div>

          <div className="slider-media_bot">
            <Slider
                ref={this.sliderBot}
                {...settingsBot}
                beforeChange={this.onSlide}
            >
              {this.media.map((item, index) => (
                  <div
                      key={`sl-item-bot-${index}`}
                      className="slider-media_bot-item"
                      onClick={() => this.onSlide(index)}
                      role="presentation"
                  >
                    <Image src={item.src}  style={{
                      height: '120px',
                      width: 'auto',
                    }}/>
                    <h5 style={{ textAlign: 'center' }}>{item.description}</h5>
                  </div>
              ))}
            </Slider>
          </div>
        </div>
    );
  }
}
