/* eslint-disable camelcase,react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';

import '../../assets/styles/smallNewsBlock.scss';

import { clearPageDescription, formatDateString } from '../../services/main';

import NewsBottom from './NewsBottom';
import LinkWrapper from "../util/LinkWrapper";
import TextTruncate from 'react-text-truncate';
import { BASE_URL } from '../../constants/urls'

const SmallNewsBlock = ({ parent, page }) => {
  const {
    is_social_networks_on, photo, alias,
    news_localizations, published_at, created_at,
  } = page;
  return (
    <div className="small-card">
      <LinkWrapper link={`${parent}/${alias}`}>
        <div className="small-card_wrapper">
          <img src={photo && (photo.resize || photo.href)} alt="" />
        </div>
        <div className="small-news-title">
          <Truncate
            lines={2}
            ellipsis={<span>...</span>}
          >
            {news_localizations[0] && news_localizations[0].title}
          </Truncate>
        </div>
        <div className="small-news-description">
          <TextTruncate
            line={3}
            truncateText="..."
            text={news_localizations[0] && clearPageDescription(news_localizations[0].description)}
          />

          {/*<Truncate*/}
          {/*  lines={3}*/}
          {/*  ellipsis={<span>...</span>}*/}
          {/*>*/}
          {/*  {news_localizations[0] && clearPageDescription(news_localizations[0].description)}*/}
          {/*</Truncate>*/}
        </div>
        </LinkWrapper>
        <NewsBottom
          date={formatDateString(published_at || created_at)}
          social
          tags={news_localizations[0] && news_localizations[0].tags}
          url={`${parent}/${alias}`}
          title={news_localizations[0] && news_localizations[0].title}
          image={photo && (photo.resize || photo.href)}
        />
    </div>
  );
};

export default SmallNewsBlock;
