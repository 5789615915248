import { initLocalization } from './main';
import { SWITCH_LANGUAGE } from '../constants/redux';

export function switchLanguage(language) {
  return async function action(dispatch) {
    localStorage.setItem('language', language);
    // dispatch({ type: SWITCH_LANGUAGE, language });
    dispatch(initLocalization());
  };
}
