/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Helmet } from 'react-helmet';

import ContentBlock from '../components/ContentBlock';
import Breadcrumbs from '../components/util/Breadcrumbs';

const IncidentItem = () => (
  <div className="container">
    <Helmet>
      <title>Інформаційне повідомлення про порушення в роботі</title>
    </Helmet>
    <div className="row no-gutter">
      <div className="col-xl-12 breadcrumb-wrapper">
        <Breadcrumbs />
      </div>
    </div>
    <ContentBlock>
      <h3 className="page-title">
        Інформаційне повідомлення про порушення в роботі
      </h3>
      <div className="page-subtitle">
        <span>
          Рівненська АЕС
        </span>
        <span>
          Блок №3
        </span>
      </div>
      <div className="page-content">
        <p>
          18 січня 2019 року о 23:50 енергоблок № 3 Рівненської АЕС через несправність
          у системі регулювання турбіни дією технологічного захисту було відключено від мережі.
        </p>
        <p>
          Після усунення зауважень 19 січня 2019 року об 11:59 енергоблок
          № 3 Рівненської АЕС підключено до енергомережі.
        </p>
        <p>
          За міжнародною шкалою INES попередня оцінка рівня порушення в роботі АЕС:
          нижче шкали/рівень «0» (не суттєво для безпеки).
        </p>
        <p>
          Створена комісія з розслідування події.
        </p>
        <p>
          Радіаційний стан на майданчику Рівненської АЕС знаходиться
          на рівні природних фонових значень.
        </p>
      </div>
    </ContentBlock>
  </div>
);

export default IncidentItem;
