/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import ContentBlock from '../components/ContentBlock';
import Breadcrumbs from '../components/util/Breadcrumbs';

const Incident = () => (
  <div className="container">
    <Helmet>
      <title>Порушення 2019 рік</title>
    </Helmet>
    <div className="row no-gutter">
      <div className="col-xl-12 breadcrumb-wrapper">
        <Breadcrumbs />
      </div>
    </div>
    <ContentBlock>
      <h3 className="page-title">
        Порушення 2019 рік
      </h3>
      <ul className="paper-list">
        <li>
          <Link to="/aes-state/violation/info">
            Інформаційне повідомлення про порушення герметичності 1-го контуру
            енергоблока № 2 ХАЕС. 22.11.2015
          </Link>
        </li>
        <li>
          <Link to="/aes-state/violation/info">
            Інформаційне повідомлення про відключення енергоблока № 6 ЗАЕС. 17.11.2015
          </Link>
        </li>
        <li>
          <Link to="/aes-state/violation/info">
            Інформаційне повідомлення про відмову 1 ГЦН-3 енергоблока № 1 ХАЕС
            через пошкодження нижнього радіального підшипника електродвигуна. 05.10.2015
          </Link>
        </li>
        <li>
          <Link to="/aes-state/violation/info">
            Інформаційне повідомлення про порушення герметичності 1-го контуру енергоблока
            № 2 ХАЕС. 22.11.2015
          </Link>
        </li>
        <li>
          <Link to="/aes-state/violation/info">
            Інформаційне повідомлення про відключення енергоблока № 6 ЗАЕС. 17.11.2015
          </Link>
        </li>
        <li>
          <Link to="/aes-state/violation/info">
            Інформаційне повідомлення про порушення герметичності 1-го контуру енергоблока
            № 2 ХАЕС. 22.11.2015
          </Link>
        </li>
        <li>
          <Link to="/aes-state/violation/info">
            Інформаційне повідомлення про порушення герметичності 1-го контуру енергоблока
            № 2 ХАЕС. 22.11.2015
          </Link>
        </li>
        <li>
          <Link to="/aes-state/violation/info">
          Інформаційне повідомлення про відмову 1 ГЦН-3 енергоблока № 1 ХАЕС через пошкодження
          нижнього радіального підшипника електродвигуна. 05.10.2015
          </Link>
        </li>
        <li>
          <Link to="/aes-state/violation/info">
          Інформаційне повідомлення про відмову 1 ГЦН-3 енергоблока № 1 ХАЕС через пошкодження
          нижнього радіального підшипника електродвигуна. 05.10.2015
          </Link>
        </li>
      </ul>
    </ContentBlock>
  </div>
);

export default Incident;
