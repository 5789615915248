/* eslint-disable react/no-array-index-key,react/prop-types */
import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.scss';
import '../assets/styles/sliderSecondary.scss';

const SliderSecondary = ({ settings, children, name }) => (
  <div className={`secondary-slider ${name || ''}`}>
    <Slider {...settings}>
      {children}
    </Slider>
  </div>
);

export default SliderSecondary;
