const servicesContentRU = [
  { text: 'Услуги', url: '/', img: '/images/mask.png' },
  { text: 'Платформа', url: '/', img: '/images/mask.png' },
  { text: 'Сертификаты', url: '/', img: '/images/mask.png' },
  { text: 'Журналы', url: '/', img: '/images/icon_3.png' },
  { text: 'Состояние АЭС', url: '/aes-state', img: '/images/icon_4.png' },
  { text: 'База НПА Украины и магате', url: '/', img: '/images/group-2-copy.png' },
];

export default servicesContentRU;
