import React from 'react';
import Close from '../../assets/svg/close.svg';
import {POST_FEEDBACK} from "../../constants/urls";
import fetchOne from "../../services/fetch";
import {validateEmail} from "../../actions/common";
import Rater from 'react-rater';

class FeedbackModal extends React.Component {
  state = {
    book: this.props.book,
    email: '',
    review: '',
    rating: 5,
    name: '',
    surname: '',
    submitted: false,
    emailError: false
  };

  onChange = name => event => {
    const {target} = event;
    this.setState({[name]: target.value});

    if (name === 'email') {
        this.setState({emailError: false});
    }
  };

  submitFeedback = (e) => {
    e.preventDefault();
    this.setState({submitted: true});

    const {
      book, email, review, rating, name, surname, emailError
    } = this.state;

    if (!validateEmail(email)) {
      this.setState({emailError: true});
    }

    if (!emailError && book && email && review && rating && name && surname) {
      this.createFeedback();

    }
  };

  createFeedback = async () => {
    try {
      const {
        book, email, review, rating, name, surname
      } = this.state;

    let method = 'POST';
      let url = `${POST_FEEDBACK}`;

      const pageBody = JSON.stringify({
        book, email, review, rating: +rating, name, surname
      });

      const createFeedback = await fetchOne({url, method, body: pageBody});

      if (createFeedback.status === 201 || createFeedback.status === 204) {
        this.setState({submitted: true});
        const feedback = await createFeedback.json();
        this.props.handleClose(feedback.id);
      }
    } catch (err) {
      this.setState({submitted: true});
    }
  };

  render() {
    const {email, review, rating, name, surname, emailError, submitted} = this.state;
    const {handleClose, show} = this.props;

    return (<div className={show ? "modal display-block" : "modal display-none"}>
      <div className="modal-background"
           onClick={handleClose}></div>
      <section className="modal-book-content">
        <div className="modal-header">
          <h2>Відгук про книгу</h2>
          <button className='close-btn' onClick={handleClose}>
            <img src={Close} alt=""/>
          </button>
        </div>

        <form>
          <div className="form-group">
            <label>
              <span>Ім'я*</span>
              <input type="text"
                     value={name}
                     className={submitted && !name ? 'error' : ''}
                     onChange={this.onChange('name')}
                     required
              />
            </label>

            <label>
              <span>Прізвище*</span>
              <input type="text"
                     value={surname}
                     className={submitted && !surname ? 'error' : ''}
                     onChange={this.onChange('surname')}
                     required
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              <span>E-mail*</span>
              <input type="text"
                     value={email}
                     className={submitted && (!email || emailError) ? 'error' : ''}
                     onChange={this.onChange('email')}
                     required
              />
            </label>
            <label>
              <span>Оцінка*</span>
              <div>
                <Rater rating={rating} total={5}
                       onRate={(val) => this.setState({rating: val.rating})}
                       required
                />
              </div>
            </label>
          </div>

          <div className="form-group-textarea">
            <label className="textarea-label">
              <span>Відгук*</span>
              <textarea value={review}
                        onChange={this.onChange('review')}
                        className={submitted && !review ? 'feedback-textarea error' : 'feedback-textarea'}
                        required> </textarea>
            </label>
          </div>

          <button className="book-buy-button"
                  onClick={(e) => this.submitFeedback(e)}>
            <span>Залишити відгук</span>
          </button>
        </form>

      </section>
    </div>)
  };
}

export default FeedbackModal;
