/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React from "react";
import "../assets/styles/propositions.scss";
import SliderSecondary from "./SliderSecondary";
import { Link } from 'react-router-dom'

const secondarySettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 440,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
      },
    },
  ],
};

const Iblocks = ({ media, title }) => (
  <div className="propositions-wrapper iblocks">
    <h3>{title}</h3>
    <div className="row no-gutter iblocks-row">
      {media.length < 5 ?
              media.map((item, i) => (
                        <LinkWrapper
                                className="iblock-item-link static"
                                link={item.link}
                                key={`propositions-item-${i}`}
                        >
                          <div
                                  className="iblock-img-wrapper"
                                  style={{ backgroundImage: `url(${item.img})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
                          >
                            {/*<img src={item.img} alt="" />*/}
                          </div>
                          <div className="iblock-caption">
                            {item.caption}
                          </div>
                        </LinkWrapper>
              ))
              :
              <SliderSecondary settings={{ ...secondarySettings }}>
                {media.map((item, i) => (
                        <LinkWrapper
                                className="iblock-item-link"
                                link={item.link}
                                key={`propositions-item-${i}`}
                        >
                          <div
                                  className="iblock-img-wrapper"
                                  style={{ backgroundImage: `url(${item.img})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
                          >
                            {/*<img src={item.img} alt="" />*/}
                          </div>
                          <div className="iblock-caption">
                            {item.caption}
                          </div>
                        </LinkWrapper>
                ))}
              </SliderSecondary>
      }
    </div>
  </div>
);

const LinkWrapper = ({ link, children, className }) => {
  if (!link) {
    return (
            <div className={className}>
              {children}
            </div>
    );
  }
  if (link.match(/http/gi)) {
    return (
            <a href={link} className={className}>
              {children}
            </a>
    );
  }
  return (
          <Link to={link} className={className}>
            {children}
          </Link>
  );
};

export default Iblocks;
